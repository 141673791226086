
import Vue from "vue";
import requester from "@/requester";
import { TiposDeclarantes } from "@/utils/enums";

export default Vue.extend({
  name: "MetabaseTimelineChart",
  props: {
    periodoInicialId: {
      type: Number,
      default: null,
    },
    periodoFinalId: {
      type: Number,
      default: null,
    },
    unidades: {
      type: Array,
      default: () => [],
    },
    usuarios: {
      type: Array,
      default: () => [],
    },
    categorias: {
      type: Array,
      default: () => [],
    },
    cardInfo: {
      type: Object,
      default: null,
    },
    metabaseSessionKey: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    iframeUrl: null,
    dadosCard: null,
    baseHeight: 400,
    height: 400,
    especieId: null,
    unidadeId: null,
    declaranteObj: null,
  }),
  computed: {
    parametrosCard() {
      return this.dadosCard ? this.dadosCard.parameters : [];
    },
    parametrosSlugsCard() {
      return this.dadosCard.parameters.map((el) => el.slug);
    },
    temParametroUnidadeId() {
      return this.parametrosSlugsCard.includes("unidade_id");
    },
    temParametroEspecieId() {
      return this.parametrosSlugsCard.includes("especie_id");
    },
    temParametroPeriodoInicialId() {
      return this.parametrosSlugsCard.includes("periodo_inicial_id");
    },
    temParametroPeriodoFinalId() {
      return this.parametrosSlugsCard.includes("periodo_final_id");
    },
    temParametrosDeDeclarante() {
      // pra definir declarante, preciso do tipo e id (unidade/usuario criador)
      return (
        this.parametrosSlugsCard.includes("tipo_declarante") &&
        this.parametrosSlugsCard.includes("declarante_id")
      );
    },
    unidadesItens() {
      return this.unidades.map((el) => ({
        text: el.sigla,
        value: el.id,
      }));
    },
    especiesItens() {
      return this.categorias.map((el) => ({
        text: el.especie.nome,
        value: el.especie.id,
      }));
    },
    declarantesItens() {
      const declarantes = [];
      this.unidades.forEach((u) => {
        declarantes.push({
          tipoDeclarante: TiposDeclarantes.UNIDADE,
          declaranteId: u.id,
          text: u.sigla,
        });
      });
      this.usuarios
        .filter((us) => us.categoriaUsuario === TiposDeclarantes.DOCENTE)
        .forEach((u) => {
          declarantes.push({
            tipoDeclarante: TiposDeclarantes.DOCENTE,
            declaranteId: u.id,
            text: u.nome,
          });
        });
      return declarantes;
    },
  },
  async mounted() {
    if (this.especiesItens.length > 0) {
      this.especieId = this.especiesItens[0].value;
    }
    if (this.declarantesItens.length > 0) {
      this.declaranteObj = this.declarantesItens[0];
    }
    if (this.unidadesItens.length > 0) {
      this.unidadeId = this.unidadesItens[0].value;
    }
    this.restauraAltura();
    this.carregaChart();
  },
  methods: {
    aumentaAltura() {
      this.height += 100;
    },
    reduzAltura() {
      if (this.height > 100) {
        this.height -= 100;
      } else {
        this.$root.$emit("toast-error", "Gráfico muito pequeno");
      }
    },
    restauraAltura() {
      this.height = this.baseHeight;
    },
    async carregaChart() {
      this.loading = true;
      await this.buscaInfoCard();
      await this.buscaGrafico();
      this.loading = false;
    },
    async buscaInfoCard() {
      // busca infos da question (do tipo card)
      try {
        this.dadosCard = await requester.metabase.private.buscaCardById(
          this.cardInfo.id,
          this.metabaseSessionKey.id
        );
      } catch (error) {
        this.$root.$errorHandler(error);
      }
    },
    async buscaGrafico() {
      let params = {};

      // busca pelas variaveis da question do metabase
      if (this.temParametroPeriodoInicialId) {
        params["periodoInicialId"] = this.periodoInicialId;
      }
      if (this.temParametroPeriodoFinalId) {
        params["periodoFinalId"] = this.periodoFinalId;
      }
      if (this.temParametroEspecieId) {
        params["especieId"] = this.especieId;
      }
      if (this.temParametroUnidadeId) {
        params["unidadeId"] = this.unidadeId;
      }
      if (this.temParametrosDeDeclarante) {
        params["tipoDeclarante"] = this.declaranteObj.tipoDeclarante;
        params["declaranteId"] = this.declaranteObj.declaranteId;
      }
      try {
        this.iframeUrl = await requester.metabase.private.buscaGrafico(
          Number(this.dadosCard.id),
          params
        );
      } catch (error) {
        this.$root.$errorHandler(error);
      }
    },
  },
  watch: {
    periodoInicialId() {
      this.carregaChart();
    },
    periodoFinalId() {
      this.carregaChart();
    },
    unidadeId() {
      this.carregaChart();
    },
    especieId() {
      this.carregaChart();
    },
    declaranteObj() {
      this.carregaChart();
    },
  },
});
