
import Vue from "vue";
import TheButtonsMenu from "../components/TheButtonsMenu.vue";
import requester from "@/requester";
import packageInfo from "../../package.json";

export default Vue.extend({
  name: "LayoutDefault",
  components: {
    TheButtonsMenu,
  },
  data: () => ({
    drawer: false,
    version: packageInfo.version,
    // USADO PARA DEV
    // wsuserid: null,
  }),
  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },
    loginUspURL() {
      return `${process.env.VUE_APP_BASE_URL}auth/login_usp`;
    },
  },
  methods: {
    // USADO PARA DEV
    // salvaWsuserid() {
    //   this.$store.dispatch("wsuserid", this.wsuserid);
    //   this.$root.$emit("toast-success", `OK, ${this.$store.getters.wsuserid}`);
    // },
    async logout() {
      await requester.auth.private.logout();
      this.$store.dispatch("logout");
      if (this.$route.name !== "LandingPage") {
        this.$router.push({ name: "LandingPage" });
      }
    },
  },
});
