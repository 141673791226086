import axios from "axios";
import { Unidade } from "@back-src/models/unidade.model";
import { Disciplina } from "@back-src/models/disciplina.model";
import { Declaracao } from "@back-src/models/declaracao.model";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import { DeclaracaoReceita } from "@back-src/models/declaracao-receita.model";
import { CriaDeclaracaoDto } from "@back-src/declaracao/dto/cria-declaracao.dto";
import { AlteraDeclaracaoDto } from "@back-src/declaracao/dto/altera-declaracao.dto";
import { AlteraItemReceitaDto } from "@back-src/declaracao/dto/altera-item-receita.dto";
import { DadosExportados } from "@back-src/utils/constants";

//UNIDADE
const busca = async (id: number): Promise<Unidade> => {
  const res = await axios.get<Unidade>(`unidades/${id}`);
  return res.data;
};
const lista = async (): Promise<Unidade[]> => {
  const res = await axios.get<Unidade[]>("unidades");
  return res.data;
};

//PREFEITURA
const criaDeclaracaoItemPrefeitura = async (
  form: FormData,
  declaracaoId: number,
  unidadeId: number
): Promise<void> => {
  const res = await axios.post(
    `unidades/${unidadeId}/prefeitura/declaracoes/${declaracaoId}/itens_declaracao`,
    form
  );
  return res.data;
};

const listaDeclaracaoItemsPrefeitura = async (
  unidadeId: number
): Promise<DeclaracaoItem[]> => {
  const res = await axios.get<DeclaracaoItem[]>(
    `unidades/${unidadeId}/prefeitura/itens_declaracao`
  );
  return res.data;
};

const listaDeclaracaoItemsPrefeituraByPeriodo = async (
  unidadeId: number,
  periodoDeclaracaoId: number
): Promise<DeclaracaoItem[]> => {
  const res = await axios.get<DeclaracaoItem[]>(
    `unidades/${unidadeId}/prefeitura/itens_declaracao/periodo/${periodoDeclaracaoId}`
  );
  return res.data;
};

const importarItensPrefeitura = async (
  unidadeId: number,
  periodoDeclaracaoIdImportar: number
): Promise<DeclaracaoItem[]> => {
  const res = await axios.post(
    `unidades/${unidadeId}/prefeitura/periodo/${periodoDeclaracaoIdImportar}/importar_itens_declaracao`
  );
  return res.data;
};

const alteraDeclaracaoItemPrefeitura = async (
  form: FormData,
  declaracaoItemId: number,
  unidadeId: number
): Promise<void> => {
  const res = await axios.put(
    `unidades/${unidadeId}/prefeitura/itens_declaracao/${declaracaoItemId}`,
    form
  );
  return res.data;
};

//DECLARAÇÃO
const buscaDeclaracaoAtual = async (unidadeId: number): Promise<Declaracao> => {
  const res = await axios.get<Declaracao>(
    `unidades/${unidadeId}/declaracoes/atual`
  );
  return res.data;
};
const criaDeclaracao = async (
  form: CriaDeclaracaoDto,
  unidadeId: number
): Promise<void> => {
  const res = await axios.post(`unidades/${unidadeId}/declaracoes`, form);
  return res.data;
};
const alteraFlagConclusao = async (
  declaracaoId: number,
  unidadeId: number,
  form: AlteraDeclaracaoDto
): Promise<void | string> => {
  const res = await axios.put(
    `unidades/${unidadeId}/declaracoes/${declaracaoId}/altera_flag`,
    form
  );
  return res.data;
};

const criaDeclaracaoItemDisciplina = async (
  form: FormData,
  declaracaoId: number,
  disciplinaId: number,
  unidadeId: number
): Promise<void> => {
  const res = await axios.post(
    `unidades/${unidadeId}/declaracoes/${declaracaoId}/disciplinas/${disciplinaId}/itens_declaracao`,
    form
  );
  return res.data;
};

const alteraDeclaracaoItemDisciplina = async (
  form: FormData,
  declaracaoItemId: number,
  disciplinaId: number,
  unidadeId: number
): Promise<void> => {
  const res = await axios.put(
    `unidades/${unidadeId}/disciplinas/${disciplinaId}/itens_declaracao/${declaracaoItemId}`,
    form
  );
  return res.data;
};

const removeDeclaracaoItem = async (
  declaracaoItemId: number,
  disciplinaId: number,
  unidadeId: number
): Promise<void> => {
  const res = await axios.delete(
    `unidades/${unidadeId}/disciplinas/${disciplinaId}/itens_declaracao/${declaracaoItemId}`
  );
  return res.data;
};

const buscaDeclaracaoItem = async (
  unidadeId: number,
  declaracaoItemId: number
): Promise<DeclaracaoItem> => {
  const res = await axios.get<DeclaracaoItem>(
    `unidades/${unidadeId}/itens_declaracao/${declaracaoItemId}`
  );
  return res.data;
};

const listaDeclaracaoItemsDisciplina = async (
  disciplinaId: number,
  unidadeId: number
): Promise<DeclaracaoItem[]> => {
  const res = await axios.get<DeclaracaoItem[]>(
    `unidades/${unidadeId}/disciplina/${disciplinaId}/itens_declaracao`
  );
  return res.data;
};

const buscaDeclaracaoByPeriodoId = async (
  unidadeId: number,
  periodoDeclaracaoId: number
): Promise<Declaracao> => {
  const res = await axios.get<Declaracao>(
    `unidades/${unidadeId}/periodo/${periodoDeclaracaoId}/declaracoes`
  );
  return res.data;
};

const listaDisciplinasByPeriodoId = async (
  periodoDeclaracaoId: number,
  unidadeId: number
): Promise<Disciplina[]> => {
  const res = await axios.get<Disciplina[]>(
    `unidades/${unidadeId}/periodo/${periodoDeclaracaoId}/disciplinas`
  );
  return res.data;
};

//DISCIPLINA
const listaDisciplinas = async (
  declaracaoId: number,
  unidadeId: number
): Promise<Disciplina[]> => {
  const res = await axios.get<Disciplina[]>(
    `unidades/${unidadeId}/declaracao/${declaracaoId}/disciplinas`
  );
  return res.data;
};
const criaDisciplina = async (
  formData: FormData,
  declaracaoId: number,
  unidadeId: number
): Promise<void> => {
  const res = await axios.post(
    `unidades/${unidadeId}/declaracao/${declaracaoId}/disciplinas`,
    formData
  );
  return res.data;
};

const alteraDisciplina = async (
  formData: FormData,
  declaracaoId: number,
  disciplinaId: number,
  unidadeId: number
): Promise<Disciplina> => {
  const res = await axios.put<Disciplina>(
    `unidades/${unidadeId}/declaracao/${declaracaoId}/disciplinas/${disciplinaId}`,
    formData
  );
  return res.data;
};

const importarItensDeDisciplina = async (
  unidadeId: number,
  declaracaoId: number,
  form: any
): Promise<DeclaracaoItem[]> => {
  const res = await axios.post(
    `unidades/${unidadeId}/declaracao/${declaracaoId}/importar_itens_declaracao`,
    form
  );
  return res.data;
};

//RECEITA
const criaEmLoteDeclaracaoReceita = async (
  unidadeId: number
): Promise<void> => {
  const res = await axios.post(`unidades/${unidadeId}/receitas`);
  return res.data;
};

const alteraItemReceita = async (
  form: AlteraItemReceitaDto,
  itemReceitaId: number,
  unidadeId: number
): Promise<void> => {
  const res = await axios.put(
    `unidades/${unidadeId}/itens_receita/${itemReceitaId}`,
    form
  );
  return res.data;
};

const listaDeclaracaoReceita = async (
  declaracaoId: number,
  unidadeId: number
): Promise<DeclaracaoReceita[]> => {
  const res = await axios.get<DeclaracaoReceita[]>(
    `unidades/${unidadeId}/declaracoes/${declaracaoId}/itens_receita`
  );
  return res.data;
};

// EXPORTAR DADOS
const exportarDadosDeclaracaoUnidade = async (
  unidadeId: number,
  declaracaoId: number
): Promise<DadosExportados> => {
  const res = await axios.get<DadosExportados>(
    `unidades/${unidadeId}/declaracoes/${declaracaoId}/exportar`
  );
  return res.data;
};

export default {
  private: {
    alteraDeclaracaoItemDisciplina,
    alteraDeclaracaoItemPrefeitura,
    alteraDisciplina,
    alteraFlagConclusao,
    alteraItemReceita,
    busca,
    buscaDeclaracaoAtual,
    buscaDeclaracaoByPeriodoId,
    buscaDeclaracaoItem,
    criaDeclaracao,
    criaDeclaracaoItemDisciplina,
    criaDeclaracaoItemPrefeitura,
    criaDisciplina,
    criaEmLoteDeclaracaoReceita,
    exportarDadosDeclaracaoUnidade,
    lista,
    listaDeclaracaoItemsDisciplina,
    listaDeclaracaoItemsPrefeitura,
    listaDeclaracaoItemsPrefeituraByPeriodo,
    listaDeclaracaoReceita,
    listaDisciplinas,
    removeDeclaracaoItem,
    listaDisciplinasByPeriodoId,
    importarItensDeDisciplina,
    importarItensPrefeitura,
  },
};
