
import Vue from "vue";
import AlertInfo from "../../components/AlertInfo.vue";
export default Vue.extend({
  name: "DeclaracaoItemImportarCard",
  components: {
    AlertInfo,
  },
  props: {
    msgImportacao: {
      type: String,
      default: null,
    },
    isProjeto: {
      type: Boolean,
      default: false,
    },
    isDisciplina: {
      type: Boolean,
      default: false,
    },
    loadingImportacao: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textoDeclaracao() {
      if (this.isProjeto) return "neste projeto";
      if (this.isDisciplina) return "nesta disciplina";
      return "nesta declaração";
    },
  },
});
