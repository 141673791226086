
import Vue from "vue";
import CustomHeader from "../../components/CustomHeader.vue";
import InicioTabItem from "../../components/InicioTabItem.vue";
import UnidadeDisciplinasTabItem from "../../components/unidade/UnidadeDisciplinasTabItem.vue";
import UnidadeHistoricoDeclaracaoTabItem from "../../components/unidade/UnidadeHistoricoDeclaracaoTabItem.vue";
import UnidadeFinalizarDeclaracaoTabItem from "../../components/unidade/UnidadeFinalizarDeclaracaoTabItem.vue";
import UnidadeReceitaTabItem from "../../components/unidade/UnidadeReceitaTabItem.vue";
import { CriaDeclaracaoDto } from "@back-src/declaracao/dto/cria-declaracao.dto";
import { Declaracao } from "@back-src/models/declaracao.model";
import { Unidade } from "@back-src/models/unidade.model";
import { getDataHojeBdFormat } from "../../../../back/src/utils/functions";

import requester from "../../requester";
import { TiposDeclarantes } from "@/utils/enums";

export default Vue.extend({
  components: {
    UnidadeHistoricoDeclaracaoTabItem,
    InicioTabItem,
    UnidadeReceitaTabItem,
    CustomHeader,
    UnidadeDisciplinasTabItem,
    UnidadeFinalizarDeclaracaoTabItem,
  },
  name: "UnidadeIndex",
  data: () => ({
    loading: {
      unidade: true,
      periodoDeclaracao: true,
      declaracao: true,
    },
    disciplinasCadastradasPanel: 1,
    tab: 0,
    tabDisciplina: 0,
    periodoDeclaracao: null,
    declaracaoUnidade: null as Declaracao | null,
    unidade: null as Unidade | null,
    menu: false,
    TiposDeclarantes,
  }),
  computed: {
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    periodoDeclaracaoAtivo() {
      if (!this.periodoDeclaracao) return false;
      const hojeBd = getDataHojeBdFormat();
      return (
        hojeBd >= this.periodoDeclaracao.dataInicio &&
        hojeBd <= this.periodoDeclaracao.dataFim
      );
    },
    unidadeId() {
      return Number(this.$store.getters.usuario.unidadeId) || null;
    },
  },
  mounted() {
    this.buscaDados();
  },
  methods: {
    async alteraFlagConclusao(completa: boolean) {
      try {
        await requester.unidade.private.alteraFlagConclusao(
          this.declaracaoUnidade.id,
          this.unidadeId,
          { completa }
        );
        if (completa === false)
          this.$root.$emit(
            "toast-success",
            "Declaração aberta para alterações!"
          );
        else {
          this.$root.$emit(
            "toast-success",
            "Declaração marcada como completa!"
          );
        }
        this.buscaDados();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async buscaDados() {
      await this.buscaUnidade();
      await this.verificaPeriodoDeclaracaoAtivo();
      await this.verificaDeclaracaoIniciada();
    },
    async verificaPeriodoDeclaracaoAtivo() {
      this.loading.periodoDeclaracao = true;
      try {
        this.periodoDeclaracao =
          await requester.periodoDeclaracao.private.buscaAtual();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.periodoDeclaracao = false;
      }
    },
    async verificaDeclaracaoIniciada() {
      if (!this.periodoDeclaracao) {
        this.loading.declaracao = false;
        return;
      }
      this.loading.declaracao = true;

      try {
        this.declaracaoUnidade =
          await requester.unidade.private.buscaDeclaracaoByPeriodoId(
            this.unidadeId,
            this.periodoDeclaracao.id
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.declaracao = false;
      }
    },
    async buscaUnidade() {
      this.loading.unidade = true;
      try {
        this.unidade = await requester.unidade.private.busca(this.unidadeId);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.unidade = false;
      }
    },
    declaracao() {
      if (!this.declaracaoUnidade) {
        this.iniciaDeclaracao();
      } else {
        this.tab = 1;
      }
    },
    async iniciaDeclaracao() {
      try {
        const formData = {
          tipoDeclarante: "Unidade",
        } as CriaDeclaracaoDto;
        await requester.unidade.private.criaDeclaracao(
          formData,
          this.unidadeId
        );
        this.$root.$emit("toast-success", "Declaração iniciada!");
        this.buscaDados();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
});
