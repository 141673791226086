
import Vue from "vue";
import { Disciplina } from "@back-src/models/disciplina.model";
import DisciplinasTabela from "./DisciplinasTabela.vue";
import requester from "../../requester";
import DisciplinaDeclaracaoItem from "./DisciplinaDeclaracaoItem.vue";
import DisciplinaDialog from "./DisciplinaDialog.vue";
import CustomTabItem from "../CustomTabItem.vue";
import UnidadeDisciplinasTabItemHeader from "./UnidadeDisciplinasTabItemHeader.vue";
import UnidadeDisciplinaImportar from "./UnidadeDisciplinaImportar.vue";
export default Vue.extend({
  components: {
    UnidadeDisciplinaImportar,
    UnidadeDisciplinasTabItemHeader,
    CustomTabItem,
    DisciplinaDeclaracaoItem,
    DisciplinaDialog,
    DisciplinasTabela,
  },
  name: "UnidadeDisciplinasTabItem",
  props: {
    ver: {
      type: Boolean,
      default: false,
    },
    unidadeId: {
      type: Number,
      default: null,
    },
    declaracaoId: {
      type: Number,
      default: null,
    },
    declaracaoFinalizada: {
      type: Boolean,
      default: false,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    loading: {
      disciplinas: false,
    },
    step: 1,
    disciplinaSelecionada: null,
    disciplinaSelecionadaId: null,
    dialogDisciplina: false,
    dialogDisciplinaAlterar: false,
    tabDisciplina: 0,
    disciplinas: [] as Disciplina[],
  }),
  computed: {
    titleComputed() {
      switch (this.step) {
        case 2:
          return "Declarar animais da disciplina";
        case 3:
          return "Importar disciplina";
        default:
          return "Declaração das disciplinas";
      }
    },
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
  },
  mounted() {
    this.listaDisciplinas();
  },
  methods: {
    async listaDisciplinas() {
      if (!this.declaracaoId) {
        this.disciplinas = [];
        return;
      }
      this.loading.disciplinas = true;
      try {
        this.disciplinas = await requester.unidade.private.listaDisciplinas(
          this.declaracaoId,
          this.unidadeId
        );
        this.loading.disciplinas = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    disciplinaCadastrada() {
      this.listaDisciplinas();
      this.step = 1;
      this.disciplinaSelecionada = null;
      this.disciplinaSelecionadaId = null;
    },
  },
  watch: {
    unidadeId() {
      this.listaDisciplinas();
    },
  },
});
