import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/src/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1094ab", //#007DA1
        secondary: "#64c4d2",
        tertiary: "#fcb421",
        accent: "#AF0050",
        error: "#FF2812",
        info: "#06C5FB",
        success: "#0ECA38",
        warning: "#FFB300",
        // novas cores
        accent_faded: "#F6E2EA",
        primary_faded: "#E1F0F4",
        error_faded: "#FAE4E1",
        info_faded: "#E3F8FF",
        success_faded: "#E1F3E7",
        warning_faded: "#FFF6E2",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
