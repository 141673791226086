
import Vue from "vue";

export default Vue.extend({
  name: "RelatoriosLayoutTool",
  props: {
    colSize: {
      type: Number,
      default: 6,
    },
  },
});
