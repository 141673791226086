
import Vue from "vue";
import { Unidade } from "@back-src/models/unidade.model";
import { Disciplina } from "@back-src/models/disciplina.model";
import FileDownloadLink from "../FileDownloadLink.vue";
export default Vue.extend({
  name: "DisciplinasTabela",
  components: { FileDownloadLink },
  props: {
    ver: {
      type: Boolean,
      default: false,
    },
    declaracaoId: {
      type: Number,
      default: null,
    },
    unidadeId: {
      type: Number,
      default: null,
    },
    disciplinas: {
      type: Array as () => Disciplina[],
      default: () => [],
    },
  },
  data: () => ({
    loading: {
      unidade: false,
      disciplinas: false,
    },
    search: null,
    cadastrando: false,
    dialogDisciplina: false,
    unidade: null as Unidade | null,
  }),
  computed: {
    ativarAcoes() {
      return !this.ver;
    },
    headers() {
      return [
        {
          value: "sigla",
          text: "Sigla",
          class: "text-no-wrap",
          sortable: true,
        },
        {
          value: "nome",
          text: "Nome",
          class: "text-no-wrap",
          sortable: true,
        },
        {
          value: "responsavel",
          text: "Responsável",
          class: "text-no-wrap",
          sortable: true,
        },
        {
          value: "numCeua",
          text: "Número CEUA",
          class: "text-no-wrap",
          sortable: false,
        },
        ...(this.ativarAcoes
          ? [
              {
                value: "alterar",
                text: "Alterar",
                class: "text-no-wrap",
                sortable: false,
                align: "center",
              },
              {
                value: "declarar",
                text: "Declarar animais",
                class: "text-no-wrap",
                sortable: false,
                align: "center",
              },
            ]
          : [{}]),
      ];
    },
  },
});
