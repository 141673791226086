
import CustomTabItem from "@/components/CustomTabItem.vue";
import Vue from "vue";
import AlertInfo from "./AlertInfo.vue";
import DisciplinaTutorialCard from "../components/unidade/DisciplinaTutorialCard.vue";
import ProjetoTutorialCard from "../components/docente/ProjetoTutorialCard.vue";
import AnimaisTutorialCard from "../components/prefeitura/AnimaisTutorialCard.vue";
import EmbededVideoDialog from "../components/EmbededVideoDialog.vue";
import { TiposDeclarantes } from "@/utils/enums";

export default Vue.extend({
  name: "InicioTabItem",
  components: {
    CustomTabItem,
    AlertInfo,
    EmbededVideoDialog,
    DisciplinaTutorialCard,
    ProjetoTutorialCard,
    AnimaisTutorialCard,
  },
  props: {
    periodoDeclaracaoAtivo: {
      type: Boolean,
      default: false,
    },
    declaracaoIniciada: {
      type: Boolean,
      default: false,
    },
    declaracaoEnviada: {
      type: Boolean,
      default: false,
    },
    tipoDeclarante: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    TiposDeclarantes,
    receitaTabText: {
      content: [
        "Este menu é utilizado para informar os rendimentos de cada espécie animal declarada, se houver. O preenchimento é obrigatório e deve ser feito após o cadastro dos animais.",
      ],
    },
    finalizarTabText: {
      content: [
        "Nesta etapa serão apresentados todos os animais declarados.",
        "O sistema irá exibir informações sobre possíveis erros no preenchimento, e será necessário realizar as alterações apontadas para que a declaração seja concluida.",
        "Quando todos os animais declarados estiverem preenchidos corretamente, será possível marcar a declaração como completa.",
        "A declaração pode ser modificada para incluir ou excluir animais declarados durante todo o período de declaração.",
      ],
    },
    historicoTabText: {
      content: [
        "Nesta aba é possivel visualizar as declarações de períodos anteriores",
        "Também é possível baixar os dados declarados em formato MS Excel",
      ],
    },
  }),
  computed: {
    videoHash() {
      switch (this.tipoDeclarante) {
        case TiposDeclarantes.UNIDADE:
          // return "iHWnVvMIRHc";
          return "_AlqvpiWEpw";
        case TiposDeclarantes.DOCENTE:
          // return "Rhagl4OdKuw";
          return "DsvENefK_EA";
        case TiposDeclarantes.PREFEITURA:
          // return "JQAXke2iVHo";
          return "oK9swd9sXTw";
        default:
          return null;
      }
    },
  },
});
