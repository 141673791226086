
import Vue from "vue";
import requester from "@/requester";
import { SituacaoAnimalPatrimonioApi } from "@/utils/constants";

export default Vue.extend({
  name: "DeclaracaoItemStatusBuscaPatrimonio",
  props: {
    numPatrimonio: {
      type: String,
      default: null,
    },
    apiPatrimonioBloqueado: {
      type: Boolean,
      default: false,
    },
    apiPatrimonioConsultadaAt: {
      type: String,
      default: null,
    },
    apiPatrimonioDados: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      dadosApi: null,
      erroApi: false,
      msgDadosCorretos:
        "Verifique se o número de patrimônio está correto ou entre em contato com os responsáveis pelo cadastro",
    };
  },
  mounted() {
    if (this.apiPatrimonioConsultadaAt) {
      this.dadosApi = this.apiPatrimonioDados;
      this.avaliaDadosApi();
    }
  },
  computed: {
    buscaHabilitada() {
      return this.numPatrimonio?.length === 11;
    },
    dataConsulta() {
      return this.apiPatrimonioConsultadaAt
        ? new Date(this.apiPatrimonioConsultadaAt).toLocaleDateString("pt-BR")
        : "-";
    },
    alertColor() {
      if (this.erroApi) return "accent";
      if (this.ok) return "success";
      if (this.baixado) return "error";
      if (this.naoCadastrado) return "warning";
      return "grey";
    },
    ok() {
      if (this.apiPatrimonioDados && this.apiPatrimonioDados.situacao) {
        return [
          SituacaoAnimalPatrimonioApi.Ativo,
          SituacaoAnimalPatrimonioApi.Passagem,
        ].includes(this.apiPatrimonioDados.situacao);
      }
      return false;
    },
    baixado() {
      if (this.apiPatrimonioDados && this.apiPatrimonioDados.situacao) {
        return (
          SituacaoAnimalPatrimonioApi.Baixado ===
          this.apiPatrimonioDados.situacao
        );
      }
      return false;
    },
    naoCadastrado() {
      return (
        !this.apiPatrimonioDados &&
        this.apiPatrimonioBloqueado &&
        !!this.apiPatrimonioConsultadaAt
      );
    },
  },
  watch: {
    numPatrimonio() {
      if (this.buscaHabilitada) {
        this.verificaNumPatrimonio();
      }
    },
  },
  methods: {
    async verificaNumPatrimonio() {
      this.loading = true;
      this.erroApi = false;
      this.$emit("busca-api-loading");
      try {
        this.dadosApi =
          await requester.declaracaoItem.private.consultaApiPatrimonio(
            `${parseInt(this.numPatrimonio.replaceAll(".", ""))}`
          );
        this.avaliaDadosApi();
      } catch (err) {
        this.erroApi = true;
        this.$emit("busca-api-erro", err);
      } finally {
        this.loading = false;
        this.$emit("busca-api-finalizada");
      }
    },
    avaliaDadosApi() {
      if (!this.dadosApi || Object.keys(this.dadosApi).length === 0) {
        this.$emit("busca-api-nao-cadastrado");
        return;
      }
      let bloquearItem = false;
      if (SituacaoAnimalPatrimonioApi.Baixado === this.dadosApi.situacao) {
        this.$emit("busca-api-ok", this.dadosApi, !bloquearItem);
      } else if (SituacaoAnimalPatrimonioApi.Ativo === this.dadosApi.situacao) {
        this.$emit("busca-api-ok", this.dadosApi, bloquearItem);
      }
    },
  },
});
