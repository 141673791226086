
import Vue from "vue";
import requester from "../../requester";
import DisciplinaForm from "./DisciplinaForm.vue";

export default Vue.extend({
  name: "DisciplinaDialog",
  components: {
    DisciplinaForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    alterar: {
      type: Boolean,
      default: false,
    },
    unidadeId: {
      type: Number,
      default: null,
    },
    disciplinaId: {
      type: Number,
      default: null,
    },
    declaracaoId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    loading: {
      disciplina: false,
    },
    disciplinaSelecionada: null,
  }),
  mounted() {
    if (this.alterar && this.disciplinaId) {
      this.buscaDisciplina();
    }
  },
  methods: {
    closeDialog() {
      this.$emit("input", false);
    },
    async buscaDisciplina() {
      this.loading.disciplina = true;
      try {
        this.disciplinaSelecionada = await requester.disciplina.private.busca(
          this.disciplinaId
        );
        this.loading.disciplina = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
});
