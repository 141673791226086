
import Vue from "vue";
export default Vue.extend({
  name: "FileDownloadLink",
  props: {
    arquivoId: {
      type: Number,
      default: null,
    },
    arquivoNome: {
      type: String,
      default: null,
    },
    // Dado de disciplina
    disciplinaId: {
      type: Number,
      default: null,
    },
    // Dados de projeto
    projetoId: {
      type: Number,
      default: null,
    },
    isArquivoConvenio: {
      type: Boolean,
      default: false,
    },
    // Projeto ou Disciplina
    isArquivoCEUA: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fileLink() {
      if (this.isArquivoConvenio && this.projetoId) {
        return `${process.env.VUE_APP_BASE_URL}projetos_pesquisa/${this.projetoId}/convenio_empresa_arquivo/presigned_url`;
      }
      if (this.isArquivoCEUA && this.projetoId) {
        return `${process.env.VUE_APP_BASE_URL}projetos_pesquisa/${this.projetoId}/ceua_arquivo/presigned_url`;
      }
      if (this.isArquivoCEUA && this.disciplinaId) {
        return `${process.env.VUE_APP_BASE_URL}disciplinas/${this.disciplinaId}/ceua_arquivo/presigned_url`;
      }
      return null;
    },
  },
});
