
import CustomHeader from "../../components/CustomHeader.vue";
import Vue from "vue";
import requester from "../../requester";
import { Usuario } from "@back-src/models/usuario.model";
import { Declaracao } from "@back-src/models/declaracao.model";
import DocenteProjetosTabItem from "@/components/docente/DocenteProjetosTabItem.vue";
import DocenteReceitaTabItem from "@/components/docente/DocenteReceitaTabItem.vue";
import InicioTabItem from "@/components/InicioTabItem.vue";
import DocenteFinalizarDeclaracaoTabItem from "@/components/docente/DocenteFinalizarDeclaracaoTabItem.vue";
import DocenteHistoricoDeclaracaoTabItem from "@/components/docente/DocenteHistoricoDeclaracaoTabItem.vue";
import { TiposDeclarantes } from "@/utils/enums";
import { getDataHojeBdFormat } from "../../../../back/src/utils/functions";

export default Vue.extend({
  components: {
    CustomHeader,
    InicioTabItem,
    DocenteProjetosTabItem,
    DocenteReceitaTabItem,
    DocenteFinalizarDeclaracaoTabItem,
    DocenteHistoricoDeclaracaoTabItem,
  },
  name: "DocenteIndex",
  data: () => ({
    loading: {
      periodoDeclaracao: true,
      declaracao: true,
    },
    tab: 0,
    periodoDeclaracao: null,
    menu: false,
    declaracaoDocente: null as Declaracao | null,
    TiposDeclarantes,
  }),
  computed: {
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    periodoDeclaracaoAtivo() {
      if (!this.periodoDeclaracao) return false;
      const hojeBd = getDataHojeBdFormat();
      return (
        hojeBd >= this.periodoDeclaracao.dataInicio &&
        hojeBd <= this.periodoDeclaracao.dataFim
      );
    },
    declaracaoIniciada() {
      return this.declaracaoDocente ? true : false;
    },
    usuario() {
      return (this.$store.getters.usuario as Usuario) || null;
    },
  },
  mounted() {
    this.buscaDados();
  },
  methods: {
    async buscaDados() {
      await this.verificaPeriodoDeclaracaoAtivo();
      await this.verificaDeclaracaoIniciada();
    },
    async verificaPeriodoDeclaracaoAtivo() {
      this.loading.periodoDeclaracao = true;
      try {
        this.periodoDeclaracao =
          await requester.periodoDeclaracao.private.buscaAtual();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.periodoDeclaracao = false;
      }
    },
    async verificaDeclaracaoIniciada() {
      if (!this.periodoDeclaracao) {
        this.loading.declaracao = false;
        return;
      }
      this.loading.declaracao = true;
      try {
        this.declaracaoDocente =
          await requester.docente.private.buscaDeclaracaoByPeriodoId(
            this.usuario.id,
            this.periodoDeclaracao.id
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.declaracao = false;
      }
    },
    declaracao() {
      if (!this.declaracaoIniciada) {
        this.iniciaDeclaracao();
      } else {
        this.tab = 1;
      }
    },
    async iniciaDeclaracao() {
      try {
        await requester.docente.private.criaDeclaracao(this.usuario.id);
        this.$root.$emit("toast-success", "Declaração iniciada!");
        this.buscaDados();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async alteraFlagConclusao(completa: boolean) {
      try {
        await requester.docente.private.alteraFlagConclusao(
          this.declaracaoDocente.id,
          this.usuario.id,
          {
            completa,
          }
        );
        if (completa === false)
          this.$root.$emit(
            "toast-success",
            "Declaração aberta para alterações!"
          );
        else {
          this.$root.$emit(
            "toast-success",
            "Declaração marcada como completa!"
          );
        }
        this.buscaDados();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
});
