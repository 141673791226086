import Vue from "vue";
import Vuex from "vuex";
import vuexPersistedState from "vuex-persistedstate";

import { SessionUsuario } from "@back-src/auth/sessionUsuario.interface";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [vuexPersistedState()],
  state: {
    usuario: null,
    // USADO PRA DEV
    // wsuserid: null,
  },
  getters: {
    usuario: (state) => state.usuario,
    wsuserid: (state) => state.usuario?.wsuserid,
    // USADO PRA DEV
    // wsuserid: (state) => state.wsuserid,
  },
  mutations: {
    setUsuario(state, usuario) {
      state.usuario = usuario;
    },
    clearUsuario(state) {
      state.usuario = null;
    },
    // USADO PRA DEV
    // wsuserid(state, wsuserid) {
    //   state.wsuserid = wsuserid;
    // },
  },
  actions: {
    login(context, usuarioSession: SessionUsuario) {
      context.commit("setUsuario", usuarioSession);
    },
    logout(context) {
      context.commit("clearUsuario");
    },
    // USADO PRA DEV
    // wsuserid(context, wsuserid: string) {
    //   context.commit("wsuserid", wsuserid);
    // },
  },
  modules: {},
});
