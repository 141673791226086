/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

const metabaseLogin = async (formData): Promise<string> => {
  const res = await axios.post<string>(`/metabase/login`, formData);
  return res.data;
};

const listaItensColecao = async (
  colecaoId: number | string,
  secret: string
): Promise<any> => {
  const res = await axios.post<any>(`/metabase/colecao/${colecaoId}/items`, {
    secret,
  });
  return res.data;
};

const listaColecoes = async (secret: string): Promise<any> => {
  const res = await axios.post<any>("/metabase/colecao", { secret });
  return res.data;
};

const buscaCardById = async (cardId: number, secret: string): Promise<any> => {
  const res = await axios.post<any>(`/metabase/card/${cardId}`, {
    secret,
  });
  return res.data;
};

const buscaDadosJsonCardById = async (
  cardId: number,
  secret: string,
  params = null
): Promise<any> => {
  const res = await axios.post<any>(`/metabase/card_json/${cardId}`, {
    secret,
    params: params ? JSON.stringify(params) : null,
  });
  return res.data;
};

const buscaGrafico = async (questionId, dto): Promise<string> => {
  const res = await axios.post<string>(`/metabase/grafico/${questionId}`, dto);
  return res.data;
};

export default {
  private: {
    metabaseLogin,
    buscaCardById,
    buscaDadosJsonCardById,
    listaItensColecao,
    listaColecoes,
    buscaGrafico,
  },
};
