
import Vue from "vue";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import CustomColInfo from "../CustomColInfo.vue";
import requester from "@/requester";

export default Vue.extend({
  name: "DeclaracaoItemDialogInfo",
  components: { CustomColInfo },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    declaracaoItem: {
      type: Object as () => DeclaracaoItem,
      default: null,
    },
    declaracaoItemId: {
      type: Number,
      default: null,
    },
    exibirDeclarante: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemBuscado: null,
    };
  },
  mounted() {
    if (!this.declaracaoItem) {
      this.buscaItem();
    }
  },
  computed: {
    itemExibido() {
      if (this.declaracaoItem) {
        return this.declaracaoItem;
      }
      return this.itemBuscado;
    },
    arrayDeclarante() {
      if (!this.exibirDeclarante) return [];
      return [
        {
          title: "Declarante",
          content:
            this.itemExibido.declaracao?.unidade?.sigla ||
            `${this.itemExibido.declaracao?.usuarioCreatedBy.unidade?.sigla} - ${this.itemExibido.declaracao.usuarioCreatedBy.nome}`,
          sm: 6,
        },
      ];
    },
    arrayAnimal() {
      return [
        {
          title: "Espécie",
          content: this.itemExibido?.categoria?.especie?.nome,
          sm: 6,
        },
        {
          title: "Categoria",
          content: this.itemExibido?.categoria?.nome,
          sm: 6,
        },
        {
          title: "Lote",
          content: this.itemExibido?.lote ? "Sim" : "Não",
          sm: 6,
        },
        {
          title: "Quantidade de animais",
          content: `${this.itemExibido?.qtdLote}`,
          sm: 6,
        },
      ];
    },
    arrayIdentificacao() {
      return [
        {
          title: "Origem particular",
          content: this.itemExibido?.origemParticular ? "Sim" : "Não",
          sm: 6,
        },
        {
          title: "Termo de cooperação",
          content: this.hrefTermoCooperacaoArquivo,
          sm: 6,
        },
        {
          title: "Número de patrimônio",
          content: this.itemExibido?.numPatrimonio,
          sm: 6,
        },
        {
          title: "Número de cadastro",
          content: this.itemExibido?.numCadastro,
          sm: 6,
        },
        {
          title: "Proprietário",
          content: this.itemExibido?.proprietario,
          sm: 6,
        },
        {
          title: "Dias de uso",
          content: this.itemExibido?.diasUso
            ? this.itemExibido?.diasUso.toString()
            : null,
          sm: 6,
        },
        {
          title: "Destino pós uso",
          content: this.itemExibido?.destinoPosUso,
          sm: 6,
        },
        {
          title: "Destinatário pós uso",
          content: this.itemExibido?.destinatarioPosUso,
          sm: 6,
        },
        {
          title: "Observações",
          content: this.itemExibido?.obs,
          sm: 12,
        },
      ];
    },
    nomeTermoCooperacaoArquivo() {
      return this.itemExibido?.termoCooperacaoArquivo?.originalname;
    },
    hrefTermoCooperacaoArquivo() {
      return this.itemExibido?.origemParticular
        ? `${process.env.VUE_APP_BASE_URL}declaracao_items/${this.itemExibido.id}/termo_cooperacao/presigned_url`
        : "";
    },
  },
  methods: {
    async buscaItem() {
      try {
        this.itemBuscado = await requester.admin.private.buscaItemPorId(
          this.declaracaoItemId
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    closeDialog() {
      this.$refs?.form?.reset();
      this.$emit("close");
      this.$emit("input", false);
    },
  },
});
