
import Vue from "vue";
import * as XLSX from "xlsx";
import requester from "@/requester";

export default Vue.extend({
  name: "ExportarButtonAdmin",
  props: {
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async baixarDados() {
      this.loading = true;
      try {
        const data = new Date().toJSON().slice(0, 10);
        const filename = `${data}_dadosCompletos.xlsx`;
        const resposta = await requester.admin.private.exportarDadosPeriodo(
          this.periodoDeclaracaoId
        );
        const workbook = XLSX.utils.book_new();
        const worksheetItens = XLSX.utils.json_to_sheet(resposta.dadosItens);
        const worksheetReceita = XLSX.utils.json_to_sheet(
          resposta.dadosReceita
        );
        XLSX.utils.book_append_sheet(workbook, worksheetItens, "itens");
        XLSX.utils.book_append_sheet(workbook, worksheetReceita, "receita");
        XLSX.writeFile(workbook, filename, {
          bookType: "xlsx",
        });
      } catch (err) {
        this.$root.$errorHandler(err);
      }
      this.loading = false;
    },
  },
});
