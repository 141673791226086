
import Vue from "vue";
import { ModoFinanciamento } from "@/utils/enums";
import { ProjetoPesquisa } from "@back-src/models/projeto-pesquisa.model";
import CustomColInfo from "../CustomColInfo.vue";
import FileDownloadLink from "../FileDownloadLink.vue";

export default Vue.extend({
  components: {
    CustomColInfo,
    FileDownloadLink,
  },
  name: "DocenteProjetoInfoCard",
  props: {
    projeto: {
      type: Object as () => ProjetoPesquisa,
      default: null,
    },
    showBotaoAlterar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nomeConvenioEmpresaArquivo() {
      return this.projeto?.convenioEmpresaArquivo?.originalname;
    },
    hrefConvenioEmpresaArquivo() {
      return `${process.env.VUE_APP_BASE_URL}projetos_pesquisa/${this.projeto.id}/convenio_empresa_arquivo/presigned_url`;
    },
    modoFinanciamentoPublico() {
      return ModoFinanciamento.PUBLICO;
    },
    modoFinanciamentoPrivado() {
      return ModoFinanciamento.PRIVADO;
    },
  },
});
