import { Declaracao } from "@back-src/models/declaracao.model";
import { AlteraVetoDeclaracaoItemDto } from "@back-src/admin/dto/altera-veto-declaracao-item.dto";
import axios from "axios";
import { TrocaFlagAtivoDto } from "@back-src/usuario/dto/troca-flag-ativo.dto";
import { TrocaCategoriaDto } from "@back-src/usuario/dto/troca-categoria.dto";
import { IndeferirUsuarioDto } from "@back-src/usuario/dto/indeferir-usuario.dto";
import { Usuario } from "@back-src/models/usuario.model";
import { ViewItensUtilizadosComCusto } from "@back-src/models/view-itens-utilizados-com-custo.model";
import { ProjetoPesquisa } from "@back-src/models/projeto-pesquisa.model";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import { DadosExportados } from "@back-src/utils/constants";

const listaDeclaracoesPorPeriodo = async (
  periodoId: number
): Promise<Declaracao[]> => {
  const res = await axios.get<Declaracao[]>(
    `/admin/periodo/${periodoId}/declaracoes`
  );
  return res.data;
};

const alteraVetoDeclaracaoItem = async (
  formData: AlteraVetoDeclaracaoItemDto,
  periodoId: number,
  declaracaoId: number,
  declaracaoItemId: number
): Promise<void> => {
  const res = await axios.post<void>(
    `/admin/periodo/${periodoId}/declaracao/${declaracaoId}/declaracao_item/${declaracaoItemId}/altera_veto`,
    formData
  );
  return res.data;
};

const alteraVetoDeclaracaoBulk = async (
  formData: AlteraVetoDeclaracaoItemDto,
  periodoId: number,
  declaracaoId: number
): Promise<void> => {
  const res = await axios.post<void>(
    `/admin/periodo/${periodoId}/declaracao/${declaracaoId}/altera_veto_bulk`,
    formData
  );
  return res.data;
};

const trocaFlagAtivo = async (
  formData: TrocaFlagAtivoDto
): Promise<Usuario> => {
  const res = await axios.put<Usuario>("/admin/troca_ativacao", formData);
  return res.data;
};

const trocaCategoria = async (
  formData: TrocaCategoriaDto
): Promise<Usuario> => {
  const res = await axios.put<Usuario>("/admin/troca_categoria", formData);
  return res.data;
};

const buscaDeclaracaoByIdDocente = async (
  declaracaoId: number,
  usuarioId: number
): Promise<Declaracao> => {
  const res = await axios.get<Declaracao>(
    `admin/docentes/${usuarioId}/declaracoes/${declaracaoId}`
  );
  return res.data;
};

const buscaDeclaracaoByIdUnidade = async (
  declaracaoId: number,
  unidadeId: number
): Promise<Declaracao> => {
  const res = await axios.get<Declaracao>(
    `admin/unidades/${unidadeId}/declaracoes/${declaracaoId}`
  );
  return res.data;
};

const listaProjetosByDeclaracaoId = async (
  declaracaoId: number,
  periodoDeclaracaoId: number,
  usuarioId: number
): Promise<ProjetoPesquisa[]> => {
  const res = await axios.get<ProjetoPesquisa[]>(
    `admin/docentes/${usuarioId}/periodo/${periodoDeclaracaoId}/declaracao/${declaracaoId}/projeto_pesquisa`
  );
  return res.data;
};

const buscaProjeto = async (
  declaracaoId: number,
  periodoDeclaracaoId: number,
  projetoId: number,
  usuarioId: number
): Promise<ProjetoPesquisa> => {
  const res = await axios.get<ProjetoPesquisa>(
    `admin/docentes/${usuarioId}/periodo/${periodoDeclaracaoId}/declaracao/${declaracaoId}/projeto_pesquisa/${projetoId}`
  );
  return res.data;
};
const indefereUsuario = async (
  formData: IndeferirUsuarioDto
): Promise<Usuario> => {
  const res = await axios.put<Usuario>("/admin/indefere_usuario", formData);
  return res.data;
};

const reverteIndeferimentoUsuario = async (
  formData: IndeferirUsuarioDto
): Promise<Usuario> => {
  const res = await axios.put<Usuario>(
    "/admin/reverte_indeferimento",
    formData
  );
  return res.data;
};

const listaItensDeclaracaoUtilizados = async (
  periodoDeclaracaoId: number
): Promise<ViewItensUtilizadosComCusto[]> => {
  const res = await axios.get<ViewItensUtilizadosComCusto[]>(
    `/admin/itens_utilizados/periodo_declaracao/${periodoDeclaracaoId}`
  );
  return res.data;
};

const buscaItensPorNum = async (
  periodoDeclaracaoId: number,
  numeroParcial: string
): Promise<DeclaracaoItem[]> => {
  const res = await axios.get<DeclaracaoItem[]>(
    `/admin/periodo_declaracao/${periodoDeclaracaoId}/busca_itens`,
    {
      params: { numeroParcial },
    }
  );
  return res.data;
};

const buscaItemPorId = async (
  declaracaoItemId: number
): Promise<DeclaracaoItem> => {
  const res = await axios.get<DeclaracaoItem>(
    `/admin/declaracao_item/${declaracaoItemId}`
  );
  return res.data;
};

const listaProjetosPorPeriodoId = async (
  periodoDeclaracaoId: number
): Promise<DadosExportados> => {
  const res = await axios.get<DadosExportados>(
    `/admin/periodo_declaracao/${periodoDeclaracaoId}/projetos`
  );
  return res.data;
};

const exportarDadosPeriodo = async (
  periodoDeclaracaoId: number
): Promise<DadosExportados> => {
  const res = await axios.get<DadosExportados>(
    `/admin/periodo_declaracao/${periodoDeclaracaoId}/exportar`
  );
  return res.data;
};

export default {
  private: {
    alteraVetoDeclaracaoBulk,
    alteraVetoDeclaracaoItem,
    buscaDeclaracaoByIdDocente,
    buscaDeclaracaoByIdUnidade,
    buscaProjeto,
    exportarDadosPeriodo,
    listaDeclaracoesPorPeriodo,
    listaProjetosByDeclaracaoId,
    listaProjetosPorPeriodoId,
    trocaCategoria,
    trocaFlagAtivo,
    indefereUsuario,
    reverteIndeferimentoUsuario,
    listaItensDeclaracaoUtilizados,
    buscaItensPorNum,
    buscaItemPorId,
  },
};
