import axios from "axios";
import store from "../store";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";

const consultaApiPatrimonio = async (numPatrimonio: string): Promise<any> => {
  const res = await axios.get<any>(
    `${process.env.VUE_APP_API_STI_URL_BASE}${numPatrimonio}`,
    {
      withCredentials: false,
      headers: {
        // // INSERIR WSUSERID PARA DEV
        "Usp-Key": store.getters.wsuserid,
        "Service-Key": process.env.VUE_APP_API_STI_HEADER_SERVICE_KEY,
      },
    }
  );

  return res.data;
};

const validaItemApiPatrimonio = async (
  declaracaoItemId: number
): Promise<DeclaracaoItem | number> => {
  const res = await axios.put(
    `declaracao_items/${declaracaoItemId}/valida_num_patrimonio`,
    {}
  );
  return res.data;
};

export default {
  private: {
    consultaApiPatrimonio,
    validaItemApiPatrimonio,
  },
};
