
import {
  CategoriaUsuarioEnum,
  PREFEITURA_UNIDADE_ID,
} from "../../../back/src/utils/constants";
import Vue from "vue";

export default Vue.extend({
  name: "TheButtonsMenu",
  data: () => ({
    admMenu: [
      {
        title: "Períodos de declaração",
        icon: "mdi-calendar",
        to: { name: "PeriodoDeclaracaoIndex" },
      },
      {
        title: "Relatórios",
        icon: "mdi-chart-bar",
        to: { name: "RelatoriosIndex" },
      },
      {
        title: "Série Histórica",
        icon: "mdi-timer-sand",
        to: { name: "RelatoriosSerieHistorica" },
      },
      {
        title: "Gestão de usuários",
        icon: "mdi-cog",
        to: { name: "GestaoUsuarios" },
      },
    ],
  }),
  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },
    isAdmin() {
      return this.usuario
        ? this.usuario.categoriaUsuario === CategoriaUsuarioEnum.ADMIN
        : false;
    },
    menuComputed() {
      if (this.usuario?.unidadeId === PREFEITURA_UNIDADE_ID) {
        return this.prefeituraMenu;
      }
      if (this.usuario?.unidadeId) {
        return this.unidadesMenu;
      }
      if (this.isAdmin) {
        return this.admMenu;
      }
      return this.docentesMenu;
    },
  },
});
