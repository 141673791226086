import { Categoria } from "@back-src/models/categoria.model";
import axios from "axios";

const lista = async (): Promise<Categoria[]> => {
  const res = await axios.get<Categoria[]>("/categorias");
  return res.data;
};

export default {
  private: {
    lista,
  },
};
