import { RouteConfig } from "vue-router";

import RelatoriosIndex from "@/pages/admin/RelatoriosIndex.vue";
import GestaoUsuarios from "@/pages/admin/GestaoUsuarios.vue";
import PeriodoDeclaracaoIndex from "@/pages/admin/PeriodoDeclaracaoIndex.vue";
import PeriodoDeclaracaoCriar from "@/pages/admin/PeriodoDeclaracaoCriar.vue";
import PeriodoDeclaracaoVerAlterar from "@/pages/admin/PeriodoDeclaracaoVerAlterar.vue";
import PeriodoDeclaracaoVerDeclaracoes from "@/pages/admin/PeriodoDeclaracaoVerDeclaracoes.vue";
import RelatoriosSerieHistorica from "@/pages/admin/RelatoriosSerieHistorica.vue";

const routes: RouteConfig[] = [
  {
    path: "/relatorios",
    name: "RelatoriosIndex",
    component: RelatoriosIndex,
    meta: {
      public: false,
    },
  },
  {
    path: "/serie_historica",
    name: "RelatoriosSerieHistorica",
    component: RelatoriosSerieHistorica,
    meta: {
      public: false,
    },
  },
  {
    path: "/periodo_declaracao/criar",
    name: "PeriodoDeclaracaoCriar",
    component: PeriodoDeclaracaoCriar,
    meta: {
      public: false,
    },
  },
  {
    path: "/periodo_declaracao/:periodoDeclaracaoId/ver_alterar/",
    name: "PeriodoDeclaracaoVerAlterar",
    component: PeriodoDeclaracaoVerAlterar,
    meta: {
      public: false,
    },
  },
  {
    path: "/periodo_declaracao/:periodoDeclaracaoId/declaracoes",
    name: "PeriodoDeclaracaoVerDeclaracoes",
    component: PeriodoDeclaracaoVerDeclaracoes,
    meta: {
      public: false,
    },
  },
  {
    path: "/periodo_declaracao/",
    name: "PeriodoDeclaracaoIndex",
    component: PeriodoDeclaracaoIndex,
    meta: {
      public: false,
    },
  },
  {
    path: "/gestao_usuarios/",
    name: "GestaoUsuarios",
    component: GestaoUsuarios,
    meta: {
      public: false,
    },
  },
];

export default routes;
