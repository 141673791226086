
import Vue from "vue";
import CustomTabItem from "../CustomTabItem.vue";
import ExportarButton from "../ExportarButton.vue";
import DeclaracaoUnidadeTabela from "../declaracao/DeclaracaoUnidadeTabela.vue";
import PeriodoDeclaracaoSelect from "../periodo-declaracao/PeriodoDeclaracaoSelect.vue";
export default Vue.extend({
  name: "UnidadeHistoricoDeclaracaoTabItem",
  components: {
    CustomTabItem,
    ExportarButton,
    DeclaracaoUnidadeTabela,
    PeriodoDeclaracaoSelect,
  },
  props: {
    unidadeId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    periodoSelecionado: null,
    declaracaoSelecionada: null,
    loading: false,
  }),
});
