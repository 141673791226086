
import Vue from "vue";
import requester from "../../requester";
import { ProjetoPesquisa } from "@back-src/models/projeto-pesquisa.model";
import FileDownloadLink from "../FileDownloadLink.vue";
export default Vue.extend({
  name: "DocenteProjetoTabelaVer",
  components: { FileDownloadLink },
  props: {
    declaracaoId: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    usuarioId: {
      type: Number,
      default: null,
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      projetosPesquisa: true,
    },
    search: null,
    projetosPesquisa: [] as ProjetoPesquisa[] | [],
  }),
  computed: {
    headers() {
      return [
        {
          value: "titulo",
          text: "Título",
          class: "text-no-wrap",
          sortable: false,
        },
        {
          value: "responsavel",
          text: "Responsável",
          class: "text-no-wrap",
          sortable: false,
        },
        {
          value: "numProjeto",
          text: "Número do projeto",
          class: "text-no-wrap",
          sortable: true,
        },
        {
          value: "numCeua",
          text: "Número CEUA",
          class: "text-no-wrap",
          sortable: false,
        },
        ...(!this.isAdmin
          ? [
              {
                value: "alterar",
                text: "Alterar",
                class: "text-no-wrap",
                sortable: false,
                align: "center",
              },
              {
                value: "declarar",
                text: "Declarar animais",
                class: "text-no-wrap",
                sortable: false,
                align: "center",
              },
            ]
          : [{}]),
        ...(this.isAdmin
          ? [
              {
                value: "visualizar",
                text: "Visualizar",
                class: "text-no-wrap",
                sortable: false,
                align: "center",
              },
            ]
          : [{}]),
      ];
    },
    isAdmin() {
      return this.admin;
    },
  },
  mounted() {
    if (!this.admin) {
      this.listaProjetos();
    } else {
      this.listaProjetosAdmin();
    }
  },
  methods: {
    async listaProjetos() {
      this.loading.projetosPesquisa = true;
      try {
        this.projetosPesquisa =
          await requester.docente.private.listaProjetosByDeclaracaoId(
            this.declaracaoId,
            this.periodoDeclaracaoId,
            this.usuarioId
          );
        this.loading.projetosPesquisa = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaProjetosAdmin() {
      this.loading.projetosPesquisa = true;
      try {
        this.projetosPesquisa =
          await requester.admin.private.listaProjetosByDeclaracaoId(
            this.declaracaoId,
            this.periodoDeclaracaoId,
            this.usuarioId
          );
        this.loading.projetosPesquisa = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
  watch: {
    usuarioId() {
      if (!this.admin) {
        this.listaProjetos();
      } else {
        this.listaProjetosAdmin();
      }
    },
  },
});
