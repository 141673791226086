
import Vue from "vue";
import {
  CategoriaUsuarioEnum,
  PREFEITURA_UNIDADE_ID,
  CATEGORIA_PREFEITURA,
} from "../../../../back/src/utils/constants";
import requester from "@/requester";
import CustomHeader from "../../components/CustomHeader.vue";
import VinculosUsuariosDialog from "../../components/admin/VinculosUsuariosDialog.vue";
import { rulesRequired } from "@/utils/input-rules";
import { TrocaCategoriaDto } from "@back-src/usuario/dto/troca-categoria.dto";

export default Vue.extend({
  components: {
    VinculosUsuariosDialog,
    CustomHeader,
  },
  name: "GestaoUsuarios",
  data: () => ({
    CategoriaUsuarioEnum,
    rules: {
      required: rulesRequired,
    },
    categorias: [
      CategoriaUsuarioEnum.ADMIN,
      CategoriaUsuarioEnum.DOCENTE,
      CategoriaUsuarioEnum.UNIDADE,
      CATEGORIA_PREFEITURA,
    ],
    usuarios: [],
    unidades: [],
    form: [],
    tab: 0,
    dialogVinculos: false,
    usuarioSelecionado: null,
  }),
  async mounted() {
    this.buscaUnidades();
    await this.listaUsuarios();
    this.montaForm();
  },
  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },
    unidadesFiltered() {
      // Prefeitura é uma unidade com comportamento diferente
      return this.unidades.filter(
        (unidade) => unidade.id !== PREFEITURA_UNIDADE_ID
      );
    },
    formUsuariosIndeferidos() {
      return this.form.filter((el) => el.indeferido);
    },
    formUsuariosValidos() {
      return this.form.filter((el) => !el.indeferido);
    },
    headers() {
      return [
        {
          value: "usuario.indeferido",
          text: "Indeferir",
          sortable: false,
          align: "center",
        },
        {
          value: "usuario.ativo",
          text: "Ativo?",
          sortable: true,
        },
        {
          value: "usuario.login",
          text: "Login/n. USP",
          sortable: true,
        },
        {
          value: "usuario.nome",
          text: "Nome",
          sortable: true,
        },
        {
          value: "usuario.emailPrincipal",
          text: "E-mail",
          sortable: true,
        },
        {
          value: "usuario.vinculos",
          text: "Vínculos",
          sortable: false,
          align: "center",
        },
        {
          value: "usuario.categoriaUsuario",
          text: "Categoria",
          sortable: true,
        },
        {
          value: "usuario.unidade.sigla",
          text: "Unidade",
          sortable: true,
        },
        {
          value: "acoes",
          text: "",
          sortable: false,
          align: "center",
        },
      ];
    },
    headersIndeferidos() {
      return [
        {
          value: "usuario.indeferido",
          text: "Reverter indeferimento",
          sortable: false,
          align: "center",
        },
        {
          value: "usuario.ativo",
          text: "Ativo?",
          sortable: true,
        },
        {
          value: "usuario.login",
          text: "Login/n. USP",
          sortable: true,
        },
        {
          value: "usuario.nome",
          text: "Nome",
          sortable: true,
        },
        {
          value: "usuario.emailPrincipal",
          text: "E-mail",
          sortable: true,
        },
        {
          value: "usuario.vinculos",
          text: "Vínculos",
          sortable: false,
          align: "center",
        },
        {
          value: "usuario.categoriaUsuario",
          text: "Categoria",
          sortable: true,
        },
        {
          value: "usuario.unidade.sigla",
          text: "Unidade",
          sortable: true,
        },
      ];
    },
  },
  methods: {
    verVinculos(usuario) {
      this.usuarioSelecionado = usuario;
      this.dialogVinculos = true;
    },
    isDirty(item) {
      if (
        item.categoria === CATEGORIA_PREFEITURA &&
        item.usuario.categoriaUsuario === CategoriaUsuarioEnum.UNIDADE &&
        item.usuario.unidadeId === PREFEITURA_UNIDADE_ID
      ) {
        return false;
      }
      if (
        item.usuario.categoriaUsuario !== item.categoria ||
        item.usuario.unidadeId !== item.unidadeId
      ) {
        return true;
      }
    },
    async buscaUnidades() {
      try {
        this.unidades = await requester.unidade.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    montaForm() {
      // Prefeitura é uma unidade com comportamento diferente
      this.form = [];
      this.usuarios.forEach((usuario) => {
        let categoria = usuario.categoriaUsuario;
        if (
          usuario.categoriaUsuario === CategoriaUsuarioEnum.UNIDADE &&
          usuario.unidadeId === PREFEITURA_UNIDADE_ID
        ) {
          categoria = CATEGORIA_PREFEITURA;
        }
        this.form.push({
          id: usuario.id,
          categoria,
          unidadeId: usuario.unidadeId,
          ativo: usuario.ativo,
          indeferido: usuario.indeferido,
          usuario,
        });
      });
    },
    async submitAtivacao(usuarioId: number, ativo: boolean) {
      const confirm = await this.$root.$confirm(
        "Alterar ativação?",
        "Um e-mail de notificação será enviado ao usuário após essa ação. Deseja confirmar?",
        {
          textConfirm: "Sim, realizar alterações",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      try {
        const usuario = await requester.admin.private.trocaFlagAtivo({
          usuarioId,
          ativo,
        });
        const index: number = this.form.findIndex((el) => el.id === usuarioId);
        this.form[index].ativo = usuario.ativo;
        this.form[index].usuario = usuario;
        this.$root.$emit("toast-success", "Usuário atualizado");
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async submitCategoria(
      usuarioId: number,
      categoria: string,
      unidadeId: number | null
    ) {
      if (
        [
          CategoriaUsuarioEnum.UNIDADE as string,
          CategoriaUsuarioEnum.DOCENTE as string,
        ].includes(categoria) &&
        !unidadeId
      ) {
        this.$root.$emit("toast-warning", "Preencha o campo da unidade");
        return;
      }

      const dto: TrocaCategoriaDto = {
        usuarioId,
        categoria,
        unidadeId,
      };
      if (
        ![
          CategoriaUsuarioEnum.UNIDADE as string,
          CategoriaUsuarioEnum.DOCENTE as string,
        ].includes(categoria)
      ) {
        dto.unidadeId = null;
      }
      // Prefeitura é uma unidade com comportamento diferente
      if (categoria === CATEGORIA_PREFEITURA) {
        dto.categoria = CategoriaUsuarioEnum.UNIDADE;
        dto.unidadeId = PREFEITURA_UNIDADE_ID;
      }
      try {
        const usuario = await requester.admin.private.trocaCategoria(dto);
        const index: number = this.form.findIndex((el) => el.id === usuarioId);
        this.form[index].usuario = usuario;
        this.$root.$emit("toast-success", "Usuário atualizado");
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async submitIndeferir(usuarioId: number) {
      const confirm = await this.$root.$confirm(
        "Indeferir acesso ao sistema?",
        "Este usuário não poderá mais acessar o sistema. Confirma esta ação?",
        {
          textConfirm: "Sim, indeferir usuário",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      try {
        const usuario = await requester.admin.private.indefereUsuario({
          usuarioId,
        });
        const index: number = this.form.findIndex((el) => el.id === usuarioId);
        this.form[index].indeferido = usuario.indeferido;
        this.form[index].usuario = usuario;
        this.$root.$emit("toast-success", "Usuário atualizado");
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async submitUndoIndeferir(usuarioId: number) {
      const confirm = await this.$root.$confirm(
        "Desfazer indeferimento?",
        "Este usuário irá voltar para a lista de usuários. Confirma esta ação?",
        {
          textConfirm: "Sim, confirmar",
          textCancel: "Não, manter indeferimento",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      try {
        const usuario =
          await requester.admin.private.reverteIndeferimentoUsuario({
            usuarioId,
          });
        const index: number = this.form.findIndex((el) => el.id === usuarioId);
        this.form[index].indeferido = usuario.indeferido;
        this.form[index].usuario = usuario;
        this.$root.$emit("toast-success", "Usuário atualizado");
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaUsuarios() {
      try {
        this.usuarios = await requester.usuario.private.lista(
          "vinculosEUnidade"
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
  watch: {
    dialogVinculos(newValue) {
      if (!newValue) {
        this.usuarioSelecionado = null;
      }
    },
  },
});
