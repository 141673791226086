<template>
  <div>
    <TabelaRelatoriosProjetoCompleto
      :itens="projetosComputedFiltered"
      :headers="headerCompleto"
      :loading="loading"
    />
    <v-row>
      <v-col cols="6">
        <TabelaRelatoriosProjetoResumoDocente
          class="mt-3"
          :headers="headersResumo('docente')"
          :itens="itensResumoDocente"
          :loading="loading"
        />
      </v-col>
      <v-col cols="6">
        <TabelaRelatoriosProjetoResumoUnidade
          class="mt-3"
          :headers="headersResumo('unidade')"
          :itens="itensResumoUnidade"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import requester from "../../requester";
import { sumBy, groupBy } from "lodash";
import TabelaRelatoriosProjetoCompleto from "./TabelaRelatoriosProjetoCompleto.vue";
import TabelaRelatoriosProjetoResumoDocente from "./TabelaRelatoriosProjetoResumoDocente.vue";
import TabelaRelatoriosProjetoResumoUnidade from "./TabelaRelatoriosProjetoResumoUnidade.vue";

export default Vue.extend({
  name: "RelatoriosProjetosPesquisa",
  components: {
    TabelaRelatoriosProjetoCompleto,
    TabelaRelatoriosProjetoResumoDocente,
    TabelaRelatoriosProjetoResumoUnidade,
  },
  props: {
    periodoDeclaracaoId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    projetos: [],
    docentesUnidades: {},
    headerCompleto: [
      {
        text: "Projeto",
        value: "projeto",
        sortable: true,
      },
      {
        text: "Docente",
        value: "docente",
      },
      {
        text: "Unidade",
        value: "unidade",
      },
      {
        text: "Valor",
        value: "valor",
        align: "right",
      },
      {
        text: "% Uni.",
        value: "percUnidade",
        align: "right",
      },
      {
        text: "Bolsas",
        value: "bolsas",
        align: "right",
      },
      {
        text: "IC",
        value: "iniciacaoCientifica",
        align: "right",
      },
      {
        text: "MsC",
        value: "mestrado",
        align: "right",
      },
      {
        text: "PhD",
        value: "doutorado",
        align: "right",
      },
      {
        text: "PosDoc",
        value: "posDoutorado",
        align: "right",
      },
      {
        text: "Convênio?",
        value: "convenio",
        align: "center",
      },
    ],
  }),
  mounted() {
    this.buscaProjetos();
  },
  computed: {
    projetosComputedFiltered() {
      return this.projetos.map((el) => {
        const iniciacaoCientifica = parseInt(el.iniciacaoCientifica || 0);
        const mestrado = parseInt(el.mestrado || 0);
        const doutorado = parseInt(el.doutorado || 0);
        const posDoutorado = parseInt(el.posDoutorado || 0);
        this.docentesUnidades[el.declaracao.usuarioCreatedBy.nome] =
          el.declaracao.usuarioCreatedBy.unidade.sigla;
        return {
          projeto: el.titulo,
          docente: el.declaracao.usuarioCreatedBy.nome,
          unidade: el.declaracao.usuarioCreatedBy.unidade.sigla,
          valor: el.valor,
          bolsas: iniciacaoCientifica + mestrado + doutorado + posDoutorado,
          iniciacaoCientifica,
          mestrado,
          doutorado,
          posDoutorado,
          convenio:
            el.possuiFinanciamento && el.modoFinanciamento === "Privado"
              ? "Sim"
              : "",
        };
      });
    },
    itensResumoDocente() {
      const groupDocente = Object.keys(
        groupBy(this.projetosComputedFiltered, "docente")
      );
      const itens = [];
      groupDocente.forEach((docente) => {
        const iniciacaoCientifica = this.somaFiltrado(
          "docente",
          docente,
          "iniciacaoCientifica"
        );
        const mestrado = this.somaFiltrado("docente", docente, "mestrado");
        const doutorado = this.somaFiltrado("docente", docente, "doutorado");
        const posDoutorado = this.somaFiltrado(
          "docente",
          docente,
          "posDoutorado"
        );
        itens.push({
          docente,
          unidade: this.docentesUnidades[docente] || null,
          valor: this.somaFiltrado("docente", docente, "valor"),
          bolsas: iniciacaoCientifica + mestrado + doutorado + posDoutorado,
          iniciacaoCientifica,
          mestrado,
          doutorado,
          posDoutorado,
        });
      });
      return itens;
    },
    itensResumoUnidade() {
      const groupUnidade = Object.keys(
        groupBy(this.projetosComputedFiltered, "unidade")
      );
      const itens = [];
      groupUnidade.forEach((unidade) => {
        const iniciacaoCientifica = this.somaFiltrado(
          "unidade",
          unidade,
          "iniciacaoCientifica"
        );
        const mestrado = this.somaFiltrado("unidade", unidade, "mestrado");
        const doutorado = this.somaFiltrado("unidade", unidade, "doutorado");
        const posDoutorado = this.somaFiltrado(
          "unidade",
          unidade,
          "posDoutorado"
        );
        itens.push({
          unidade,
          valor: this.somaFiltrado("unidade", unidade, "valor"),
          bolsas: iniciacaoCientifica + mestrado + doutorado + posDoutorado,
          iniciacaoCientifica,
          mestrado,
          doutorado,
          posDoutorado,
          numProjetos: parseInt(this.filtraProjetos("unidade", unidade).length),
        });
      });
      return itens;
    },
    valorTotal() {
      return sumBy(this.projetos, function (el) {
        return el.valor;
      });
    },
  },
  methods: {
    headersResumo(tipo) {
      const headers = [
        {
          text: "Unidade",
          value: "unidade",
        },
        {
          text: "Valor",
          value: "valor",
          align: "right",
        },
        {
          text: "Bolsas",
          value: "bolsas",
          align: "right",
        },
        {
          text: "IC",
          value: "iniciacaoCientifica",
          align: "right",
        },
        {
          text: "MsC",
          value: "mestrado",
          align: "right",
        },
        {
          text: "PhD",
          value: "doutorado",
          align: "right",
        },
        {
          text: "PosDoc",
          value: "posDoutorado",
          align: "right",
        },
      ];
      if (tipo === "docente") {
        headers.unshift({
          text: "Docente",
          value: "docente",
        });
      } else {
        headers.push({
          text: "Qt. Proj.",
          value: "numProjetos",
          align: "right",
        });
      }
      return headers;
    },
    filtraProjetos(chave, valor) {
      return this.projetosComputedFiltered.filter((el) => el[chave] === valor);
    },
    somaFiltrado(chaveFiltro, valorFiltro, attrSoma) {
      return (
        sumBy(this.filtraProjetos(chaveFiltro, valorFiltro), attrSoma) || 0
      );
    },
    async buscaProjetos() {
      this.loading = true;
      try {
        this.projetos = await requester.admin.private.listaProjetosPorPeriodoId(
          this.periodoDeclaracaoId
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    periodoDeclaracaoId() {
      this.buscaProjetos();
    },
  },
});
</script>
