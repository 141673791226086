
import Vue from "vue";
export default Vue.extend({
  name: "EmbededVideoDialog",
  props: {
    src: {
      type: String,
      default: "",
    },
    thumbSrc: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    width() {
      if (
        this.$vuetify.breakpoint.height * (16 / 9) <
        this.$vuetify.breakpoint.width
      ) {
        return Math.round(this.height * (16 / 9));
      }
      return this.$vuetify.breakpoint.width * 0.9;
    },
    height() {
      if (
        this.$vuetify.breakpoint.height * (16 / 9) <
        this.$vuetify.breakpoint.width
      ) {
        return Math.round(this.$vuetify.breakpoint.height * 0.8);
      }
      return Math.round(this.$vuetify.breakpoint.width * 0.9 * (9 / 16));
    },
  },
});
