import { SessionUsuario } from "@back-src/auth/sessionUsuario.interface";
import { Usuario } from "@back-src/models/usuario.model";
import { SetCategoriaUsuarioDto } from "@back-src/usuario/dto/set-categoria-usuario.dto";
import axios from "axios";

const lista = async (scope?: string): Promise<Usuario[]> => {
  const res = await axios.get<Usuario[]>("usuarios", {
    params: { scope },
  });
  return res.data;
};

const setCategoriaUsuario = async (
  formData: SetCategoriaUsuarioDto
): Promise<SessionUsuario> => {
  const res = await axios.post<SessionUsuario>(
    "/usuarios/categoria_usuario",
    formData
  );
  return res.data;
};

export default {
  private: {
    lista,
    setCategoriaUsuario,
  },
};
