
import Vue from "vue";
import { Declaracao } from "@back-src/models/declaracao.model";
import { PeriodoDeclaracao } from "@back-src/models/periodo-declaracao.model";
import { Unidade } from "@back-src/models/unidade.model";
import CustomHeader from "../../components/CustomHeader.vue";
import DeclarantesMenu from "../../components/periodo-declaracao/DeclarantesMenu.vue";
import DeclaracaoUnidadeTabela from "../../components/declaracao/DeclaracaoUnidadeTabela.vue";
import DeclaracaoDocenteTabela from "../../components/declaracao/DeclaracaoDocenteTabela.vue";
import DeclaracaoPrefeituraTabela from "../../components/declaracao/DeclaracaoPrefeituraTabela.vue";
import PeriodoDeclaracaoDadosBasicos from "../../components/periodo-declaracao/PeriodoDeclaracaoDadosBasicos.vue";
import requester from "../../requester";
import { DeclarantesListSubItem } from "../../utils/constants";
import UnidadeReceitaTabItem from "@/components/unidade/UnidadeReceitaTabItem.vue";
import DocenteReceitaTabItem from "@/components/docente/DocenteReceitaTabItem.vue";
import DocenteProjetoTabelaVer from "@/components/docente/DocenteProjetoTabelaVer.vue";
import DocenteProjetoInfoDialog from "@/components/docente/DocenteProjetoInfoDialog.vue";
import UnidadeDisciplinasTabItem from "@/components/unidade/UnidadeDisciplinasTabItem.vue";
import ExportarButtonAdmin from "@/components/admin/ExportarButtonAdmin.vue";
export default Vue.extend({
  components: {
    ExportarButtonAdmin,
    CustomHeader,
    DeclaracaoDocenteTabela,
    DeclaracaoPrefeituraTabela,
    DeclaracaoUnidadeTabela,
    DeclarantesMenu,
    DocenteProjetoInfoDialog,
    DocenteProjetoTabelaVer,
    DocenteReceitaTabItem,
    PeriodoDeclaracaoDadosBasicos,
    UnidadeDisciplinasTabItem,
    UnidadeReceitaTabItem,
  },
  name: "PeriodoDeclaracaoVerDeclaracoes",
  data: () => ({
    loading: {
      periodoDeclaracao: true,
      unidades: true,
      declaracoes: true,
    },
    panelModel: 0,
    tab: null,
    tipoDeclarante: null,
    idDeclarante: null,
    nomeDeclarante: null,
    declaracaoId: null,
    periodoDeclaracao: null as PeriodoDeclaracao | null,
    unidades: [] as Unidade[] | [],
    declaracoes: [] as Declaracao[] | [],
    projetoId: null,
    dialogProjetoInfo: false,
  }),
  computed: {
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    periodoDeclaracaoId() {
      return Number(this.$route?.params.periodoDeclaracaoId) || null;
    },
  },
  async mounted() {
    this.buscaPeriodoDeclaracao();
    await this.listaUnidades();
    await this.listaDeclaracoes();
  },
  methods: {
    declaranteAlterado(subitem: DeclarantesListSubItem | null) {
      this.tipoDeclarante = subitem?.tipoDeclarante || null;
      this.idDeclarante = subitem?.idDeclarante || null;
      this.nomeDeclarante = subitem?.nome || null;
      this.declaracaoId = subitem?.declaracaoId || null;
    },
    async buscaPeriodoDeclaracao() {
      this.loading.periodoDeclaracao = true;
      try {
        this.periodoDeclaracao =
          await requester.periodoDeclaracao.private.busca(
            this.periodoDeclaracaoId
          );
        this.loading.periodoDeclaracao = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaDeclaracoes() {
      this.loading.declaracoes = true;
      try {
        this.declaracoes =
          await requester.periodoDeclaracao.private.listaDeclaracoes(
            this.periodoDeclaracaoId
          );
        this.loading.declaracoes = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaUnidades() {
      this.loading.unidades = true;
      try {
        this.unidades = await requester.unidade.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.unidades = false;
      }
    },
  },
});
