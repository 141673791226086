
import Vue from "vue";
import CustomColInfo from "../CustomColInfo.vue";
import { ProjetoPesquisa } from "@back-src/models/projeto-pesquisa.model";

export default Vue.extend({
  name: "ProjetoResumoCard",
  components: {
    CustomColInfo,
  },
  props: {
    projeto: {
      type: Object as () => ProjetoPesquisa,
      default: null,
    },
  },
});
