
import Vue from "vue";
export default Vue.extend({
  name: "CustomText",
  props: {
    content: {
      default: "",
    },
    textClass: {
      type: String,
      default: "",
    },
    number: {
      type: Boolean,
      default: false,
    },
    money: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: "",
    },
  },
});
