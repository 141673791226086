import { RouteConfig } from "vue-router";
import DocenteIndex from "@/pages/docente/DocenteIndex.vue";

const routes: RouteConfig[] = [
  {
    path: "/docente",
    name: "DocenteIndex",
    component: DocenteIndex,
    meta: {
      public: false,
    },
  },
];

export default routes;
