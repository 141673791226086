
import { ProjetoPesquisa } from "@back-src/models/projeto-pesquisa.model";
import Vue from "vue";
import CustomTabItem from "../CustomTabItem.vue";
import DocenteProjetoCadastrarWindow from "./DocenteProjetoCadastrarWindow.vue";
import DocenteProjetoImportar from "./DocenteProjetoImportar.vue";
import DocenteProjetoDeclaracaoItemWindow from "./DocenteProjetoDeclaracaoItemWindow.vue";
import DocenteProjetoTabelaVer from "./DocenteProjetoTabelaVer.vue";
import DocenteProjetosTabItemHeader from "./DocenteProjetosTabItemHeader.vue";
export default Vue.extend({
  components: {
    CustomTabItem,
    DocenteProjetosTabItemHeader,
    DocenteProjetoCadastrarWindow,
    DocenteProjetoImportar,
    DocenteProjetoTabelaVer,
    DocenteProjetoDeclaracaoItemWindow,
  },
  name: "DocenteProjetosTabItem",
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    declaracaoId: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    usuarioId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    loading: {
      projetosPesquisa: false,
    },
    step: 0,
    projetoSelecionadoId: null,
    projetosPesquisa: [] as ProjetoPesquisa[] | [],
    alterarProjeto: false,
  }),
  computed: {
    titleComputed() {
      switch (this.step) {
        case 2:
          if (!this.projetoSelecionadoId) {
            return "Cadastrar projeto";
          } else {
            return "Alterar projeto";
          }
        case 3:
          return "Declarar animais do projeto";
        case 4:
          return "Importar projeto";
        default:
          return "Declaração dos projetos";
      }
    },
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
  },
});
