import { Categoria } from "@back-src/models/categoria.model";
import { filterFormatCurrency } from "./functions";

export const PREFEITURA_UNIDADE_ID = 5;

export const coresPeriodo = {
  Futuro: "grey",
  "Em andamento": "success",
  Finalizado: "primary",
};

export enum SituacaoAnimalPatrimonioApi {
  NãoCadastrado = "NãoCadastrado",
  Ativo = "Ativo",
  Baixado = "Baixado",
  Passagem = "Passagem",
}
export interface DeclarantesListSubItem {
  key: string;
  declaracaoId: number;
  tipoDeclarante: string;
  idDeclarante: number;
  nome: string;
  status: string;
  badgeIcon: string;
  badgeColor: string;
}
export interface DeclarantesListItem {
  isHeader: boolean;
  title: string;
  icon: string;
  subitens: DeclarantesListSubItem[];
}

export interface ItemDeclaradoVerificacao {
  id: number;
  tipoDeclarante: string;
  completa: boolean;
  diasUso: number;
  qtdLote: number;
  especie: string;
  categoria: Categoria;
  numPatrimonio: string;
  numCadastro: string;
  unidadeSigla: string;
  updatedAt: any;
  usuarioNome: string;
  usuarioEmailPrincipal: string;
  usuarioTelefone: string;
}

export interface ConfigTabela {
  nome: string;
  parametros: string[];
  atributosItens: string[];
  atributoSelect: string;
  atributoLinha: string;
  atributoColuna: string;
  atributoValorColuna: string;
  formatadorValor?: any;
}

export const configTabelasSerieHistorica: ConfigTabela[] = [
  {
    // Isso vai ser a chave pra encontrar o card do metabase
    nome: "Quantidade de animais em cada categoria por unidade no tempo",
    parametros: ["periodo_inicial_id", "periodo_final_id"],
    atributosItens: ["Categoria animal", "Ano", "Unidade", "Total de animais"],
    atributoSelect: "Unidade",
    // é atributo cujos valores devem ser headers
    atributoColuna: "Ano",
    atributoLinha: "Categoria animal",
    atributoValorColuna: "Total de animais",
    formatadorValor: null,
  },
  {
    // Isso vai ser a chave pra encontrar o card do metabase
    nome: "Receita total em cada categoria animal por unidade no tempo",
    parametros: ["periodo_inicial_id", "periodo_final_id"],
    atributosItens: ["Categoria animal", "Ano", "Unidade", "Receita Total"],
    atributoSelect: "Unidade",
    // é atributo cujos valores devem ser headers
    atributoColuna: "Ano",
    atributoLinha: "Categoria animal",
    atributoValorColuna: "Receita Total",
    formatadorValor: filterFormatCurrency,
  },
  {
    // Isso vai ser a chave pra encontrar o card do metabase
    nome: "Total de bolsas dos projetos das unidades no tempo",
    parametros: ["periodo_inicial_id", "periodo_final_id"],
    atributosItens: ["Ano", "Unidade", "Tipo de Bolsa", "Valor"],
    atributoSelect: "Unidade",
    // é atributo cujos valores devem ser headers
    atributoColuna: "Ano",
    atributoLinha: "Tipo de Bolsa",
    atributoValorColuna: "Valor",
    formatadorValor: null,
  },
];
