
import Vue from "vue";
export default Vue.extend({
  name: "PeriodoDeclaracaoDadosBasicos",
  props: {
    periodoDeclaracao: {
      type: Object,
      default: null,
    },
  },
});
