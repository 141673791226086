
import Vue from "vue";
import CustomTabItem from "../CustomTabItem.vue";
import ExportarButton from "../ExportarButton.vue";
import DeclaracaoDocenteTabela from "../declaracao/DeclaracaoDocenteTabela.vue";
import PeriodoDeclaracaoSelect from "../periodo-declaracao/PeriodoDeclaracaoSelect.vue";
export default Vue.extend({
  name: "DocenteHistoricoDeclaracaoTabItem",
  components: {
    CustomTabItem,
    ExportarButton,
    DeclaracaoDocenteTabela,
    PeriodoDeclaracaoSelect,
  },
  props: {
    usuarioId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    periodoSelecionado: null,
    declaracaoSelecionada: null,
    loading: false,
  }),
});
