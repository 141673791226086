
import Vue from "vue";
import InputMoney from "../inputs/InputMoney.vue";
import FileDownloadLink from "../FileDownloadLink.vue";
import { ProjetoPesquisa } from "@back-src/models/projeto-pesquisa.model";
import { CriaProjetoPesquisaDto } from "@back-src/docente/dto/cria-projeto-pesquisa.dto";
import requester from "@/requester";
import {
  ModoFinanciamento,
  TipoFinanciamentoBolsa,
  TipoFinanciamentoProjeto,
} from "@/utils/enums";
import { fileMaxSize, isNumber, rulesRequired } from "@/utils/input-rules";
export default Vue.extend({
  components: {
    InputMoney,
    FileDownloadLink,
  },
  name: "DocenteProjetoForm",
  props: {
    declaracaoId: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    usuarioId: {
      type: Number,
      default: null,
    },
    projetoSelecionado: {
      type: Object,
      default: null,
    },
    importacaoProjeto: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      projeto: false,
      submit: false,
    },
    file: null,
    fileCeua: null,
    agenciaFomento: null,
    rules: {
      required: rulesRequired,
      number: isNumber,
      fileSize: fileMaxSize,
    },
    arquivo: null,
    form: {
      titulo: null,
      responsavel: null,
      numCeua: null,
      numProjeto: null,
      valor: null,
      justificativaNaoFinanciamento: null,
      possuiFinanciamento: false,
      possuiBolsa: false,
      modoFinanciamento: null,
      origemBolsa: null,
      iniciacaoCientifica: null,
      mestrado: null,
      doutorado: null,
      posDoutorado: null,
      origemFinanciamento: null,
      numProjetoFinanciamento: null,
      nomeEmpresa: null,
      convenioUsp: null,
    } as ProjetoPesquisa,
  }),
  computed: {
    nomeConvenioEmpresaArquivo() {
      return this.projetoSelecionado?.convenioEmpresaArquivo?.originalname;
    },
    nomeCeuaArquivo() {
      return this.projetoSelecionado?.ceuaArquivo?.originalname;
    },
    hrefConvenioEmpresaArquivo() {
      if (!this.projetoSelecionado) return null;
      return `${process.env.VUE_APP_BASE_URL}projetos_pesquisa/${this.projetoSelecionado.id}/convenio_empresa_arquivo/presigned_url`;
    },
    tiposFinanciamentoBolsa() {
      return Object.values(TipoFinanciamentoBolsa);
    },
    tiposFinanciamentoProjeto() {
      return Object.values(TipoFinanciamentoProjeto);
    },
    modoFinanciamentoPublico() {
      return ModoFinanciamento.PUBLICO;
    },
    modoFinanciamentoPrivado() {
      return ModoFinanciamento.PRIVADO;
    },
  },
  mounted() {
    if (this.projetoSelecionado) {
      this.preencheForm();
    }
  },
  methods: {
    alteraModoFinanciamento(financiamentoSelecionado) {
      if (this.form?.modoFinanciamento === financiamentoSelecionado) {
        this.form.modoFinanciamento = null;
      } else {
        this.form.modoFinanciamento = financiamentoSelecionado;
      }
      this.form.valor = null;
    },
    validaForm() {
      return this.$refs.form.validate();
    },
    preencheForm() {
      this.form.titulo = this.projetoSelecionado.titulo;
      this.form.responsavel = this.projetoSelecionado.responsavel;
      this.form.numCeua = this.projetoSelecionado.numCeua;
      this.form.numProjeto = this.projetoSelecionado.numProjeto;
      this.form.valor = this.projetoSelecionado.valor;
      this.form.justificativaNaoFinanciamento =
        this.projetoSelecionado.justificativaNaoFinanciamento;
      this.form.possuiFinanciamento =
        this.projetoSelecionado.possuiFinanciamento;
      this.form.possuiBolsa = this.projetoSelecionado.possuiBolsa;
      this.form.modoFinanciamento = this.projetoSelecionado.modoFinanciamento;
      this.form.origemBolsa = this.projetoSelecionado.origemBolsa;
      this.form.iniciacaoCientifica =
        this.projetoSelecionado.iniciacaoCientifica;
      this.form.mestrado = this.projetoSelecionado.mestrado;
      this.form.doutorado = this.projetoSelecionado.doutorado;
      this.form.posDoutorado = this.projetoSelecionado.posDoutorado;
      this.form.origemFinanciamento =
        this.projetoSelecionado.origemFinanciamento;
      this.form.numProjetoFinanciamento =
        this.projetoSelecionado.numProjetoFinanciamento;
      this.form.nomeEmpresa = this.projetoSelecionado.nomeEmpresa;
      this.form.convenioUsp = this.projetoSelecionado.convenioUsp;
      if (
        this.projetoSelecionado.modoFinanciamento ===
        this.modoFinanciamentoPublico
      ) {
        if (
          !this.tiposFinanciamentoProjeto.includes(
            this.projetoSelecionado.origemFinanciamento
          )
        ) {
          this.agenciaFomento = this.projetoSelecionado.origemFinanciamento;
          this.form.origemFinanciamento = TipoFinanciamentoProjeto.OUTRO;
        }
      }
    },
    validaTexto(text: string) {
      return text ? text.trim() : null;
    },
    montaFormData() {
      const formBolsa = this.form?.possuiBolsa
        ? {
            origemBolsa: this.form?.origemBolsa,
            iniciacaoCientifica: Number(this.form?.iniciacaoCientifica),
            mestrado: Number(this.form?.mestrado),
            doutorado: Number(this.form?.doutorado),
            posDoutorado: Number(this.form?.posDoutorado),
          }
        : {};
      const formModoFinanciamento =
        this.form?.modoFinanciamento === ModoFinanciamento.PUBLICO
          ? {
              origemFinanciamento:
                this.form.origemFinanciamento === TipoFinanciamentoProjeto.OUTRO
                  ? this.agenciaFomento
                  : this.form.origemFinanciamento,
              numProjetoFinanciamento: this.form?.numProjetoFinanciamento,
            }
          : this.form?.modoFinanciamento === ModoFinanciamento.PRIVADO
          ? {
              nomeEmpresa: this.form?.nomeEmpresa,
              convenioUsp: this.form?.convenioUsp,
            }
          : {};

      const formFinanciamento = this.form.possuiFinanciamento
        ? {
            modoFinanciamento: this.form?.modoFinanciamento,
            ...formModoFinanciamento,
            possuiBolsa: this.form?.possuiBolsa,
            ...formBolsa,
          }
        : {
            justificativaNaoFinanciamento: this.validaTexto(
              this.form.justificativaNaoFinanciamento
            ),
          };
      const form = {
        titulo: this.validaTexto(this.form.titulo),
        importacaoProjetoId: this.importacaoProjeto
          ? this.projetoSelecionado.id
          : null,
        responsavel: this.validaTexto(this.form.responsavel),
        numCeua: this.validaTexto(this.form.numCeua),
        numProjeto: this.validaTexto(this.form.numProjeto),
        valor: this.form?.possuiFinanciamento ? Number(this.form.valor) : null,
        possuiFinanciamento: this.form.possuiFinanciamento,
        possuiBolsa: this.form.possuiBolsa,
        ...formFinanciamento,
      } as CriaProjetoPesquisaDto;
      return form;
    },
    async submitForm(alterar = false) {
      try {
        if (!this.validaForm()) {
          this.$root.$emit(
            "toast-error",
            "Todos os campos devem ser preenchidos corretamente para continuar."
          );
          return;
        }
        this.loading.submit = true;
        const formData = new FormData();
        const data = this.montaFormData();
        let successMsg = "Projeto cadastrado!";
        formData.append("data", JSON.stringify(data));
        formData.append("file", this.file);
        formData.append("fileCeua", this.fileCeua);
        if (alterar) {
          // Alterar
          successMsg = "Projeto atualizado!";
          await requester.docente.private.alteraProjeto(
            formData,
            this.declaracaoId,
            this.periodoDeclaracaoId,
            this.projetoSelecionado.id,
            this.usuarioId
          );
        } else {
          // Criar
          await requester.docente.private.criaProjetoPesquisa(
            formData,
            this.declaracaoId,
            this.periodoDeclaracaoId,
            this.usuarioId
          );
        }
        this.$root.$emit("toast-success", successMsg);
        this.$emit("projeto-cadastrado");
        this.$refs?.form?.reset();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submit = false;
      }
    },
  },
});
