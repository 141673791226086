import { RouteConfig } from "vue-router";
import PrefeituraIndex from "@/pages/prefeitura/PrefeituraIndex.vue";

const routes: RouteConfig[] = [
  {
    path: "/prefeitura",
    name: "PrefeituraIndex",
    component: PrefeituraIndex,
    meta: {
      public: false,
    },
  },
];

export default routes;
