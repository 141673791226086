
import Vue from "vue";
import { isNumber } from "../../utils/input-rules";
import InputMoney from "../inputs/InputMoney.vue";
import requester from "@/requester";

export default Vue.extend({
  components: { InputMoney },
  name: "CardRecursosFinanceiros",
  props: {
    recursosFinanceirosNecessarios: {
      type: Number,
      default: 0,
    },
    periodoDeclaracao: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      recursoTotal: null,
      cotaBioterio: null,
    },
    rules: { isNumber },
    teste: null,
    isComponentMounted: false,
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2,
    },
    properties: {},
  }),
  computed: {
    cotaRebanho() {
      if (!this.form.recursoTotal || !this.form.cotaBioterio) {
        return null;
      }
      const cota = this.form.recursoTotal - this.form.cotaBioterio;
      return cota >= 0 ? cota : null;
    },
    fatorDisponibilidade() {
      let fator = 0;
      if (this.recursosFinanceirosNecessarios && this.cotaRebanho) {
        fator = this.cotaRebanho / this.recursosFinanceirosNecessarios;
      }
      this.$emit("fator-alterado", fator);
      return fator;
    },
  },
  mounted() {
    this.carregaComponent();
  },
  methods: {
    carregaComponent() {
      this.form.recursoTotal = this.periodoDeclaracao?.recursoTotal;
      this.form.cotaBioterio = this.periodoDeclaracao?.cotaBioterio;
      this.isComponentMounted = true;
      setTimeout(() => {
        const inputVerde = document.getElementById("greenInput");
        if (inputVerde) {
          inputVerde.querySelector("input").style.color = "#009a2f";
          const elemento1 = inputVerde.querySelector(".v-text-field__suffix");
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (elemento1 as any).style.color = "#009a2f";
        }
      }, 500);
    },
    validaForm() {
      return this.$refs.form.validate();
    },
    async alteraPeriodoDeclaracao() {
      if (!this.validaForm()) {
        this.$root.$emit("toast-error", "Valor inválido");
        return;
      }
      try {
        await requester.periodoDeclaracao.private.altera(
          this.periodoDeclaracao.id,
          {
            recursoTotal: Number(this.form.recursoTotal),
            cotaBioterio: Number(this.form.cotaBioterio),
          }
        );
        this.$root.$emit("toast-success", "Período de declaração atualizado!");
        this.$emit("periodo-alterado");
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
  watch: {
    periodoDeclaracao() {
      this.isComponentMounted = false;
      this.carregaComponent();
    },
  },
});
