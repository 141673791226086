
import Vue from "vue";

export default Vue.extend({
  name: "VinculosUsuariosDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    usuario: {
      type: Object,
      default: null,
    },
    vinculos: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    headers: [
      {
        text: "Vínculo",
        value: "nomeVinculo",
        sortable: false,
      },
      {
        text: "Unidade",
        value: "siglaUnidade",
        sortable: false,
      },
      {
        text: "Setor",
        value: "nomeSetor",
        sortable: false,
      },
      {
        text: "Função",
        value: "nomeAbreviadoFuncao",
        sortable: false,
      },
    ],
  }),
  methods: {
    closeDialog() {
      this.$emit("input", false);
    },
  },
});
