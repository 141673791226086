
import Vue from "vue";
import requester from "@/requester";
import { ModoFinanciamento } from "@/utils/enums";
import { ProjetoPesquisa } from "@back-src/models/projeto-pesquisa.model";
import DocenteProjetoInfoCard from "./DocenteProjetoInfoCard.vue";

export default Vue.extend({
  name: "DocenteProjetoInfoDialog",
  components: {
    DocenteProjetoInfoCard,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    projetoId: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    declaracaoId: {
      type: Number,
    },
    usuarioId: {
      type: Number,
    },
    isDocente: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      projeto: true,
    },
    projeto: null as ProjetoPesquisa | null,
  }),
  computed: {
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    nomeConvenioEmpresaArquivo() {
      return this.projeto?.convenioEmpresaArquivo?.originalname;
    },
    hrefConvenioEmpresaArquivo() {
      return `${process.env.VUE_APP_BASE_URL}projetos_pesquisa/${this.projetoId}/convenio_empresa_arquivo/presigned_url`;
    },
    modoFinanciamentoPublico() {
      return ModoFinanciamento.PUBLICO;
    },
    modoFinanciamentoPrivado() {
      return ModoFinanciamento.PRIVADO;
    },
  },
  mounted() {
    if (
      this.usuarioId &&
      this.declaracaoId &&
      this.periodoDeclaracaoId &&
      this.projetoId
    ) {
      this.buscaProjeto();
    }
  },
  methods: {
    async buscaProjeto() {
      this.loading.projeto = true;
      try {
        if (this.isDocente) {
          this.projeto = await requester.docente.private.buscaProjeto(
            this.declaracaoId,
            this.periodoDeclaracaoId,
            this.projetoId,
            this.usuarioId
          );
        } else {
          this.projeto = await requester.admin.private.buscaProjeto(
            this.declaracaoId,
            this.periodoDeclaracaoId,
            this.projetoId,
            this.usuarioId
          );
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.projeto = false;
      }
    },
    closeDialog() {
      this.$refs?.form?.reset();
      this.$emit("close");
      this.$emit("input", false);
    },
  },
});
