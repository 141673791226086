
import Vue from "vue";
import requester from "@/requester";
import { uniq } from "lodash";
import { ConfigTabela } from "@/utils/constants";

export default Vue.extend({
  name: "MetabaseTimelineTable",
  props: {
    periodoInicialId: {
      type: Number,
      default: null,
    },
    periodoFinalId: {
      type: Number,
      default: null,
    },
    unidades: {
      type: Array,
      default: () => [],
    },
    cardConfig: {
      type: Object as () => ConfigTabela,
      default: null,
    },
    cardInfo: {
      type: Object,
      default: null,
    },
    metabaseSessionKey: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    dadosCard: null,
    attrSelected: null,
    dadosMetabaseTabela: [],
  }),
  async mounted() {
    await this.carregaTabela();
    if (this.selectItens.length > 0) {
      this.attrSelected = this.selectItens[0].value;
    }
  },
  computed: {
    parametrosCard() {
      return this.dadosCard ? this.dadosCard.parameters : [];
    },
    parametrosSlugsCard() {
      return this.dadosCard.parameters.map((el) => el.slug);
    },
    temParametroPeriodoInicialId() {
      return this.parametrosSlugsCard.includes("periodo_inicial_id");
    },
    temParametroPeriodoFinalId() {
      return this.parametrosSlugsCard.includes("periodo_final_id");
    },
    selectItens() {
      const valoresSelect = uniq(
        this.dadosMetabaseTabela.map((el) => el[this.cardConfig.atributoSelect])
      );
      return valoresSelect.map((el) => ({
        text: el,
        value: el,
      }));
    },
    colunasValores() {
      // Colunas adicionais
      return uniq(
        this.dadosMetabaseTabela.map((el) => el[this.cardConfig.atributoColuna])
      );
    },
    linhas() {
      return uniq(
        this.dadosMetabaseTabela.map((el) => el[this.cardConfig.atributoLinha])
      );
    },
    headersDataTable() {
      const headers = [
        {
          text: this.cardConfig.atributoLinha,
          value: this.cardConfig.atributoLinha,
          sortable: true,
          align: "left",
        },
      ];
      this.colunasValores.forEach((novaColuna) => {
        headers.push({
          text: novaColuna,
          value: novaColuna,
          sortable: true,
          align: "right",
        });
      });
      return headers;
    },
    dadosDataTable() {
      if (!this.attrSelected) {
        return [];
      }
      const filtered = this.dadosMetabaseTabela.filter(
        (el) => el[this.cardConfig.atributoSelect] === this.attrSelected
      );
      const itens = [];
      this.linhas.forEach((linha) => {
        let novoItem = {};
        novoItem[this.cardConfig.atributoLinha] = linha;
        this.colunasValores.forEach((col) => {
          const dado = filtered.find(
            (it) =>
              it[this.cardConfig.atributoColuna] === col &&
              it[this.cardConfig.atributoLinha] === linha
          );
          let val = dado ? dado[this.cardConfig.atributoValorColuna] : 0;
          if (this.cardConfig.formatadorValor) {
            novoItem[col] = this.cardConfig.formatadorValor(val);
          } else {
            novoItem[col] = val;
          }
        });
        itens.push(novoItem);
      });
      return itens;
    },
  },
  methods: {
    async carregaTabela() {
      this.loading = true;
      await this.buscaInfoCard();
      await this.buscaDadosJsonCard();
      this.loading = false;
    },
    async buscaInfoCard() {
      // busca infos da question (do tipo card)
      try {
        this.dadosCard = await requester.metabase.private.buscaCardById(
          this.cardInfo.id,
          this.metabaseSessionKey.id
        );
      } catch (error) {
        this.$root.$errorHandler(error);
      }
    },
    async buscaDadosJsonCard() {
      try {
        const results = await requester.metabase.private.buscaDadosJsonCardById(
          this.cardInfo.id,
          this.metabaseSessionKey.id,
          this.montaParamsParaGetJson()
        );
        this.dadosMetabaseTabela = results;
      } catch (error) {
        this.$root.$errorHandler(error);
      }
    },
    montaParamsParaGetJson() {
      // A rota que busca os resultados no metabase espera que cada parametro tenha:
      // - id: string
      // - type: string
      // - value: array com os valores
      // - target: array estranho (exemplo: ['variable', ['template-tag', 'unidade_id']])
      let queryParams = [];

      if (this.temParametroPeriodoInicialId) {
        const par = this.parametrosCard.find(
          (el) => el.slug === "periodo_inicial_id"
        );
        queryParams.push({
          id: par.id,
          target: par.target,
          type: par.type,
          value: [`${this.periodoInicialId}`],
        });
      }
      if (this.temParametroPeriodoFinalId) {
        const par = this.parametrosCard.find(
          (el) => el.slug === "periodo_final_id"
        );
        queryParams.push({
          id: par.id,
          target: par.target,
          type: par.type,
          value: [`${this.periodoFinalId}`],
        });
      }
      return queryParams;
    },
  },
  watch: {
    periodoInicialId() {
      this.carregaTabela();
    },
    periodoFinalId() {
      this.carregaTabela();
    },
  },
});
