
import Vue from "vue";
import requester from "../../requester";
import { filter } from "lodash";
import { MetabaseCollectionsSerieHistorica } from "@/utils/enums";
import { Unidade } from "@back-src/models/unidade.model";
import { Categoria } from "@back-src/models/categoria.model";
import { PeriodoDeclaracao } from "@back-src/models/periodo-declaracao.model";
import { Usuario } from "@back-src/models/usuario.model";
import CustomHeader from "../../components/CustomHeader.vue";
import RelatoriosLayoutTool from "../../components/admin/RelatoriosLayoutTool.vue";
import PeriodoDeclaracaoSelect from "../../components/periodo-declaracao/PeriodoDeclaracaoSelect.vue";
import MetabaseTimelineChart from "../../components/metabase/MetabaseTimelineChart.vue";
import MetabaseTimelineTablesWrapper from "../../components/metabase/MetabaseTimelineTablesWrapper.vue";

export default Vue.extend({
  name: "RelatoriosSerieHistorica",
  components: {
    RelatoriosLayoutTool,
    CustomHeader,
    PeriodoDeclaracaoSelect,
    MetabaseTimelineChart,
    MetabaseTimelineTablesWrapper,
  },
  data: () => ({
    colSize: 6,
    periodoInicial: null,
    periodoFinal: null,
    itensNaoExcluidosDosPeriodos: [],
    unidades: [] as Unidade[],
    categorias: [] as Categoria[],
    usuarios: [] as Usuario[],
    // // metabase
    metabaseSessionKey: null,
    metabaseCollections: [],
    metabaseChartItens: [],
    metabaseTableItens: [],
  }),
  async mounted() {
    this.listaUnidades();
    this.listaCategorias();
    this.listaUsuarios();
    this.metabaseSetup();
  },
  computed: {
    // CHARTS
    metabaseCollectionChartsSerieHist() {
      const col = this.metabaseCollections.find(
        (el) => el.name === MetabaseCollectionsSerieHistorica.SERIE_HISTORICA
      );
      return col || null;
    },
    metabaseChartItensCard() {
      return filter(this.metabaseChartItens, (el) => el.model === "card");
    },
    // TABLES
    metabaseCollectionTablesSerieHist() {
      const col = this.metabaseCollections.find(
        (el) => el.name === MetabaseCollectionsSerieHistorica.TABELAS
      );
      return col || null;
    },
    metabaseTableItensCard() {
      return filter(this.metabaseTableItens, (el) => el.model === "card");
    },
  },
  methods: {
    async listaCategorias() {
      try {
        this.categorias = await requester.categoria.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaUnidades() {
      try {
        this.unidades = await requester.unidade.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaUsuarios() {
      try {
        this.usuarios = await requester.usuario.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    //metabase
    async metabaseSetup() {
      // login me da a session key
      await this.metabaseLogin();
      if (this.metabaseSessionKey) {
        // com a session key pego as collections
        await this.metabaseListaColecoes();
      }
      // com as collections listadas, tem um computed pra pegar a que eu quero: metabaseCollectionChartsSerieHist
      // dentro da collection tem os "itens". Cada um é um chart/tabela
      this.metabaseListaItensColecao(
        this.metabaseCollectionChartsSerieHist,
        MetabaseCollectionsSerieHistorica.SERIE_HISTORICA
      );
      this.metabaseListaItensColecao(
        this.metabaseCollectionTablesSerieHist,
        MetabaseCollectionsSerieHistorica.TABELAS
      );
    },
    async metabaseLogin() {
      const data = {
        username: process.env.VUE_APP_METABASE_LOGIN,
        password: process.env.VUE_APP_METABASE_PASS,
      };
      try {
        this.metabaseSessionKey =
          await requester.metabase.private.metabaseLogin(data);
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async metabaseListaColecoes() {
      try {
        this.metabaseCollections =
          await requester.metabase.private.listaColecoes(
            this.metabaseSessionKey.id
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async metabaseListaItensColecao(mbCollection, nomeCollection) {
      if (!mbCollection) {
        this.$root.$emit(
          "toast-warning",
          "Coleção de relatórios não encontrada"
        );
        return;
      }
      try {
        const res = await requester.metabase.private.listaItensColecao(
          mbCollection.id,
          this.metabaseSessionKey.id
        );
        if (
          nomeCollection === MetabaseCollectionsSerieHistorica.SERIE_HISTORICA
        ) {
          this.metabaseChartItens = res.data;
        } else if (
          nomeCollection === MetabaseCollectionsSerieHistorica.TABELAS
        ) {
          this.metabaseTableItens = res.data;
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
});
