import axios from "axios";
import { Disciplina } from "@back-src/models/disciplina.model";

const busca = async (disciplinaId: number): Promise<Disciplina> => {
  const res = await axios.get<Disciplina>(`/disciplinas/${disciplinaId}`);
  return res.data;
};

const lista = async (): Promise<Disciplina[]> => {
  const res = await axios.get<Disciplina[]>("disciplinas");
  return res.data;
};

export default {
  private: {
    busca,
    lista,
  },
};
