var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loadingPageComputed)?_c('div',[_c('CustomHeader',{attrs:{"title":`${_vm.unidade.sigla} - ${_vm.unidade.nome}`,"titleClass":"text-h5 font-weight-light"}}),_c('div',{staticClass:"d-flex justify-end"},[(_vm.periodoDeclaracaoAtivo)?_c('span',{staticClass:"green--text font-weight-light text-right"},[_vm._v(" Estamos em período de declaração de "+_vm._s(_vm._f("formatDate")(_vm.periodoDeclaracao.dataInicio))+" à "+_vm._s(_vm._f("formatDate")(_vm.periodoDeclaracao.dataFim)))]):_c('span',{staticClass:"red--text font-weight-light text-right"},[_vm._v(" Não estamos em período de declaração ")])]),_c('v-card',{staticClass:"pr-3",attrs:{"outlined":""}},[_c('v-tabs',{attrs:{"vertical":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"d-flex justify-start"},[_c('v-icon',{attrs:{"left":"","mdall":""}},[_vm._v(" mdi-home ")]),_vm._v(" Início ")],1),(
          !!_vm.declaracaoUnidade &&
          _vm.periodoDeclaracaoAtivo &&
          !_vm.declaracaoUnidade.completa
        )?[_c('v-tab',{staticClass:"d-flex justify-start"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-cow")]),_vm._v(" Animais ")],1),_c('v-tab',{staticClass:"d-flex justify-start"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-cash")]),_vm._v(" Receita ")],1),_c('v-tab',{staticClass:"d-flex justify-start",attrs:{"vif":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-check")]),_vm._v(" Finalizar ")],1)]:_vm._e(),_c('v-tab',{staticClass:"d-flex justify-start"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-history")]),_vm._v(" Histórico ")],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('InicioTabItem',{attrs:{"periodoDeclaracaoAtivo":_vm.periodoDeclaracaoAtivo,"declaracaoIniciada":!!_vm.declaracaoUnidade,"declaracaoEnviada":_vm.declaracaoUnidade?.completa,"declaracaoId":_vm.declaracaoUnidade?.id,"tipoDeclarante":_vm.TiposDeclarantes.PREFEITURA},on:{"iniciar-declaracao":function($event){return _vm.declaracao()},"envia-declaracao":function($event){return _vm.alteraFlagConclusao($event)}}})],1),(
            !!_vm.declaracaoUnidade &&
            _vm.periodoDeclaracaoAtivo &&
            !_vm.declaracaoUnidade.completa
          )?[_c('v-tab-item',[_c('PrefeituraAnimaisTabItem',{attrs:{"declaracaoId":_vm.declaracaoUnidade?.id,"unidadeId":_vm.unidadeId,"declaracaoFinalizada":_vm.declaracaoUnidade?.completa,"periodoDeclaracaoId":_vm.periodoDeclaracaoAtivo ? _vm.periodoDeclaracao.id : null}})],1),_c('v-tab-item',[(_vm.tab === 2)?_c('UnidadeReceitaTabItem',{attrs:{"declaracaoAtualId":_vm.declaracaoUnidade.id,"unidadeId":_vm.unidadeId}}):_vm._e()],1),_c('v-tab-item',[(_vm.tab === 3)?_c('PrefeituraFinalizarDeclaracaoTabItem',{attrs:{"periodoAtualId":_vm.periodoDeclaracao.id,"unidadeId":_vm.unidadeId,"declaracaoFinalizada":_vm.declaracaoUnidade?.completa,"declaracaoId":_vm.declaracaoUnidade?.id},on:{"declaracao-enviada":function($event){(_vm.tab = 0), _vm.buscaDados()},"receita-animal":function($event){_vm.tab = 2}}}):_vm._e()],1)]:_vm._e(),_c('v-tab-item',[_c('div',[_c('PrefeituraHistoricoDeclaracaoTabItem',{attrs:{"alterar":false,"unidadeId":_vm.unidadeId,"declaracaoId":_vm.declaracaoUnidade?.id,"periodoAtualId":_vm.periodoDeclaracaoAtivo ? _vm.periodoDeclaracao.id : null}})],1)])],2)],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }