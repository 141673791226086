import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import AcessoInterno from "./admin.routes";
import Docente from "./docente.routes";
import LandingPage from "../pages/LandingPage.vue";
import Prefeitura from "./prefeitura.routes";
import Unidade from "./unidade.routes";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
    meta: {
      public: true,
    },
  },
  ...AcessoInterno,
  ...Docente,
  ...Prefeitura,
  ...Unidade,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
