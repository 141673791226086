
import Vue from "vue";
import { PeriodoDeclaracao } from "@back-src/models/periodo-declaracao.model";
import { coresPeriodo } from "../../utils/constants";
import { defineStatusPeriodoDeclaracao } from "../../utils/functions";
export default Vue.extend({
  name: "PeriodoDeclaracaoChip",
  props: {
    periodo: {
      type: Object as () => PeriodoDeclaracao,
      default: null as () => PeriodoDeclaracao,
    },
    small: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      coresPeriodo,
      defineStatusPeriodoDeclaracao,
    };
  },
});
