
import Vue from "vue";
import requester from "@/requester";

export default Vue.extend({
  name: "DeclaracaoItemAlertValidacao",
  props: {
    declaracaoItens: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      realizados: 0,
    };
  },
  computed: {
    itensSemValidacao() {
      return this.declaracaoItens.filter(
        (el) => el.numPatrimonio && !el.apiPatrimonioConsultadaAt
      );
    },
  },
  methods: {
    async validaDadosApi() {
      this.loading = true;
      this.realizados = 0;
      for await (const item of this.itensSemValidacao) {
        if (item.numPatrimonio) {
          this.realizados++;
          try {
            const resData =
              await requester.declaracaoItem.private.validaItemApiPatrimonio(
                item.id
              );
            if (!isNaN(resData as any) && resData === 401) {
              this.loading = false;
              this.$root.$emit(
                "toast-error",
                "Sessão expirada. Favor refazer seu login"
              );
              return;
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
      this.$root.$emit("toast-success", "Itens validados");
      this.loading = false;
      this.$emit("validacao-realizada");
    },
  },
});
