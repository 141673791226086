
import Vue from "vue";
import { configTabelasSerieHistorica } from "@/utils/constants";
import MetabaseTimelineTable from "./MetabaseTimelineTable.vue";

export default Vue.extend({
  name: "MetabaseTimelineTablesWrapper",
  components: {
    MetabaseTimelineTable,
  },
  props: {
    periodoInicialId: {
      type: Number,
      default: null,
    },
    periodoFinalId: {
      type: Number,
      default: null,
    },
    unidades: {
      type: Array,
      default: () => [],
    },
    metabaseTableItensCard: {
      type: Array,
      default: () => [],
    },
    metabaseSessionKey: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    configTabelasSerieHistorica,
  }),
  methods: {
    getCardInfo(nome) {
      return this.metabaseTableItensCard.find((el) => el.name === nome);
    },
  },
});
