import { sortBy } from 'lodash';
import { SessionUsuario } from '../auth/sessionUsuario.interface';
import { Usuario } from '../models/usuario.model';
import {
  CategoriaUsuarioEnum,
  DadosExportados,
  DadosExportadosItens,
  PREFEITURA_UNIDADE_ID,
  TipoExportacao,
} from './constants';
import { Declaracao } from '../models/declaracao.model';
import { DeclaracaoItem } from '../models/declaracao-item.model';
import { DeclaracaoReceita } from '../models/declaracao-receita.model';
import { PeriodoDeclaracao } from '../models/periodo-declaracao.model';

export const getDataHojeBdFormat = () => {
  const dataHoje = new Date();
  let month = '' + (dataHoje.getMonth() + 1);
  let day = '' + dataHoje.getDate();
  const year = dataHoje.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  const hojeBd = [year, month, day].join('-');
  return hojeBd;
};

export const usuarioParaUsuarioSession = (usuario: Usuario) => {
  const usuarioSession: SessionUsuario = {
    id: usuario.id,
    ativo: usuario.ativo,
    login: usuario.login,
    nome: usuario.nome,
    nomeDisplay:
      usuario.unidadeId &&
      usuario.categoriaUsuario === CategoriaUsuarioEnum.UNIDADE
        ? `${usuario.unidade.sigla} - ${usuario.unidade.nome}`
        : usuario.nome,
    categoriaUsuario: usuario.categoriaUsuario,
    unidadeId: usuario.unidadeId,
    wsuserid: usuario.wsuserid,
  };
  return usuarioSession;
};

export function parseDadosExportar(
  declaracao: Declaracao,
  tipoExportacao: TipoExportacao,
): DadosExportados {
  const dadosItens = [];
  dadosItens.push(
    ...declaracao.declaracaoItems.map((item: DeclaracaoItem) => {
      let novoItem: DadosExportadosItens = {
        numPatrimonio: item.numPatrimonio || '',
        numCadastro: item.numCadastro || '',
        especie: item.categoria?.especie?.nome,
        categoria: item.categoria?.nome,
        lote: item.lote ? 'Sim' : 'Não',
        quantidadeDeAnimais: item.qtdLote,
        proprietario: item.proprietario || '',
        diasDeUso: item.diasUso,
        destinoPosUso: item.destinoPosUso || '',
        destinatarioPosUso: item.destinatarioPosUso || '',
        observacoes: item.obs || '',
        origemParticular: item.origemParticular ? 'Sim' : 'Não',
      };
      if (tipoExportacao === TipoExportacao.UNIDADE) {
        // feito assim pra disciplina ser 1 chave
        novoItem = {
          disciplina: `${item.disciplina.sigla} - ${item.disciplina.nome}`,
          ...novoItem,
        };
      } else if (tipoExportacao === TipoExportacao.DOCENTE) {
        // feito assim pra projeto ser 1 chave
        novoItem = {
          projeto: item.projetoPesquisa.titulo,
          ...novoItem,
        };
      }
      return novoItem;
    }),
  );
  const dadosReceita = [];
  dadosReceita.push(
    ...declaracao.declaracaoReceitas.map((item: DeclaracaoReceita) => ({
      especie: item.categoria?.especie?.nome,
      categoria: item.categoria?.nome,
      receitaSubprodutos: item.receitaSubprodutos || 0,
      receitaProle: item.receitaProle || 0,
      receitaTransferencia: item.receitaTransferencia || 0,
      observacoes: item.obs || '',
    })),
  );
  return {
    dadosItens: sortBy(dadosItens, [
      'projeto',
      'disciplina',
      'numPatrimonio',
      'numCadastro',
    ]),
    dadosReceita: sortBy(dadosReceita, ['especie', 'categoria']),
  };
}

export function parseDadosExportarAdmin(
  periodo: PeriodoDeclaracao
): DadosExportados {
  const dadosItens = [];
  const dadosReceita = [];

  periodo.declaracoes.forEach((declaracao: Declaracao) => {
    let declarante;
    if (declaracao.tipoDeclarante === CategoriaUsuarioEnum.UNIDADE) {
      declarante = declaracao.unidade.nome;
    } else if (declaracao.tipoDeclarante === CategoriaUsuarioEnum.DOCENTE) {
      declarante = declaracao.usuarioCreatedBy.nome;
    }
    dadosItens.push(
      ...declaracao.declaracaoItems.map((item: DeclaracaoItem) => {
        const novoItem: DadosExportadosItens = {
          declarante,
          disciplina: null,
          projeto: null,
          utilizar: item.utilizar ? 'Sim' : 'Não',
          numPatrimonio: item.numPatrimonio || '',
          numCadastro: item.numCadastro || '',
          especie: item.categoria?.especie?.nome,
          categoria: item.categoria?.nome,
          lote: item.lote ? 'Sim' : 'Não',
          quantidadeDeAnimais: item.qtdLote,
          proprietario: item.proprietario || '',
          diasDeUso: item.diasUso,
          destinoPosUso: item.destinoPosUso || '',
          destinatarioPosUso: item.destinatarioPosUso || '',
          observacoes: item.obs || '',
          origemParticular: item.origemParticular ? 'Sim' : 'Não',
        };
        if (declaracao.tipoDeclarante === CategoriaUsuarioEnum.UNIDADE) {
          // ITENS DE DISCIPLINA
          if (declaracao.unidadeId !== PREFEITURA_UNIDADE_ID) {
            novoItem.disciplina = `${item.disciplina.sigla} - ${item.disciplina.nome}`;
          }
        } else if (declaracao.tipoDeclarante === CategoriaUsuarioEnum.DOCENTE) {
          // ITENS DE PROJETO
          novoItem.projeto = item.projetoPesquisa.titulo;
        }
        return novoItem;
      }),
    );
    dadosReceita.push(
      ...declaracao.declaracaoReceitas.map((item: DeclaracaoReceita) => ({
        declarante,
        especie: item.categoria?.especie?.nome,
        categoria: item.categoria?.nome,
        receitaSubprodutos: item.receitaSubprodutos || 0,
        receitaProle: item.receitaProle || 0,
        receitaTransferencia: item.receitaTransferencia || 0,
        observacoes: item.obs || '',
      })),
    );
  });

  return {
    dadosItens: sortBy(dadosItens, [
      'declarante',
      'disciplina',
      'projeto',
      'utilizar',
      'numPatrimonio',
      'numCadastro',
    ]),
    dadosReceita: sortBy(dadosReceita, ['declarante', 'especie', 'categoria']),
  };
}
