
import Vue from "vue";
import requester from "../../requester";
import InputMoney from "../inputs/InputMoney.vue";
import { notNull, rulesRequired } from "../../utils/input-rules";
import { AlteraItemReceitaDto } from "@back-src/declaracao/dto/altera-item-receita.dto";

export default Vue.extend({
  name: "AlterarItemReceitaDialog",
  components: {
    InputMoney,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    geraReceita: {
      type: Boolean,
      default: false,
    },
    unidadeId: {
      type: Number,
    },
    docenteId: {
      type: Number,
    },
    declaracaoId: {
      type: Number,
    },
  },
  data: () => ({
    itemReceita: {
      id: null,
      especie: null,
      categoria: null,
      geraReceita: null,
      receitaProle: null,
      receitaSubprodutos: null,
      receitaTransferencia: null,
      obs: null,
    },
    rules: {
      required: rulesRequired,
      notNull: notNull,
    },
  }),

  methods: {
    limpaValoresReceita() {
      this.itemReceita.receitaProle = null;
      this.itemReceita.receitaSubprodutos = null;
      this.itemReceita.receitaTransferencia = null;
    },
    closeDialog() {
      this.$refs?.form?.reset();
      this.$emit("voltar");
      this.$emit("input", false);
    },
    validaForm() {
      return this.$refs.form.validate();
    },
    async atualizarItemReita() {
      if (!this.validaForm()) return;

      const itemReceitaId = this.itemReceita.id;
      const formData = {
        geraReceita: this.itemReceita?.geraReceita,
        receitaTransferencia: this.itemReceita?.receitaTransferencia
          ? Number(this.itemReceita?.receitaTransferencia)
          : null,
        receitaSubprodutos: this.itemReceita?.receitaSubprodutos
          ? Number(this.itemReceita?.receitaSubprodutos)
          : null,
        receitaProle: this.itemReceita?.receitaProle
          ? Number(this.itemReceita?.receitaProle)
          : null,
        obs: this.itemReceita?.obs,
      } as AlteraItemReceitaDto;
      try {
        if (this.unidadeId) {
          await requester.unidade.private.alteraItemReceita(
            formData,
            itemReceitaId,
            this.unidadeId
          );
        } else if (this.docenteId) {
          await requester.docente.private.alteraItemReceitaDocente(
            formData,
            itemReceitaId,
            this.declaracaoId,
            this.docenteId
          );
        }
        this.$emit("item-alterado", {
          id: itemReceitaId,
          ...this.itemReceita,
        });
        this.$root.$emit("toast-success", "Valores alterados");
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.closeDialog();
      }
    },
    preencheItemReceita(itemReceita) {
      this.itemReceita.id = itemReceita?.id;
      this.itemReceita.especie = itemReceita?.especie;
      this.itemReceita.categoria = itemReceita?.categoria;
      this.itemReceita.geraReceita = itemReceita?.geraReceita;
      this.itemReceita.receitaProle = itemReceita?.receitaProle;
      this.itemReceita.receitaSubprodutos = itemReceita?.receitaSubprodutos;
      this.itemReceita.receitaTransferencia = itemReceita?.receitaTransferencia;
      this.itemReceita.obs = itemReceita?.obs;
    },
  },
});
