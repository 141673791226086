
import Vue from "vue";
import AlertInfo from "../AlertInfo.vue";

export default Vue.extend({
  name: "AnimaisTutorialCard",
  components: {
    AlertInfo,
  },
});
