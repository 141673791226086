
import Vue from "vue";
import requester from "@/requester";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import DeclaracaoItemDialogInfo from "../declaracao/DeclaracaoItemDialogInfo.vue";
import { AlteraVetoDeclaracaoItemDto } from "@back-src/admin/dto/altera-veto-declaracao-item.dto";

export default Vue.extend({
  name: "TabelaConsultaItensDeclaracao",
  components: {
    DeclaracaoItemDialogInfo,
  },
  props: {
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    loading: {
      busca: false,
    },
    dialogVerDeclaracaoItem: false,
    search: null,
    declaracaoItemSelecionado: null,
    itensDeclaracao: [],
  }),
  computed: {
    headersComputed() {
      const headers = [
        {
          text: "Num. patrimônio",
          value: "numPatrimonio",
        },
        {
          text: "Num. cadastro",
          value: "numCadastro",
        },
        {
          text: "Projeto",
          value: "projeto",
        },
        {
          text: "Disciplina",
          value: "disciplina",
        },
        {
          text: "Espécie",
          value: "categoria.especie.nome",
        },
        {
          text: "Categoria",
          value: "categoria.nome",
        },
        { text: "Qtde", value: "qtdLote" },
        { text: "Dias de uso", value: "diasUso" },
        {
          text: this.alterar ? "Alterar" : "Visualizar",
          value: "actions",
          sortable: false,
          align: "center",
        },
        {
          text: "Habilitado",
          value: "utilizar",
          sortable: false,
          align: "center",
        },
      ];
      return headers;
    },
  },
  methods: {
    async buscaItens() {
      if (this.search.length < 3) {
        this.$root.$emit(
          "toast-error",
          "Favor preencher ao menos 3 caracteres no campo de busca"
        );
        return;
      }
      this.loading.busca = true;
      try {
        this.itensDeclaracao = await requester.admin.private.buscaItensPorNum(
          this.periodoDeclaracaoId,
          this.search
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.busca = false;
      }
    },
    async submitAlteraVetoForm(item: DeclaracaoItem) {
      try {
        const formData = {
          utilizar: item.utilizar,
        } as AlteraVetoDeclaracaoItemDto;
        await requester.admin.private.alteraVetoDeclaracaoItem(
          formData,
          this.periodoDeclaracaoId,
          item?.declaracaoId,
          item?.id
        );
        await this.buscaItens();
        this.$root.$emit("toast-success", "Item atualizado");
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    openDialogInfo(declaracaoItem: DeclaracaoItem) {
      this.declaracaoItemSelecionado = declaracaoItem;
      this.dialogVerDeclaracaoItem = true;
    },
  },
});
