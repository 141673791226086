
import Vue from "vue";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import CustomColInfo from "../CustomColInfo.vue";
import { Categoria } from "@back-src/models/categoria.model";
import { Especie } from "@back-src/models/especie.model";
import requester from "../../requester";

export default Vue.extend({
  name: "DeclaracaoItemDataIterator",
  components: {
    CustomColInfo,
  },
  props: {
    declaracaoItens: {
      type: Array as () => DeclaracaoItem[],
      default: () => [],
    },
    showDeclarar: {
      type: Boolean,
      default: false,
    },
    docenteId: {
      type: Number,
      default: null,
    },
    projetoId: {
      type: Number,
      default: null,
    },
    disciplinaId: {
      type: Number,
      default: null,
    },
    unidadeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      busca: "",
      loading: false,
      categorias: [] as Categoria[],
      especies: [] as Especie[],
      itemsPerPageArray: [5, 10, 20, 30],
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 5,
      sortBy: "id",
      keys: [
        {
          text: "Núm. patrimônio",
          value: "numPatrimonio",
        },
        {
          text: "Núm. cadastro",
          value: "numCadastro",
        },
        {
          text: "Categoria",
          value: "categoria",
        },
        {
          text: "Espécie",
          value: "especie",
        },
      ],
    };
  },
  async mounted() {
    this.loading = true;
    await this.listaCategorias();
    await this.listaEspecies();
    this.loading = false;
  },
  computed: {
    sizeCols() {
      return 3;
    },
    numberOfPages() {
      return Math.ceil(this.declaracaoItens.length / this.itemsPerPage);
    },
    itensDisplay() {
      if (this.declaracaoItens.length === 0 || this.loading) {
        return [];
      }
      return this.declaracaoItens.map((item) => {
        return {
          ...item,
          categoria: this.getCategoriaFromCategoriaId(item.categoriaId),
          especie: this.getEspecieFromCategoriaId(item.categoriaId),
        };
      });
    },
  },
  methods: {
    async confirmaRemocao() {
      const confirm = await this.$root.$confirm(
        "Remover item",
        "Esta ação irá remover este item da declaração. Deseja prosseguir?",
        {
          textConfirm: "Sim, remover",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      this.removeDeclaracaoItem();
    },
    getCategoriaFromCategoriaId(categoriaId: number) {
      const categoria = this.categorias.find((cat) => cat.id === categoriaId);
      return categoria?.nome;
    },
    getEspecieFromCategoriaId(categoriaId: number) {
      const categoria = this.categorias.find((cat) => cat.id === categoriaId);
      if (categoria) {
        const especie = this.especies.find(
          (esp) => esp.id === categoria.especieId
        );
        return especie?.nome;
      }
      return null;
    },
    async removeDeclaracaoItem(declaracaoItemId) {
      const confirm = await this.$root.$confirm(
        "Remover item",
        "Esta ação irá remover este item da declaração. Deseja prosseguir?",
        {
          textConfirm: "Sim, remover",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      try {
        if (this.docenteId) {
          await requester.docente.private.removeDeclaracaoItem(
            declaracaoItemId,
            this.projetoId,
            this.docenteId
          );
        } else {
          await requester.unidade.private.removeDeclaracaoItem(
            declaracaoItemId,
            this.disciplinaId,
            this.unidadeId
          );
        }
        this.$emit("animal-removido");
        this.$root.$emit("toast-success", "Itens removido");
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaCategorias() {
      try {
        this.categorias = await requester.categoria.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaEspecies() {
      try {
        this.especies = await requester.especie.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
});
