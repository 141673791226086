
import Vue from "vue";
import AlertInfo from "../AlertInfo.vue";
import PeriodoDeclaracaoSelect from "../periodo-declaracao/PeriodoDeclaracaoSelect.vue";
import DisciplinaForm from "./DisciplinaForm.vue";
import DisciplinaResumoCard from "./DisciplinaResumoCard.vue";
import requester from "@/requester";
export default Vue.extend({
  name: "UnidadeDisciplinaImportar",
  components: {
    DisciplinaForm,
    PeriodoDeclaracaoSelect,
    DisciplinaResumoCard,
    AlertInfo,
  },
  props: {
    declaracaoId: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    unidadeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: {
        disciplinas: false,
      },
      periodoSelecionado: null,
      disciplinasAnteriores: [],
      disciplinaSelecionada: null,
      projetoVerDetalhes: null,
      msgAviso:
        "Selecione abaixo qual disciplina deseja importar para a declaração atual. Os dados poderão ser alterados antes da nova disciplina ser salva",
    };
  },
  mounted() {
    if (this.periodoSelecionado) {
      this.buscaDisciplinasAnteriores(this.periodoSelecionado.id);
    }
  },
  methods: {
    async buscaDisciplinasAnteriores(periodoDeclaracaoId: number) {
      try {
        this.loading.disciplinas = true;
        this.disciplinasAnteriores =
          await requester.unidade.private.listaDisciplinasByPeriodoId(
            periodoDeclaracaoId,
            this.unidadeId
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.disciplinas = false;
      }
    },
  },
  watch: {
    periodoSelecionado() {
      this.disciplinasAnteriores = [];
      if (this.periodoSelecionado) {
        this.buscaDisciplinasAnteriores(this.periodoSelecionado.id);
      }
    },
  },
});
