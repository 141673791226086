
import Vue from "vue";
import requester from "../../requester";
import CustomHeader from "../../components/CustomHeader.vue";
import PeriodoDeclaracaoForm from "../../components/periodo-declaracao/PeriodoDeclaracaoForm.vue";

export default Vue.extend({
  components: {
    CustomHeader,
    PeriodoDeclaracaoForm,
  },
  name: "PeriodoDeclaracaoVerAlterar",
  data: () => ({
    loading: {
      periodo: true,
      categorias: true,
    },
    modoEdicao: false,
    periodoDeclaracao: null,
    categorias: [],
  }),
  async mounted() {
    await this.listaCategorias();
    await this.buscaPeriodo();
  },
  computed: {
    titulo() {
      return this.modoEdicao
        ? "Alterar período de declaração"
        : "Informações do período de declaração";
    },
    loadingPageComputed(): boolean {
      return Object.values(this.loading).reduce(
        (prox, acc) => prox || acc
      ) as boolean;
    },
    periodoDeclaracaoId(): number {
      return this.$route?.params?.periodoDeclaracaoId || null;
    },
  },
  methods: {
    async listaCategorias() {
      this.loading.categorias = true;
      try {
        this.categorias = await requester.categoria.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.categorias = false;
      }
    },
    async buscaPeriodo() {
      this.loading.periodo = true;
      try {
        this.periodoDeclaracao =
          await requester.periodoDeclaracao.private.busca(
            this.periodoDeclaracaoId
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.periodo = false;
      }
    },
    periodoAtualizadoHandler() {
      this.periodoDeclaracao = null;
      this.buscaPeriodo();
      this.modoEdicao = false;
    },
  },
});
