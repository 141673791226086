
import Vue from "vue";

export default Vue.extend({
  name: "TheConfirmDialog",
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: "",
    text: [],
    iconTitle: "",
    iconConfirm: "mdi-check",
    iconCancel: "mdi-close",
    textConfirm: "",
    textCancel: "",
    maxWidth: 400,
  }),
  methods: {
    open(
      title: string,
      text: string | string[] = [],
      options?: {
        iconTitle?: string;
        iconConfirm?: string;
        textConfirm?: string;
        iconCancel?: string;
        textCancel?: string;
        maxWidth?: number;
      }
    ) {
      this.dialog = true;
      this.title = title;
      this.text = Array.isArray(text) ? text : [text];
      this.iconTitle = options?.iconTitle || "";
      this.iconConfirm = options?.iconConfirm || "mdi-check";
      this.textConfirm = options?.textConfirm || "Sim, confirmar";
      this.iconCancel = options?.iconCancel || "mdi-close";
      this.textCancel = options?.textCancel || "Não, cancelar";
      this.maxWidth = options?.maxWidth || 400;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
});
