
import Vue from "vue";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default Vue.extend({
  name: "ChartRecursosFinanceiros",
  props: {
    recursosNecessarios: {
      type: Number,
      default: 0,
    },
    periodoDeclaracao: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pieChart: null,
      colors: [
        "#b30000",
        "#7c1158",
        "#4421af",
        "#1a53ff",
        "#0d88e6",
        "#00b7c7",
        "#5ad45a",
        "#8be04e",
        "#ebdc78",
      ],
      colors2: ["#1fd493", "#139c7d", "#086668"],
      colors3: ["#003f5c", "#58508d", "#bc5090", "#ff6361", "#ffa600"],
      colors4: ["#007DA1", "rgb(66, 66, 66)"],
      labels: ["Disponível", "Faltante"],
    };
  },
  computed: {
    cotaRebanho() {
      if (
        !this.periodoDeclaracao.recursoTotal ||
        !this.periodoDeclaracao.cotaBioterio
      ) {
        return null;
      }
      const cota =
        this.periodoDeclaracao.recursoTotal -
        this.periodoDeclaracao.cotaBioterio;
      return cota >= 0 ? cota : null;
    },
    cotaRebanhoPercent() {
      if (this.recursosNecessarios && this.cotaRebanho) {
        return (this.cotaRebanho * 100) / this.recursosNecessarios;
      }
      return 0;
    },
    faltantePercent() {
      if (this.recursosNecessarios && this.cotaRebanho) {
        if (this.recursosNecessarios < this.cotaRebanho) return 0;
        return (
          ((this.recursosNecessarios - this.cotaRebanho) * 100) /
          this.recursosNecessarios
        );
      }
      return 0;
    },
    pieChartCanvas() {
      return this.$refs.pieChartCanvas as HTMLCanvasElement;
    },
    datasets() {
      return [
        {
          label: "",
          backgroundColor: this.colors4,
          data: [
            this.cotaRebanho,
            this.recursosNecessarios - this.cotaRebanho < 0
              ? 0
              : this.recursosNecessarios - this.cotaRebanho,
          ],
        },
      ];
    },
  },
  mounted() {
    Chart.register(...registerables);
    this.barChart = new Chart(this.pieChartCanvas, {
      type: "pie",
      plugins: [ChartDataLabels],
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "Recursos financeiros",
            font: {
              size: 16,
            },
          },
          datalabels: {
            formatter: (value) => {
              if (value === 0) {
                return "";
              }
              return (
                ((value * 100) / this.recursosNecessarios).toFixed(2) + "%"
              );
            },
            color: "#fff",
            labels: {
              title: {
                align: "center",
                font: {
                  weight: "bold",
                  size: 20,
                },
              },
            },
          },
          tooltip: {
            bodyFont: {
              size: 16,
            },
            callbacks: {
              label: (item) => {
                return new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(Number(item.raw));
              },
            },
          },
        },
      },
    });
    this.barChart.render();
  },
});
