function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const rulesRequired = [
  (v: unknown): boolean | string => !!v || "Campo obrigatório",
];

export const isNumber = [
  (v: unknown): boolean | string =>
    !isNaN(Number(v)) || "Campo deve ser um número",
];

export const notNull = [
  (v: unknown): boolean | string =>
    v != null || "Uma opção deve ser selecionada",
];

// export const isNumberMax = [
//   (v: unknown): boolean | string => {
//     if (!max) max = 365;
//     if (v === null) return true;
//     else {
//       return (
//         (!isNaN(Number(v)) && v <= max) || "Campo deve ser um número até " + max
//       );
//     }
//   },
// ];

export function isNumberMax(
  v: number,
  max: number
): Array<(v: number) => boolean | string> {
  if (!max && max !== 0) max = 365;
  if (v === null) return;
  else {
    return [
      (v: number): boolean | string =>
        (!isNaN(Number(v)) && v <= max) ||
        "Campo deve ser um número até " + max,
    ];
  }
}

export function isEspecieValida(
  especieSelecionada: number,
  especieJaDeclarada: number,
  nome: string,
  isPatrimonio: boolean
): boolean | string {
  const msg =
    isPatrimonio === true
      ? `Uma espécie diferente foi utilizada para este número de patrimônio. (${nome})`
      : `Uma espécie diferente foi utilizada para este número de cadastro. (${nome})`;
  if (!especieJaDeclarada) return true;
  if (especieJaDeclarada === especieSelecionada) return true;
  return msg;
}

export function isCategoriaValida(
  categoriaSelecionada: number,
  categoriaJaDeclarada: number,
  nome: string,
  isPatrimonio: boolean
): boolean | string {
  const msg =
    isPatrimonio === true
      ? `Uma categoria diferente foi utilizada para este número de patrimônio. (${nome})`
      : `Uma categoria diferente foi utilizada para este número de cadastro. (${nome})`;
  if (!categoriaJaDeclarada) return true;
  if (categoriaJaDeclarada === categoriaSelecionada) return true;
  return msg;
}

export function isQtdLoteValida(
  qdtLoteCadastrada: number,
  qtdLoteInformada: number,
  isPatrimonio: boolean
): boolean | string {
  const animal = qtdLoteInformada === 1 ? "animal" : "animais";
  if (!qdtLoteCadastrada) return true;
  if (qdtLoteCadastrada === qtdLoteInformada) return true;
  const msg =
    isPatrimonio === true
      ? `O número de patrimônio informado possui uma declaração com ${qdtLoteCadastrada} ${animal}`
      : `O número de cadastro informado possui uma declaração com ${qdtLoteCadastrada} ${animal}`;
  return msg;
}

export const rulesIsEmail = [
  (v: string): boolean | string => validateEmail(v) || "E-mail inválido",
];

export const rulesIsEmailOptional = [
  (v: string): boolean | string => !v || validateEmail(v) || "E-mail inválido",
];

export function rulesPatrimonioValid(patrimonio: string): boolean | string {
  if (!patrimonio || patrimonio.length === 11) return true;
  return "O número informado está incompleto";
}

export function rulesMinLenght(
  n: number,
  message: string
): Array<(v: string) => boolean | string> {
  return [
    (v: string): boolean | string =>
      v?.length >= n || message || `Mínimo de ${n} caracteres`,
  ];
}

export function fileMaxSize(file): Array<boolean | string> {
  if (!file) return ["Campo obrigatório"];
  else if (file.size > 10_485_760) {
    return ["O arquivo deve ter no máximo 10MB!"];
  } else {
    return [true];
  }
}
