export enum CategoriaUsuarioEnum {
  ADMIN = 'Admin',
  DOCENTE = 'Docente',
  UNIDADE = 'Unidade',
  INDEFINIDO = 'Indefinido',
}

export enum TipoExportacao {
  DOCENTE = 'DOCENTE',
  UNIDADE = 'UNIDADE',
  PREFEITURA = 'PREFEITURA',
}

export enum SituacaoAnimalPatrimonioApi {
  NãoCadastrado = 'NãoCadastrado',
  Ativo = 'Ativo',
  Baixado = 'Baixado',
  Passagem = 'Passagem',
}
export interface DadosExportadosItens {
  declarante?: string | null;
  projeto?: string | null;
  disciplina?: string | null;
  utilizar?: string | null;
  numPatrimonio: string;
  numCadastro: string;
  especie: string;
  categoria: string;
  lote: string;
  quantidadeDeAnimais: number;
  proprietario: string;
  diasDeUso: number;
  destinoPosUso: string;
  destinatarioPosUso: string;
  observacoes: string;
  origemParticular: string;
}

export interface DadosExportadosReceita {
  declarante?: string | null;
  especie: string;
  categoria: string;
  receitaSubprodutos: string;
  receitaProle: string;
  receitaTransferencia: string;
  observacoes: string;
}
export interface DadosExportados {
  dadosItens: DadosExportadosItens[];
  dadosReceita: DadosExportadosReceita[];
}

export const PREFEITURA_UNIDADE_ID = 5;
export const CATEGORIA_PREFEITURA = 'Prefeitura';
