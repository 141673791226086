
import Vue from "vue";
import requester from "@/requester";
import {
  CategoriaUsuarioEnum,
  PREFEITURA_UNIDADE_ID,
  CATEGORIA_PREFEITURA,
} from "../../../back/src/utils/constants";
import { SetCategoriaUsuarioDto } from "../../../back/src/usuario/dto/set-categoria-usuario.dto";
import { SessionUsuario } from "@back-src/auth/sessionUsuario.interface";

export default Vue.extend({
  name: "LandingPage",
  data() {
    return {
      loading: false,
      CategoriaUsuarioEnum,
      categorias: [
        CategoriaUsuarioEnum.DOCENTE,
        CategoriaUsuarioEnum.UNIDADE,
        CATEGORIA_PREFEITURA,
      ],
      unidades: [],
      form: {
        categoria: null,
        unidadeId: null,
      },
    };
  },
  computed: {
    isDevMode() {
      return process.env.VUE_APP_MODE === "develop";
    },
    queryLoggedIn(): boolean | null {
      return this.$route?.query?.loggedIn || null;
    },
    usuario() {
      return this.$store.getters.usuario;
    },
    loginUspURL() {
      return `${process.env.VUE_APP_BASE_URL}auth/login_usp`;
    },
    baseUrl() {
      return `${process.env.VUE_APP_BASE_URL}`;
    },
    unidadesFiltered() {
      // Prefeitura é uma unidade com comportamento diferente
      return this.unidades.filter(
        (unidade) => unidade.id !== PREFEITURA_UNIDADE_ID
      );
    },
    submitDisabled() {
      return (
        this.form.categoria === null ||
        ([CategoriaUsuarioEnum.UNIDADE, CategoriaUsuarioEnum.DOCENTE].includes(
          this.form.categoria
        ) &&
          this.form.unidadeId === null)
      );
    },
  },
  async mounted() {
    if (this.queryLoggedIn) {
      this.loading = true;
      try {
        const user: SessionUsuario =
          await requester.auth.private.verificaSessao();
        this.$store.dispatch("login", user);
        this.buscaUnidades();
        this.redirectPosLogin(user);
      } catch (error) {
        return;
      } finally {
        this.loading = false;
      }
    }
  },
  methods: {
    acessarSistema() {
      let indexRoute;
      if (this.usuario.categoriaUsuario === CategoriaUsuarioEnum.DOCENTE) {
        indexRoute = "DocenteIndex";
      } else if (
        this.usuario.categoriaUsuario === CategoriaUsuarioEnum.UNIDADE &&
        this.usuario.unidadeId === PREFEITURA_UNIDADE_ID
      ) {
        indexRoute = "PrefeituraIndex";
      } else if (
        this.usuario.categoriaUsuario === CategoriaUsuarioEnum.UNIDADE
      ) {
        indexRoute = "UnidadeIndex";
      } else {
        return;
      }
      this.$router.push({ name: indexRoute });
    },
    async submitVinculo() {
      // Prefeitura é uma unidade com comportamento diferente
      if (this.form.categoria === CATEGORIA_PREFEITURA) {
        this.form.unidadeId = PREFEITURA_UNIDADE_ID;
        this.form.categoria = CategoriaUsuarioEnum.UNIDADE;
      }
      const formData: SetCategoriaUsuarioDto = {
        categoriaUsuario: this.form.categoria,
        unidadeId: this.form.unidadeId,
      };
      try {
        const sessionUsuario =
          await requester.usuario.private.setCategoriaUsuario(formData);
        this.$store.dispatch("login", sessionUsuario);
        this.$root.$emit("toast-success", "Categoria de usuário definida");
        if (sessionUsuario.ativo) {
          this.redirectPosLogin(sessionUsuario);
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async buscaUnidades() {
      try {
        this.unidades = await requester.unidade.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async loginDev(categoria: string) {
      let usuarioIdStr: string;
      let indexRoute: string;

      switch (categoria) {
        case CategoriaUsuarioEnum.ADMIN:
          usuarioIdStr = "1";
          indexRoute = "PeriodoDeclaracaoIndex";
          break;
        case CategoriaUsuarioEnum.UNIDADE:
          usuarioIdStr = "4";
          indexRoute = "UnidadeIndex";
          break;
        case "prefeitura":
          usuarioIdStr = "5";
          indexRoute = "PrefeituraIndex";
          break;
        case CategoriaUsuarioEnum.DOCENTE:
          usuarioIdStr = "63";
          indexRoute = "DocenteIndex";
          break;
        default:
          break;
      }
      try {
        const user = await requester.auth.public.loginDev(usuarioIdStr);
        this.$store.dispatch("login", user);
        if (indexRoute) {
          this.$router.push({ name: indexRoute });
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    redirectPosLogin(user) {
      let indexRoute: string;
      switch (user.categoriaUsuario) {
        case CategoriaUsuarioEnum.ADMIN:
          indexRoute = "PeriodoDeclaracaoIndex";
          break;
        case CategoriaUsuarioEnum.UNIDADE:
          if (user.unidadeId === PREFEITURA_UNIDADE_ID) {
            indexRoute = "PrefeituraIndex";
          } else {
            indexRoute = "UnidadeIndex";
          }
          break;
        case CategoriaUsuarioEnum.DOCENTE:
          indexRoute = "DocenteIndex";
          break;
        default:
          break;
      }
      if (indexRoute) {
        this.$router.push({ name: indexRoute });
      }
    },
  },
});
