import axios from "axios";
import { TabelaCusto } from "@back-src/models/tabela-custo.model";

const buscaAtual = async (): Promise<TabelaCusto> => {
  const res = await axios.get<TabelaCusto>("/tabelas_custo/atual");
  return res.data;
};
const cria = async (tabelaCusto: TabelaCusto): Promise<TabelaCusto> => {
  const res = await axios.post<TabelaCusto>("/tabelas_custo", tabelaCusto);
  return res.data;
};

export default {
  private: {
    buscaAtual,
    cria,
  },
};
