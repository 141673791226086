import { SessionUsuario } from "@back-src/auth/sessionUsuario.interface";
import axios from "axios";

const verificaSessao = async (): Promise<SessionUsuario> => {
  const res = await axios.get<SessionUsuario>("/auth/verifica_sessao");
  return res.data;
};

const logout = async (): Promise<void> => {
  const res = await axios.get<void>("/auth/logout");
  return res.data;
};

const loginDev = async (usuarioIdStr: string): Promise<SessionUsuario> => {
  const res = await axios.post<SessionUsuario>("/auth/login_dev", {
    usuarioId: usuarioIdStr,
    password: "123",
  });
  return res.data;
};

export default {
  public: {
    loginDev,
  },
  private: {
    verificaSessao,
    logout,
  },
};
