
import Vue from "vue";
import { fileMaxSize, rulesRequired } from "../../utils/input-rules";
import { Disciplina } from "@back-src/models/disciplina.model";
import requester from "@/requester";
import { CriaDisciplinaDto } from "@back-src/unidade/dto/cria-disciplina.dto";
import FileDownloadLink from "../FileDownloadLink.vue";

export default Vue.extend({
  name: "DisciplinaForm",
  components: {
    FileDownloadLink,
  },
  props: {
    unidadeId: {
      type: Number,
      default: null,
    },
    disciplinaSelecionada: {
      type: Object as () => Disciplina,
      default: null,
    },
    declaracaoId: {
      type: Number,
      default: null,
    },
    alterar: {
      type: Boolean,
      default: false,
    },
    importacaoDisciplina: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      disciplina: false,
    },
    form: {
      sigla: null,
      nome: null,
      responsavel: null,
      numCeua: null,
    },
    fileCeua: null,
    rules: {
      required: rulesRequired,
      fileSize: fileMaxSize,
    },
  }),
  mounted() {
    if (this.disciplinaSelecionada) {
      this.preencheForm();
    }
  },
  computed: {
    nomeCeuaArquivo() {
      return this.disciplinaSelecionada?.ceuaArquivo?.originalname;
    },
  },
  methods: {
    validaForm() {
      return this.$refs.form.validate();
    },
    preencheForm() {
      this.form.sigla = this.disciplinaSelecionada.sigla;
      this.form.nome = this.disciplinaSelecionada.nome;
      this.form.responsavel = this.disciplinaSelecionada.responsavel;
      this.form.numCeua = this.disciplinaSelecionada.numCeua;
    },
    async submitForm() {
      if (!this.validaForm()) return;
      const data = {
        sigla: this.form?.sigla?.trim(),
        nome: this.form?.nome?.trim(),
        responsavel: this.form?.responsavel?.trim(),
        numCeua: this.form?.numCeua?.trim(),
        importacaoDisciplinaId: this.importacaoDisciplina
          ? this.disciplinaSelecionada.id
          : null,
      } as CriaDisciplinaDto;

      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      formData.append("fileCeua", this.fileCeua);
      try {
        if (this.alterar) {
          // alterar
          await requester.unidade.private.alteraDisciplina(
            formData,
            this.declaracaoId,
            this.disciplinaSelecionada.id,
            this.unidadeId
          );
          this.$root.$emit("toast-success", "Disciplina atualizada!");
          this.$emit("disciplina-atualizada");
        } else {
          // criar / importar
          this.disciplinas = await requester.unidade.private.criaDisciplina(
            formData,
            this.declaracaoId,
            this.unidadeId
          );
          this.$root.$emit("toast-success", "Disciplina cadastrada!");
          this.$emit("disciplina-cadastrada");
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      }
      this.$emit("salvar", data);
    },
  },
  watch: {
    disciplinaSelecionada() {
      if (this.disciplinaSelecionada) {
        this.preencheForm();
      }
    },
  },
});
