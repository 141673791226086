<template>
  <v-card outlined class="ma-0">
    <h1 class="text-h5 ma-3">Dados dos projetos</h1>
    <div class="d-flex">
      <!-- BUSCA -->
      <div style="width: 100%">
        <v-text-field
          @click:clear="search = null"
          append-icon="mdi-magnify"
          class="pa-3 ml-3"
          clearable
          dense
          hide-details
          label="Buscar"
          outlined
          full-width
          v-model="search"
        />
      </div>
    </div>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :items="itens"
      :loading="loading"
      :search="search"
      sortBy="valor"
      :sortDesc="true"
      item-key="id"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 50, 100],
      }"
    >
      <template v-slot:[`item.percUnidade`]="{ item }">
        {{ getPercentualUnidade(item.unidade, item.valor) }}
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        {{ item.valor | formatCurrency }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Vue from "vue";
import { groupBy, sumBy } from "lodash";

export default Vue.extend({
  name: "TabelaRelatoriosProjetoCompleto",
  props: {
    itens: {
      type: Array,
      default: () => [],
      required: true,
    },
    headers: {
      type: Array,
      default: () => [],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    valoresPorUnidade() {
      const unidades = Object.keys(groupBy(this.itens, "unidade"));
      let valores = {};
      unidades.forEach((unidade) => {
        valores[unidade] = sumBy(
          this.itens.filter((item) => item.unidade === unidade),
          (el) => el.valor
        );
      });
      return valores;
    },
  },
  methods: {
    getPercentualUnidade(unidade, valor) {
      if (this.valoresPorUnidade[unidade] === 0) {
        return "0%";
      }
      return new Intl.NumberFormat("pt-BR", {
        style: "percent",
        maximumFractionDigits: 2,
      }).format(valor / this.valoresPorUnidade[unidade]);
    },
  },
});
</script>
