<template>
  <v-card outlined class="ma-0">
    <h1 class="text-h5 ma-3">Agrupados por docente</h1>
    <v-data-table
      dense
      :headers="headers"
      :items="itens"
      :loading="loading"
      sortBy="valor"
      :sortDesc="true"
      item-key="id"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 50, 100],
      }"
    >
      <template v-slot:[`item.valor`]="{ item }">
        {{ item.valor | formatCurrency }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "TabelaRelatoriosProjetoResumoDocente",
  props: {
    itens: {
      type: Array,
      default: () => [],
      required: true,
    },
    headers: {
      type: Array,
      default: () => [],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
