import Vue from "vue";

// gambiarra (typescript tá maluco)
// interface DateTimeFormatOptions extends Intl.DateTimeFormatOptions {
//   dateStyle?: "full" | "long" | "medium" | "short" | undefined;
// }

Vue.filter("formatDate", (value: string | number) => {
  const dateText = `${value}`.match(/\d+/g).map((d, i) => Number(d) - (i % 2));
  const dateNumber = new Date(dateText[0], dateText[1], dateText[2]);
  const DateTimeFormat = new Intl.DateTimeFormat("pt-BR");
  return DateTimeFormat.format(dateNumber);
  // return value
  //   ? new Intl.DateTimeFormat("pt-BR", {
  //       dateStyle: "short",
  //     } as DateTimeFormatOptions).format(new Date(`${value} 00:00:10`))
  //   : "";
});
