
import Vue from "vue";
import DocenteProjetoForm from "./DocenteProjetoForm.vue";
import AlertInfo from "../AlertInfo.vue";
import PeriodoDeclaracaoSelect from "../periodo-declaracao/PeriodoDeclaracaoSelect.vue";
import DocenteProjetoInfoDialog from "./DocenteProjetoInfoDialog.vue";
import ProjetoResumoCard from "./ProjetoResumoCard.vue";
import requester from "@/requester";
export default Vue.extend({
  name: "DocenteProjetoImportar",
  components: {
    DocenteProjetoForm,
    DocenteProjetoInfoDialog,
    PeriodoDeclaracaoSelect,
    ProjetoResumoCard,
    AlertInfo,
  },
  props: {
    declaracaoId: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    usuarioId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: {
        projetos: false,
      },
      dialogProjetoInfo: false,
      periodoSelecionado: null,
      projetosAnteriores: [],
      projetoSelecionado: null,
      projetoVerDetalhes: null,
      msgAviso:
        "Selecione abaixo qual projeto deseja importar para a declaração atual. Os dados poderão ser alterados antes do novo projeto ser salvo",
    };
  },
  mounted() {
    if (this.periodoSelecionado) {
      this.buscaProjetosAnteriores(this.periodoSelecionado.id);
    }
  },
  methods: {
    async buscaProjetosAnteriores(periodoDeclaracaoId: number) {
      try {
        this.loading.projetos = true;
        this.projetosAnteriores =
          await requester.docente.private.listaProjetosByPeriodoId(
            periodoDeclaracaoId,
            this.usuarioId
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.projetos = false;
      }
    },
  },
  watch: {
    periodoSelecionado() {
      this.projetosAnteriores = [];
      if (this.periodoSelecionado) {
        this.buscaProjetosAnteriores(this.periodoSelecionado.id);
      }
    },
  },
});
