
import Vue from "vue";
import * as XLSX from "xlsx";
import requester from "@/requester";
import { PREFEITURA_UNIDADE_ID } from "@/utils/constants";

export default Vue.extend({
  name: "ExportarButton",
  props: {
    unidadeId: {
      type: Number,
      default: null,
    },
    docenteUsuarioId: {
      type: Number,
      default: null,
    },
    declaracaoId: {
      type: Number,
      default: null,
    },
    loadingExterno: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async baixarDados() {
      this.loading = true;
      try {
        const data = new Date().toJSON().slice(0, 10);
        let filename;
        let resposta;
        if (this.unidadeId) {
          if (this.unidadeId === PREFEITURA_UNIDADE_ID) {
            filename = `${data}_dadosPrefeitura.xlsx`;
          } else {
            filename = `${data}_dadosUnidade.xlsx`;
          }
          resposta =
            await requester.unidade.private.exportarDadosDeclaracaoUnidade(
              this.unidadeId,
              this.declaracaoId
            );
        } else if (this.docenteUsuarioId) {
          filename = `${data}_dadosDocente.xlsx`;
          resposta =
            await requester.docente.private.exportarDadosDeclaracaoDocente(
              this.docenteUsuarioId,
              this.declaracaoId
            );
        }
        const workbook = XLSX.utils.book_new();
        const worksheetItens = XLSX.utils.json_to_sheet(resposta.dadosItens);
        const worksheetReceita = XLSX.utils.json_to_sheet(
          resposta.dadosReceita
        );
        XLSX.utils.book_append_sheet(workbook, worksheetItens, "itens");
        XLSX.utils.book_append_sheet(workbook, worksheetReceita, "receita");
        XLSX.writeFile(workbook, filename, {
          bookType: "xlsx",
        });
      } catch (err) {
        this.$root.$errorHandler(err);
      }
      this.loading = false;
    },
  },
});
