
import Vue from "vue";
export default Vue.extend({
  name: "CustomHeader",
  props: {
    title: {
      type: String,
      default: null,
    },
    titleClass: {
      type: String,
      default: "text-h5 font-weight-medium",
    },
    helpIcon: {
      type: Boolean,
      default: false,
    },
  },
});
