
import Vue from "vue";
import requester from "../../requester";
import { sumBy } from "lodash";
import { Unidade } from "@back-src/models/unidade.model";
import { Declaracao } from "@back-src/models/declaracao.model";
import { Categoria } from "@back-src/models/categoria.model";
import { PeriodoDeclaracao } from "@back-src/models/periodo-declaracao.model";
import { MetabaseCollectionsRelatorios } from "../../utils/enums";
import RelatoriosTabItem from "../../components/admin/RelatoriosTabItem.vue";
import CardRecursosFinanceiros from "../../components/admin/CardRecursosFinanceiros.vue";
import CustomHeader from "../../components/CustomHeader.vue";
import PeriodoDeclaracaoDadosBasicos from "../../components/periodo-declaracao/PeriodoDeclaracaoDadosBasicos.vue";
import PeriodoDeclaracaoSelect from "../../components/periodo-declaracao/PeriodoDeclaracaoSelect.vue";
import ChartRecursosFinanceiros from "../../components/admin/ChartRecursosFinanceiros.vue";
import TabelaConsultaItensDeclaracao from "../../components/admin/TabelaConsultaItensDeclaracao.vue";
import TabelaItensAtivosDeclaracao from "../../components/admin/TabelaItensAtivosDeclaracao.vue";
import RelatoriosProjetosPesquisa from "../../components/admin/RelatoriosProjetosPesquisa.vue";

export default Vue.extend({
  name: "RelatoriosIndex",
  components: {
    CustomHeader,
    CardRecursosFinanceiros,
    PeriodoDeclaracaoSelect,
    PeriodoDeclaracaoDadosBasicos,
    RelatoriosTabItem,
    ChartRecursosFinanceiros,
    TabelaConsultaItensDeclaracao,
    TabelaItensAtivosDeclaracao,
    RelatoriosProjetosPesquisa,
  },
  data: () => ({
    loading: {
      disciplinas: false,
      tabela: false,
      unidades: false,
      declaracoes: false,
      categorias: false,
    },
    MetabaseCollectionsRelatorios,
    tabs: 0,
    tabsMetabase: 0,
    unidadeSelecionadaId: null,
    alternasVisualizacao: true,
    unidades: [] as Unidade[],
    declaracoes: [] as Declaracao[],
    categorias: [] as Categoria[],
    itensDeclaracaoDoPeriodo: [],
    selectedItem: 0,
    fatorDisponibilidade: 1,
    periodoDeclaracaoSelecionado: null as PeriodoDeclaracao,
    periodoDeclaracaoSelecionadoId: null,
    // metabase
    metabaseSessionKey: null,
    metabaseCollections: [],
  }),
  computed: {
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    recursosNecessarios() {
      return sumBy(this.itensDeclaracaoDoPeriodo, (el) => el.custoTotal);
    },
  },
  async mounted() {
    this.listaCategorias();
    this.listaUnidades();
    if (this.periodoDeclaracaoSelecionadoId) {
      this.listaItensDeclaracaoUtilizados();
      this.listaDeclaracoes();
    }
    await this.metabaseLogin();
    if (this.metabaseSessionKey) {
      this.metabaseListaColecoes();
    }
  },
  methods: {
    async listaItensDeclaracaoUtilizados() {
      this.loading.declaracoes = true;
      try {
        this.itensDeclaracaoDoPeriodo =
          await requester.admin.private.listaItensDeclaracaoUtilizados(
            this.periodoDeclaracaoSelecionadoId
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.declaracoes = false;
      }
    },
    async buscaPeriodo() {
      this.loading.periodo = true;
      try {
        this.periodoDeclaracaoSelecionado =
          await requester.periodoDeclaracao.private.busca(
            this.periodoDeclaracaoSelecionadoId
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.periodo = false;
      }
    },
    async listaCategorias() {
      this.loading.categorias = true;
      try {
        this.categorias = await requester.categoria.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.categorias = false;
      }
    },
    async listaUnidades() {
      this.loading.unidades = true;
      try {
        this.unidades = await requester.unidade.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.unidades = false;
      }
    },
    async listaDeclaracoes() {
      this.loading.declaracoes = true;
      try {
        this.declaracoes =
          await requester.admin.private.listaDeclaracoesPorPeriodo(
            this.periodoDeclaracaoSelecionadoId
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.declaracoes = false;
      }
    },
    //metabase
    achaCollection(collectionName: MetabaseCollectionsRelatorios) {
      const col = this.metabaseCollections.find(
        (el) => el.name === collectionName
      );
      return col || null;
    },
    async metabaseLogin() {
      const data = {
        username: process.env.VUE_APP_METABASE_LOGIN,
        password: process.env.VUE_APP_METABASE_PASS,
      };
      try {
        this.metabaseSessionKey =
          await requester.metabase.private.metabaseLogin(data);
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async metabaseListaColecoes() {
      try {
        this.metabaseCollections =
          await requester.metabase.private.listaColecoes(
            this.metabaseSessionKey.id
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
  watch: {
    periodoDeclaracaoSelecionadoId() {
      if (this.periodoDeclaracaoSelecionadoId) {
        this.buscaPeriodo();
        this.listaItensDeclaracaoUtilizados();
        this.listaDeclaracoes();
      }
    },
  },
});
