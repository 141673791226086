
import { PeriodoDeclaracao } from "@back-src/models/periodo-declaracao.model";
import Vue from "vue";
import requester from "../../requester";
import CustomHeader from "../../components/CustomHeader.vue";
import { defineStatusPeriodoDeclaracao } from "../../utils/functions";
import { coresPeriodo } from "../../utils/constants";

export default Vue.extend({
  components: { CustomHeader },
  name: "PeriodoDeclaracaoIndex",
  data: () => ({
    search: null,
    loading: {
      periodoDeclaracao: true,
    },
    coresPeriodo,
    periodoDeclaracaoDialog: false,
    periodosDeclaracao: [] as PeriodoDeclaracao[],
    headerDeclaracoes: [
      {
        value: "anoReferencia",
        text: "Ano",
        sortable: true,
        filterable: false,
        align: "left",
      },
      {
        value: "nome",
        text: "Nome",
        sortable: true,
        filterable: false,
        align: "left",
      },
      {
        value: "dataInicio",
        text: "Data de início das declarações",
        sortable: true,
        filterable: false,
        align: "center",
      },
      {
        value: "dataFim",
        text: "Data de fim das declarações",
        sortable: true,
        filterable: false,
        align: "center",
      },
      {
        value: "status",
        text: "Status",
        sortable: true,
        filterable: false,
        align: "center",
      },
      {
        value: "declaracoes",
        text: "Declarações",
        sortable: false,
        filterable: false,
        align: "center",
      },
      {
        value: "ver_alterar",
        text: "Ver/Alterar",
        sortable: false,
        filterable: false,
        align: "center",
      },
    ],
  }),
  mounted() {
    this.listaPeriodos();
  },
  methods: {
    async listaPeriodos() {
      this.loading.tabela = true;
      try {
        this.periodosDeclaracao =
          await requester.periodoDeclaracao.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.periodoDeclaracao = false;
      }
    },
    statusPeriodo(periodo: PeriodoDeclaracao) {
      return defineStatusPeriodoDeclaracao(periodo);
    },
  },
});
