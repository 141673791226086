import { RouteConfig } from "vue-router";
import UnidadeIndex from "@/pages/unidade/UnidadeIndex.vue";

const routes: RouteConfig[] = [
  {
    path: "/unidade",
    name: "UnidadeIndex",
    component: UnidadeIndex,
    meta: {
      public: false,
    },
  },
];

export default routes;
