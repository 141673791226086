
import Vue from "vue";
import requester from "../../requester";
import AlertInfo from "../../components/AlertInfo.vue";
import PeriodoDeclaracaoSelect from "../periodo-declaracao/PeriodoDeclaracaoSelect.vue";
import { PREFEITURA_UNIDADE_ID } from "@/utils/constants";
export default Vue.extend({
  name: "PrefeituraImportacaoCard",
  components: {
    AlertInfo,
    PeriodoDeclaracaoSelect,
  },
  props: {
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      loadingImportacao: false,
      step: 1,
      periodoSelecionado: null,
      itensDeclaracao: [],
      animaisImportar: [],
      qtdeAnimaisImportar: 0,
      qtdeAnimaisValidacao: 0,
      validados: 0,
    };
  },
  watch: {
    periodoSelecionado(newValue) {
      if (newValue) {
        this.listaAnimaisImportacao();
      }
    },
  },
  methods: {
    async listaAnimaisImportacao() {
      this.loading = true;
      try {
        const itensDeclaracao =
          await requester.unidade.private.listaDeclaracaoItemsPrefeituraByPeriodo(
            PREFEITURA_UNIDADE_ID,
            this.periodoSelecionado.id
          );
        this.qtdeAnimaisImportar = itensDeclaracao.length;
        this.qtdeAnimaisValidacao = itensDeclaracao.filter(
          (el) => el.numPatrimonio
        ).length;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async importarAnimais() {
      const confirm = await this.$root.$confirm(
        "Importar animais",
        `Esta ação irá importar ${this.qtdeAnimaisImportar} animais da declaração anterior. Os dados importados desta maneira poderão ser alterados ou excluídos posteriormente. Deseja prosseguir?`,
        {
          textConfirm: "Sim, importar",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      // importar
      this.loading = true;
      try {
        const novosItens =
          await requester.unidade.private.importarItensPrefeitura(
            PREFEITURA_UNIDADE_ID,
            this.periodoSelecionado.id
          );
        this.animaisDeclarados = [];

        this.loadingImportacao = true;
        for await (const novoItem of novosItens) {
          if (novoItem.numPatrimonio) {
            // se tiver numPatrimonio, checa e usa versão validada
            // Caso de erro, retorna status code (401)
            const validado =
              await requester.declaracaoItem.private.validaItemApiPatrimonio(
                novoItem.id
              );
            this.validados++;
            if (isNaN(Number(validado))) {
              this.animaisDeclarados.push(validado);
            } else {
              this.animaisDeclarados.push(novoItem);
            }
          } else {
            // senao, usa versão atual
            this.animaisDeclarados.push(novoItem);
          }
        }
        this.loadingImportacao = false;
        this.$root.$emit("toast-success", "Itens importados com sucesso!");
        this.$emit("importacao-concluida");
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
