
import Vue from "vue";
export default Vue.extend({
  name: "DocenteProjetosTabItemHeader",
  props: {
    stepAtual: {
      type: Number,
      default: 1,
    },
    projetoSelecionadoId: {
      type: Number,
      default: null,
    },
  },
});
