
import Vue from "vue";
import { minBy, maxBy } from "lodash";
import { PeriodoDeclaracao } from "@back-src/models/periodo-declaracao.model";
import { getDataHojeBdFormat } from "../../../../back/src/utils/functions";
import PeriodoDeclaracaoChip from "./PeriodoDeclaracaoChip.vue";
import requester from "../../requester";
export default Vue.extend({
  name: "PeriodoDeclaracaoSelect",
  components: {
    PeriodoDeclaracaoChip,
  },
  props: {
    selectLabel: {
      type: String,
      default: "Período selecionado:",
    },
    exibirAtual: {
      type: Boolean,
      default: false,
    },
    loadingExterno: {
      type: Boolean,
      default: false,
    },
    disableSelect: {
      type: Boolean,
      default: false,
    },
    selecionarPrimeiro: {
      type: Boolean,
      default: false,
    },
    selecionarUltimo: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      periodoDeclaracao: true,
    },
    periodosDeclaracao: [] as PeriodoDeclaracao[] | [],
    periodoDeclaracaoSelecionado: null,
  }),
  computed: {
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    periodosDeclaracaoComputed() {
      return this.periodosDeclaracao.map((periodoDeclaracao) => ({
        ...periodoDeclaracao,
        disabled: this.setDisabled(periodoDeclaracao),
      }));
    },
  },
  async mounted() {
    await this.listaPeriodos();
    const periodosSelecionaveis = this.periodosDeclaracaoComputed.filter(
      (el) => !el.disabled
    );
    if (this.selecionarPrimeiro) {
      this.periodoDeclaracaoSelecionado = minBy(periodosSelecionaveis, "id");
    } else if (this.selecionarUltimo) {
      this.periodoDeclaracaoSelecionado = maxBy(periodosSelecionaveis, "id");
    } else {
      this.periodoDeclaracaoSelecionado =
        periodosSelecionaveis.length > 0 ? periodosSelecionaveis[0] : null;
    }
    this.$emit("periodo-alterado", this.periodoDeclaracaoSelecionado);
  },
  methods: {
    setDisabled(periodoDeclaracao) {
      const hojeBd = getDataHojeBdFormat();
      // desabilita periodos futuros
      let isDisabled = hojeBd < periodoDeclaracao.dataInicio;
      if (!this.exibirAtual) {
        // desabilita periodo atual
        isDisabled =
          isDisabled ||
          (hojeBd >= periodoDeclaracao.dataInicio &&
            hojeBd <= periodoDeclaracao.dataFim);
      }
      return isDisabled;
    },
    periodoSelecionadoIsAtual() {
      if (!this.periodoDeclaracaoSelecionado) {
        return false;
      }
      const hojeBd = getDataHojeBdFormat();
      const periodoSelecionado: PeriodoDeclaracao =
        this.periodosDeclaracao.find(
          (el: PeriodoDeclaracao) =>
            this.periodoDeclaracaoSelecionado.id === el.id
        );
      return (
        periodoSelecionado &&
        periodoSelecionado.dataInicio <= hojeBd &&
        periodoSelecionado.dataFim >= hojeBd
      );
    },
    async listaPeriodos() {
      this.loading.periodoDeclaracao = true;
      try {
        this.periodosDeclaracao =
          await requester.periodoDeclaracao.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.periodoDeclaracao = false;
      }
    },
  },
});
