import { Declaracao } from "@back-src/models/declaracao.model";
import { PeriodoDeclaracao } from "@back-src/models/periodo-declaracao.model";
import { CriaPeriodoDeclaracaoDto } from "@back-src/periodo-declaracao/dto/cria-periodo-declaracao.dto";
import { AlteraPeriodoDeclaracaoDto } from "@back-src/periodo-declaracao/dto/altera-periodo-declaracao.dto";

import axios from "axios";

const lista = async (): Promise<PeriodoDeclaracao[]> => {
  const res = await axios.get<PeriodoDeclaracao[]>("/periodos_declaracao");
  return res.data;
};

const listaDeclaracoes = async (periodoId: number): Promise<Declaracao[]> => {
  const res = await axios.get<Declaracao[]>(
    `/periodos_declaracao/${periodoId}/declaracoes`
  );
  return res.data;
};

const buscaAtual = async (): Promise<PeriodoDeclaracao> => {
  const res = await axios.get<PeriodoDeclaracao>("/periodos_declaracao/atual");
  return res.data;
};

const buscaUltimo = async (): Promise<PeriodoDeclaracao> => {
  const res = await axios.get<PeriodoDeclaracao>("/periodos_declaracao/ultimo");
  return res.data;
};

const busca = async (
  peridoDeclaracaoId: number
): Promise<PeriodoDeclaracao> => {
  const res = await axios.get<PeriodoDeclaracao>(
    `/periodos_declaracao/${peridoDeclaracaoId}`
  );
  return res.data;
};
const cria = async (
  periodoDeclaracao: CriaPeriodoDeclaracaoDto
): Promise<PeriodoDeclaracao> => {
  const res = await axios.post<PeriodoDeclaracao>(
    "/periodos_declaracao",
    periodoDeclaracao
  );
  return res.data;
};

const altera = async (
  periodoId: number,
  periodoDeclaracao: AlteraPeriodoDeclaracaoDto
): Promise<PeriodoDeclaracao> => {
  const res = await axios.put<PeriodoDeclaracao>(
    `/periodos_declaracao/${periodoId}`,
    periodoDeclaracao
  );
  return res.data;
};

const verificaNumPatrimonio = async (
  periodoId: number,
  numPatrimonio: number,
  itemDeclaracaoId?: number
): Promise<Declaracao[]> => {
  const res = await axios.get<[]>(
    `/periodos_declaracao/${periodoId}/verifica_patimonio`,
    {
      params: {
        num_patrimonio: numPatrimonio,
        item_declaracao_id: itemDeclaracaoId,
      },
    }
  );
  return res.data;
};

const verificaNumCadastro = async (
  periodoId: number,
  numCadastro: number,
  itemDeclaracaoId?: number
): Promise<Declaracao[]> => {
  const res = await axios.get<[]>(
    `/periodos_declaracao/${periodoId}/verifica_cadastro`,
    {
      params: {
        num_cadastro: numCadastro,
        item_declaracao_id: itemDeclaracaoId,
      },
    }
  );
  return res.data;
};

export default {
  private: {
    altera,
    busca,
    buscaAtual,
    buscaUltimo,
    cria,
    lista,
    listaDeclaracoes,
    verificaNumPatrimonio,
    verificaNumCadastro,
  },
};
