
import Vue from "vue";
import requester from "../../requester";
import { filter } from "lodash";
import RelatoriosLayoutTool from "./RelatoriosLayoutTool.vue";
import MetabaseChart from "../metabase/MetabaseChart.vue";

export default Vue.extend({
  name: "RelatoriosTabItem",
  components: {
    MetabaseChart,
    RelatoriosLayoutTool,
  },
  props: {
    unidades: {
      type: Array,
      default: () => [],
    },
    categorias: {
      type: Array,
      default: () => [],
    },
    declaracoes: {
      type: Array,
      default: () => [],
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    fatorDisponibilidade: {
      type: Number,
      default: null,
    },
    metabaseSessionKey: {
      type: Object,
      default: null,
    },
    metabaseCollection: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      colSize: 6,
      metabaseItens: [],
    };
  },
  computed: {
    metabaseItensCard() {
      return filter(this.metabaseItens, (el) => el.model === "card");
    },
  },
  mounted() {
    this.metabaseListaItensColecao();
  },
  methods: {
    async metabaseListaItensColecao() {
      if (!this.metabaseCollection) {
        return;
      }
      try {
        const res = await requester.metabase.private.listaItensColecao(
          this.metabaseCollection.id,
          this.metabaseSessionKey.id
        );
        this.metabaseItens = res.data;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
  watch: {
    metabaseCollection() {
      if (this.metabaseCollection) {
        this.metabaseListaItensColecao();
      }
    },
  },
});
