import { Especie } from "@back-src/models/especie.model";
import axios from "axios";

const lista = async (): Promise<Especie[]> => {
  const res = await axios.get<Especie[]>("/especies");
  return res.data;
};

export default {
  private: {
    lista,
  },
};
