
import Vue from "vue";
import CustomText from "../CustomText.vue";
import { ItemDeclaradoVerificacao } from "../../utils/constants";
export default Vue.extend({
  name: "DeclaracaoVerConflitoDialog",
  components: { CustomText },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    animaisDeclarados: {
      type: Array,
      default: () => [] as ItemDeclaradoVerificacao[],
    },
    numPatrimonio: {
      type: String,
      default: null,
    },
    numCadastro: {
      type: String,
      default: null,
    },
    tipoConflito: {
      type: String,
      default: null,
    },
    diasDisponiveis: {
      type: Number,
      default: null,
    },
    declaracaoItemId: {
      type: Number,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Tipo da declaração",
        value: "tipoDeclarante",
        groupable: false,
      },
      {
        text: "Responsável",
        value: "responsavel",
        groupable: false,
      },
      {
        text: "Contato",
        value: "usuarioUpdatedBy.email",
        groupable: false,
      },
      {
        text: "Status",
        value: "status",
        groupable: false,
      },
      {
        text: "Ações",
        value: "actions",
        groupable: false,
      },
    ],
  }),
  computed: {
    itemsComputed() {
      const data = [];
      this.animaisDeclarados.forEach((item) => {
        if (item.id !== this.declaracaoItemId) data.push(item);
      });
      if (this.tipoConflito === "patrimonio") {
        return data.filter((el) => el.numPatrimonio === this.numPatrimonio);
      } else if (this.tipoConflito === "cadastro") {
        return data.filter((el) => el.numCadastro === this.numCadastro);
      }
      return [];
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      this.$emit("input", false);
    },
  },
});
