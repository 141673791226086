
import Vue from "vue";

export default Vue.extend({
  name: "InputMoney",
  props: {
    properties: {
      type: Object,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    valor: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    appendOuter: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    defaultOptions: {
      locale: "pt-BR",
      prefix: "",
      suffix: "R$",
      length: 20,
      precision: 2,
      min: 0,
    },
    value: null,
  }),
  mounted() {
    this.value = this.valor;
  },
  watch: {
    valor() {
      this.value = this.valor;
    },
  },
});
