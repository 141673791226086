
import { Declaracao } from "@back-src/models/declaracao.model";
import Vue from "vue";
import { StatusDeclaracoes } from "../../utils/enums";
import {
  DeclarantesListItem,
  DeclarantesListSubItem,
} from "../../utils/constants";

export default Vue.extend({
  name: "DeclarantesMenu",
  props: {
    unidades: {
      type: Array,
      default: () => [],
    },
    declaracoes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      StatusDeclaracoes,
      showMenu: false,
      mini: false,
      selectedSubItemKey: null,
      selectedItemAutocomplete: null,
    };
  },
  computed: {
    todosDeclarantes() {
      const declarantes = [];
      this.unidadesMenu.map((unidade) =>
        unidade.subitens.map((item) => {
          declarantes.push({ text: item.nome, value: item.key });
        })
      );
      return declarantes;
    },
    unidadesMenu() {
      const docentesSubitens = this.declaracoesDocentes();
      return this.unidades.map((unidade) => {
        const declaracao = this.buscaDeclaracaoUnidade(unidade);
        let status = declaracao
          ? StatusDeclaracoes.PARCIAL
          : StatusDeclaracoes.PENDENTE;
        if (declaracao?.completa) {
          status = StatusDeclaracoes.COMPLETA;
        }
        return {
          id: unidade.id,
          isPrefeitura: unidade.isPrefeitura,
          nome: `${unidade.sigla} - ${unidade.nome}`,
          subitens: [
            {
              nome: `Declaração da ${
                unidade.isPrefeitura ? "prefeitura" : "unidade"
              } (${unidade.sigla})`,
              key: `Unidade${unidade.id}`,
              declaracaoId: declaracao ? declaracao.id : null,
              tipoDeclarante: unidade.isPrefeitura ? "Prefeitura" : "Unidade",
              idDeclarante: unidade.id,
              icon: "mdi-office-building",
              status: status,
              badgeIcon: this.badgeIcon(status),
              badgeColor: this.badgeColor(status),
            },
            ...docentesSubitens.filter(
              (docente) => docente.unidadeId === unidade.id
            ),
          ],
        };
      });
    },
    itens(): DeclarantesListItem[] {
      const prefeituraHeader: DeclarantesListItem = {
        isHeader: true,
        title: "Prefeitura",
        icon: null,
        subitens: [],
      };
      const prefeituraSubitens: DeclarantesListItem = {
        isHeader: false,
        title: null,
        icon: "mdi-office-building",
        subitens: this.unidades
          .filter((unidade) => unidade.isPrefeitura)
          .map((el) => {
            const declaracao = this.buscaDeclaracaoUnidade(el);
            let status = declaracao
              ? StatusDeclaracoes.PARCIAL
              : StatusDeclaracoes.PENDENTE;
            if (declaracao?.completa) {
              status = StatusDeclaracoes.COMPLETA;
            }
            return {
              key: `Unidade${el.id}`,
              declaracaoId: declaracao ? declaracao.id : null,
              tipoDeclarante: "Prefeitura",
              idDeclarante: el.id,
              nome: `${el.sigla} - ${el.nome}`,
              status: status,
              badgeIcon: this.badgeIcon(status),
              badgeColor: this.badgeColor(status),
            } as DeclarantesListSubItem;
          }),
      };
      const unidadeHeader: DeclarantesListItem = {
        isHeader: true,
        title: "Unidades",
        icon: null,
        subitens: [],
      };
      const unidadesSubitens: DeclarantesListItem = {
        isHeader: false,
        title: null,
        icon: "mdi-office-building",
        subitens: this.unidades
          .filter((unidade) => !unidade.isPrefeitura)
          .map((el) => {
            const declaracao = this.buscaDeclaracaoUnidade(el);
            let status = declaracao
              ? StatusDeclaracoes.PARCIAL
              : StatusDeclaracoes.PENDENTE;
            if (declaracao?.completa) {
              status = StatusDeclaracoes.COMPLETA;
            }
            return {
              key: `Unidade${el.id}`,
              declaracaoId: declaracao ? declaracao.id : null,
              tipoDeclarante: "Unidade",
              idDeclarante: el.id,
              nome: `${el.sigla} - ${el.nome}`,
              status: status,
              badgeIcon: this.badgeIcon(status),
              badgeColor: this.badgeColor(status),
            } as DeclarantesListSubItem;
          }),
      };
      const docentesHeader: DeclarantesListItem = {
        isHeader: true,
        title: "Docentes",
        icon: null,
        subitens: [],
      };
      const docentesSubitens: DeclarantesListItem = {
        isHeader: false,
        title: null,
        icon: "mdi-account",
        subitens: this.declaracoesDocentes(),
      };
      return [
        prefeituraHeader,
        prefeituraSubitens,
        unidadeHeader,
        unidadesSubitens,
        docentesHeader,
        docentesSubitens,
      ];
    },
  },
  methods: {
    buscaDeclaranteSubItem(key) {
      let declarante;
      this.unidadesMenu.map((unidade) => {
        const declaranteSubItem = unidade.subitens.find(
          (subitem) => subitem.key === key
        );
        if (declaranteSubItem) {
          declarante = declaranteSubItem;
        }
      });
      return declarante;
    },
    declaracoesDocentes(): DeclarantesListSubItem[] {
      return this.declaracoes
        .filter((dec: Declaracao) => dec.tipoDeclarante === "Docente")
        .map((dec: Declaracao) => {
          let status = dec
            ? StatusDeclaracoes.PARCIAL
            : StatusDeclaracoes.PENDENTE;
          if (dec?.completa) {
            status = StatusDeclaracoes.COMPLETA;
          }

          return {
            key: `Docente${dec.createdBy}`,
            declaracaoId: dec.id,
            tipoDeclarante: dec.tipoDeclarante,
            idDeclarante: dec.usuarioCreatedBy.id,
            nome: `${dec.usuarioCreatedBy.nome}`,
            status: status,
            icon: "mdi-account",
            unidadeId: dec.usuarioCreatedBy.unidadeId,
            badgeIcon: this.badgeIcon(status),
            badgeColor: this.badgeColor(status),
          } as DeclarantesListSubItem;
        });
    },
    clicaSubItem(subitem: DeclarantesListSubItem) {
      if (this.selectedSubItemKey === subitem.key) {
        this.selectedSubItemKey = null;
        this.$emit("declarante-alterado", null);
      } else {
        this.selectedSubItemKey = subitem.key;
        this.$emit("declarante-alterado", subitem);
        this.showMenu = false;
      }
    },
    clicaSubItemAutoComplete(subItemKey) {
      if (this.selectedSubItemKey === subItemKey || !subItemKey) {
        this.selectedSubItemKey = null;
        this.$emit("declarante-alterado", null);
      } else {
        this.selectedSubItemKey = subItemKey;
        let subitem = null;
        this.unidadesMenu.map((unidade) => {
          unidade.subitens.map((item) => {
            if (item.key === subItemKey) subitem = item;
          });
        });
        this.$emit("declarante-alterado", subitem);
        this.showMenu = false;
      }
    },
    badgeColor(status: string) {
      if (status === StatusDeclaracoes.PENDENTE) return "grey";
      if (status === StatusDeclaracoes.PARCIAL) return "warning";
      return "success";
    },
    badgeIcon(status: string) {
      if (status === StatusDeclaracoes.PENDENTE) return "mdi-dots-horizontal";
      if (status === StatusDeclaracoes.PARCIAL) return "mdi-pencil";
      return "mdi-check";
    },
    buscaDeclaracaoUnidade(unidade) {
      const dec = this.declaracoes.find(
        (declaracao) => declaracao.unidadeId === unidade.id
      );
      return dec || null;
    },
  },
});
