import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTheMask from "vue-the-mask";
import "./plugins/vuetify-money.js";

// filters
import "./filter/formatCurrency";
import "./filter/formatDate";

Vue.config.productionTip = false;
Vue.use(VueTheMask);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
