
import Vue from "vue";
import { debounce } from "lodash";
import requester from "../../requester";
import CustomText from "../../components/CustomText.vue";
import InputMoney from "../inputs/InputMoney.vue";
import InputData from "../inputs/InputData.vue";
import { PeriodoDeclaracao } from "@back-src/models/periodo-declaracao.model";
import { Categoria } from "@back-src/models/categoria.model";
import { TabelaCustoItem } from "@back-src/models/tabela-custo-item.model";
import { CriaPeriodoDeclaracaoDto } from "@back-src/periodo-declaracao/dto/cria-periodo-declaracao.dto";
import { AlteraPeriodoDeclaracaoDto } from "@back-src/periodo-declaracao/dto/altera-periodo-declaracao.dto";
import { rulesRequired } from "@/utils/input-rules";

export default Vue.extend({
  name: "PeriodoDeclaracaoForm",
  components: {
    CustomText,
    InputData,
    InputMoney,
  },
  props: {
    criar: {
      type: Boolean,
      default: true,
    },
    modoEdicao: {
      type: Boolean,
      default: false,
    },
    categorias: {
      type: Array as () => Categoria[],
      default: () => [],
    },
    ultimoPeriodoDeclaracao: {
      type: Object as () => PeriodoDeclaracao,
      default: null,
    },
    periodoDeclaracaoSelecionado: {
      type: Object as () => PeriodoDeclaracao,
      default: null,
    },
  },
  data: () => ({
    loading: {
      submit: false,
      form: true,
    },
    headers: [
      {
        value: "especieNome",
        text: "Espécie",
        sortable: true,
      },
      {
        value: "categoriaNome",
        text: "Categoria",
        sortable: true,
      },
      {
        value: "valor",
        text: "R$/cabeça/ano",
        sortable: true,
        align: "right",
      },
      {
        value: "observacoes",
        text: "Observações",
        sortable: true,
      },
    ],
    rules: {
      rulesRequired,
    },
    form: {
      nome: null,
      anoReferencia: null,
      dataInicio: null,
      dataFim: null,
      observacoes: null,
      tabelaCustoItens: [],
    },
  }),
  async mounted() {
    this.loading.form = true;
    if (!this.criar && !this.periodoDeclaracaoSelecionado) {
      this.$root.$emit("toast-warning", "Nenhum período selecionado");
      this.$router.push({ name: "PeriodoDeclaracaoIndex" });
    } else {
      this.montaForm();
    }
    this.loading.form = false;
  },
  computed: {
    // ultimaAtualizacao() {
    //   return this.tabelaCusto ? this.tabelaCusto.createdAt : null;
    // },
    mostrarForm() {
      return this.criar || this.modoEdicao;
    },
  },
  methods: {
    async descartarAlteracoes() {
      const confirm = await this.$root.$confirm(
        "Descartar alterações?",
        "As alterações não salvas serão perdidas. Deseja prosseguir?",
        {
          textConfirm: "Sim, descartar alterações",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      this.$emit("desabilitar-alteracao");
      this.montaForm();
    },
    montaForm() {
      this.form.dataInicio = null;
      this.form.dataFim = null;
      this.form.observacoes = null;
      this.form.tabelaCustoItens = [];
      // MODO CRIAR
      let periodoReferencia: PeriodoDeclaracao = null;
      if (this.criar) {
        periodoReferencia = this.ultimoPeriodoDeclaracao;
      } else {
        // MODO VER/ALTERAR
        periodoReferencia = this.periodoDeclaracaoSelecionado;
        this.form.nome = periodoReferencia.nome;
        this.form.anoReferencia = periodoReferencia.anoReferencia;
        this.form.dataInicio = periodoReferencia.dataInicio;
        this.form.dataFim = periodoReferencia.dataFim;
      }
      this.form.observacoes =
        periodoReferencia?.tabelaCusto.observacoes || null;
      this.form.tabelaCustoItens.push(
        ...this.categorias.map((categoria: Categoria) => {
          let itemCusto;
          if (!!periodoReferencia && periodoReferencia?.tabelaCusto) {
            itemCusto = periodoReferencia.tabelaCusto.tabelaCustoItems.find(
              (el: TabelaCustoItem) => el.categoriaId === categoria.id
            );
          }
          return {
            // display
            categoriaNome: categoria.nome,
            especieNome: categoria.especie.nome,
            // form
            id: itemCusto && itemCusto.id ? itemCusto.id : null,
            categoriaId: categoria.id,
            valor: itemCusto ? itemCusto.valor : null,
            observacoes: itemCusto ? itemCusto.observacoes : null,
          };
        })
      );
    },
    alteraPeriodoDeclaracaoDebounce: debounce(function () {
      this.alteraPeriodoDeclaracao();
    }, 200),
    async alteraPeriodoDeclaracao() {
      if (!this.$refs?.form?.validate()) {
        this.$root.$emit("toast-error", "Preencha os campos obrigatórios");
        return;
      }
      this.loading.submit = true;
      const formData: AlteraPeriodoDeclaracaoDto = {
        nome: this.form.nome,
        anoReferencia: parseInt(this.form.anoReferencia),
        dataInicio: this.form.dataInicio,
        dataFim: this.form.dataFim,
        tabelaCusto: {
          observacoes: this.form.observacoes,
          tabelaCustoItems: [
            ...this.form.tabelaCustoItens.map((el) => ({
              id: el.id,
              categoriaId: el.categoriaId,
              valor: Number(el.valor),
              observacoes: el.observacoes,
            })),
          ],
        },
      };
      try {
        this.tabelaCusto = await requester.periodoDeclaracao.private.altera(
          this.periodoDeclaracaoSelecionado.id,
          formData
        );
        this.$root.$emit("toast-success", "As alterações foram salvas!");
        this.$emit("periodo-atualizado");
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submit = false;
      }
    },
    criaPeriodoDeclaracaoDebounce: debounce(function () {
      this.criaPeriodoDeclaracao();
    }, 200),
    async criaPeriodoDeclaracao() {
      if (!this.$refs?.form?.validate()) {
        this.$root.$emit("toast-error", "Preencha os campos obrigatórios");
        return;
      }
      this.loading.submit = true;
      const formData: CriaPeriodoDeclaracaoDto = {
        nome: this.form.nome,
        anoReferencia: parseInt(this.form.anoReferencia),
        dataInicio: this.form.dataInicio,
        dataFim: this.form.dataFim,
        tabelaCusto: {
          observacoes: this.form.observacoes,
          tabelaCustoItems: [
            ...this.form.tabelaCustoItens.map((el) => ({
              categoriaId: el.categoriaId,
              valor: Number(el.valor),
              observacoes: el.observacoes,
            })),
          ],
        },
      };
      try {
        this.tabelaCusto = await requester.periodoDeclaracao.private.cria(
          formData
        );
        this.$root.$emit("toast-success", "Novo período criado!");
        this.$router.push({ name: "PeriodoDeclaracaoIndex" });
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.submit = false;
      }
    },
  },
});
