var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loading.periodoDeclaracao)?_c('div',[_c('CustomHeader',{staticClass:"mb-5",attrs:{"title":"Histórico dos períodos de declaração","titleClass":"text-h4 font-weight-light"}},[_c('template',{slot:"left"},[_c('v-icon',{staticClass:"pb-2",attrs:{"left":"","large":""}},[_vm._v("mdi-calendar")])],1),_c('template',{slot:"rigth"},[_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","to":{ name: 'PeriodoDeclaracaoCriar' },"color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Período")])],1)]}}],null,false,453320403)},[_c('span',[_vm._v("Cadastrar um novo período de declaração")])])],1)],2),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headerDeclaracoes,"items":_vm.periodosDeclaracao,"item-key":"id","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:`item.dataInicio`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dataInicio))+" ")])]}},{key:`item.dataFim`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dataFim))+" ")])]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.coresPeriodo[_vm.statusPeriodo(item)],"dark":""}},[_vm._v(" "+_vm._s(_vm.statusPeriodo(item))+" ")])]}},{key:`item.declaracoes`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","to":{
                name: 'PeriodoDeclaracaoVerDeclaracoes',
                params: { periodoDeclaracaoId: item.id },
              }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-document-check-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver declarações do período")])])]}},{key:`item.ver_alterar`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","to":{
                name: 'PeriodoDeclaracaoVerAlterar',
                params: { periodoDeclaracaoId: item.id },
              }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver/alterar datas e custos do período")])])]}}],null,true)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }