var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loadingPageComputed)?_c('div',[_c('CustomHeader',{attrs:{"title":_vm.usuario.nome,"titleClass":"text-h5 font-weight-light"}}),_c('div',{staticClass:"d-flex justify-end"},[(_vm.periodoDeclaracaoAtivo)?_c('span',{staticClass:"green--text font-weight-light text-right"},[_vm._v(" Estamos em período de declaração de "+_vm._s(_vm._f("formatDate")(_vm.periodoDeclaracao.dataInicio))+" à "+_vm._s(_vm._f("formatDate")(_vm.periodoDeclaracao.dataFim)))]):_c('span',{staticClass:"red--text font-weight-light text-right"},[_vm._v(" Não estamos em período de declaração ")])]),_c('v-card',{staticClass:"pr-3",attrs:{"outlined":""}},[_c('v-tabs',{attrs:{"vertical":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"d-flex justify-start"},[_c('v-icon',{attrs:{"left":"","mdall":""}},[_vm._v(" mdi-home ")]),_vm._v(" Início ")],1),(
          _vm.declaracaoIniciada &&
          _vm.periodoDeclaracaoAtivo &&
          !_vm.declaracaoDocente.completa
        )?[_c('v-tab',{staticClass:"d-flex justify-start"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-human-male-board")]),_vm._v(" Projetos ")],1),_c('v-tab',{staticClass:"d-flex justify-start"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-cash")]),_vm._v(" Receita ")],1),_c('v-tab',{staticClass:"d-flex justify-start",attrs:{"vif":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-check")]),_vm._v(" Finalizar ")],1)]:_vm._e(),_c('v-tab',{staticClass:"d-flex justify-start"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-history")]),_vm._v(" Histórico ")],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('InicioTabItem',{attrs:{"periodoDeclaracaoAtivo":_vm.periodoDeclaracaoAtivo,"declaracaoIniciada":!!_vm.declaracaoDocente,"declaracaoEnviada":_vm.declaracaoDocente?.completa,"declaracaoId":_vm.declaracaoDocente?.id,"tipoDeclarante":_vm.TiposDeclarantes.DOCENTE},on:{"iniciar-declaracao":function($event){return _vm.declaracao()},"envia-declaracao":function($event){return _vm.alteraFlagConclusao($event)}}})],1),(
            _vm.declaracaoIniciada &&
            _vm.periodoDeclaracaoAtivo &&
            !_vm.declaracaoDocente.completa
          )?[_c('v-tab-item',[_c('DocenteProjetosTabItem',{attrs:{"periodoDeclaracaoId":_vm.periodoDeclaracao.id,"declaracaoId":_vm.declaracaoDocente.id,"usuarioId":_vm.usuario.id}})],1),_c('v-tab-item',[(_vm.tab === 2)?_c('DocenteReceitaTabItem',{attrs:{"periodoDeclaracaoId":_vm.periodoDeclaracao.id,"declaracaoAtualId":_vm.declaracaoDocente.id,"usuarioId":_vm.usuario.id}}):_vm._e()],1),_c('v-tab-item',[(_vm.tab === 3)?_c('DocenteFinalizarDeclaracaoTabItem',{attrs:{"periodoAtualId":_vm.periodoDeclaracao.id,"usuarioId":_vm.usuario.id,"declaracaoFinalizada":_vm.declaracaoDocente?.completa,"declaracaoId":_vm.declaracaoDocente?.id},on:{"declaracao-enviada":function($event){(_vm.tab = 0), _vm.buscaDados()},"receita-animal":function($event){_vm.tab = 2}}}):_vm._e()],1)]:_vm._e(),_c('v-tab-item',[_c('DocenteHistoricoDeclaracaoTabItem',{attrs:{"alterar":false,"usuarioId":_vm.usuario.id,"declaracaoId":_vm.declaracaoDocente?.id,"periodoAtualId":_vm.periodoDeclaracaoAtivo ? _vm.periodoDeclaracao.id : null}})],1)],2)],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }