
import Vue from "vue";
import requester from "@/requester";
import { sortBy } from "lodash";

export default Vue.extend({
  name: "MetabaseChart",
  props: {
    unidades: {
      type: Array,
      default: () => [],
    },
    categorias: {
      type: Array,
      default: () => [],
    },
    declaracoes: {
      type: Array,
      default: () => [],
    },
    cardInfo: {
      type: Object,
      default: null,
    },
    metabaseSessionKey: {
      type: Object,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    fatorDisponibilidade: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    iframeUrl: null,
    dadosCard: null,
    baseHeight: 400,
    height: 400,
    unidadeId: null,
    categoriaId: null,
    declaracaoId: null,
  }),
  computed: {
    parametrosCard() {
      return this.dadosCard.parameters.map((el) => el.slug);
    },
    temParametroUnidadeId() {
      return this.parametrosCard.includes("unidade_id");
    },
    temParametroDeclaracaoId() {
      return this.parametrosCard.includes("declaracao_id");
    },
    temParametroCategoriaId() {
      return this.parametrosCard.includes("categoria_id");
    },
    temParametroPeriodoDeclaracaoId() {
      return this.parametrosCard.includes("periodo_declaracao_id");
    },
    temParametroFatorDisponibilidade() {
      return this.parametrosCard.includes("fator_disponibilidade");
    },
    unidadesItens() {
      return this.unidades.map((el) => ({
        text: el.sigla,
        value: el.id,
      }));
    },
    categoriasItens() {
      return this.categorias.map((el) => ({
        text: `${el.especie.nome} - ${el.nome}`,
        value: el.id,
      }));
    },
    declaracoesItens() {
      return sortBy(
        this.declaracoes.map((el) => ({
          text:
            el.tipoDeclarante === "Docente"
              ? el.usuarioCreatedBy.nome
              : el.unidade.sigla,
          value: el.id,
        })),
        ["text"]
      );
    },
  },
  async mounted() {
    this.restauraAltura();
    this.carregaChart();
    if (this.unidadesItens.length > 0) {
      this.unidadeId = this.unidadesItens[0].value;
    }
    if (this.declaracoesItens.length > 0) {
      this.declaracaoId = this.declaracoesItens[0].value;
    }
    if (this.categoriasItens.length > 0) {
      this.categoriaId = this.categoriasItens[0].value;
    }
  },
  methods: {
    aumentaAltura() {
      this.height += 100;
    },
    reduzAltura() {
      if (this.height > 100) {
        this.height -= 100;
      } else {
        this.$root.$emit("toast-error", "Gráfico muito pequeno");
      }
    },
    restauraAltura() {
      this.height = this.baseHeight;
    },
    async carregaChart() {
      this.loading = true;
      await this.buscaDadosCard();
      await this.buscaGrafico();
      this.loading = false;
    },
    async buscaDadosCard() {
      // busca infos da question (do tipo card)
      try {
        this.dadosCard = await requester.metabase.private.buscaCardById(
          this.cardInfo.id,
          this.metabaseSessionKey.id
        );
      } catch (error) {
        this.$root.$errorHandler(error);
      }
    },
    async buscaGrafico() {
      let params = {};

      // busca pelas variaveis da question do metabase
      if (this.temParametroPeriodoDeclaracaoId) {
        params["periodoDeclaracaoId"] = this.periodoDeclaracaoId;
      }
      if (this.temParametroUnidadeId) {
        params["unidadeId"] = this.unidadeId;
      }
      if (this.temParametroDeclaracaoId) {
        params["declaracaoId"] = this.declaracaoId;
      }
      if (this.temParametroFatorDisponibilidade) {
        params["fatorDisponibilidade"] = this.fatorDisponibilidade;
      }
      if (this.temParametroCategoriaId) {
        params["categoriaId"] = this.categoriaId;
      }

      try {
        this.iframeUrl = await requester.metabase.private.buscaGrafico(
          Number(this.dadosCard.id),
          params
        );
      } catch (error) {
        this.$root.$errorHandler(error);
      }
    },
  },
  watch: {
    periodoDeclaracaoId() {
      this.carregaChart();
    },
    unidadeId() {
      this.carregaChart();
    },
    categoriaId() {
      this.carregaChart();
    },
    declaracaoId() {
      this.carregaChart();
    },
    fatorDisponibilidade() {
      this.carregaChart();
    },
  },
});
