
import Vue from "vue";
import requester from "../../requester";
import CustomHeader from "../../components/CustomHeader.vue";
import PeriodoDeclaracaoForm from "../../components/periodo-declaracao/PeriodoDeclaracaoForm.vue";

export default Vue.extend({
  components: {
    CustomHeader,
    PeriodoDeclaracaoForm,
  },
  name: "PeriodoDeclaracaoCriar",
  data: () => ({
    loading: {
      periodo: true,
      categorias: true,
    },
    ultimoPeriodoDeclaracao: null,
    categorias: [],
  }),
  async mounted() {
    await this.listaCategorias();
    await this.buscaUltimoPeriodo();
  },
  computed: {
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
  },
  methods: {
    async listaCategorias() {
      this.loading.categorias = true;
      try {
        this.categorias = await requester.categoria.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.categorias = false;
      }
    },
    async buscaUltimoPeriodo() {
      this.loading.periodo = true;
      try {
        this.ultimoPeriodoDeclaracao =
          (await requester.periodoDeclaracao.private.buscaUltimo()) || null;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.periodo = false;
      }
    },
  },
});
