
import Vue from "vue";
import CustomTabItem from "../CustomTabItem.vue";
import ExportarButton from "../ExportarButton.vue";
import DeclaracaoPrefeituraTabela from "../declaracao/DeclaracaoPrefeituraTabela.vue";
import PeriodoDeclaracaoSelect from "../periodo-declaracao/PeriodoDeclaracaoSelect.vue";
import { PREFEITURA_UNIDADE_ID } from "@/utils/constants";
export default Vue.extend({
  name: "PrefeituraHistoricoDeclaracaoTabItem",
  components: {
    CustomTabItem,
    ExportarButton,
    PeriodoDeclaracaoSelect,
    DeclaracaoPrefeituraTabela,
  },
  data: () => ({
    PREFEITURA_UNIDADE_ID,
    periodoSelecionado: null,
    declaracaoSelecionada: null,
    loading: false,
  }),
});
