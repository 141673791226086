
import Vue from "vue";
export default Vue.extend({
  name: "AlertInfo",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: null,
    },
    content: {
      type: Array,
      default: () => [],
    },
  },
});
