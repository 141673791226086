
import Vue from "vue";
import CustomColInfo from "../CustomColInfo.vue";
import { Disciplina } from "@back-src/models/disciplina.model";

export default Vue.extend({
  name: "DisciplinaResumoCard",
  components: {
    CustomColInfo,
  },
  props: {
    disciplina: {
      type: Object as () => Disciplina,
      default: null,
    },
  },
});
