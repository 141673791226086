
import Vue from "vue";
import DocenteProjetoForm from "./DocenteProjetoForm.vue";
import requester from "@/requester";
export default Vue.extend({
  name: "DocenteProjetoCadastrarWindow",
  components: {
    DocenteProjetoForm,
  },
  props: {
    declaracaoId: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    usuarioId: {
      type: Number,
      default: null,
    },
    projetoSelecionadoId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: {
        projeto: false,
      },
      projetoSelecionado: null,
    };
  },
  mounted() {
    if (this.projetoSelecionadoId) {
      this.buscaProjeto();
    }
  },
  methods: {
    async buscaProjeto() {
      try {
        this.loading.projeto = true;
        this.projetoSelecionado = await requester.docente.private.buscaProjeto(
          this.declaracaoId,
          this.periodoDeclaracaoId,
          this.projetoSelecionadoId,
          this.usuarioId
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.projeto = false;
      }
    },
  },
  watch: {
    projetoSelecionadoId() {
      if (this.projetoSelecionadoId) {
        this.buscaProjeto();
      } else {
        this.projetoSelecionado = null;
      }
    },
  },
});
