
import Vue from "vue";
import requester from "../../requester";
import CustomTabItem from "../CustomTabItem.vue";
import { Declaracao } from "@back-src/models/declaracao.model";
import DeclaracaoPrefeituraTabela from "../declaracao/DeclaracaoPrefeituraTabela.vue";
import DeclaracaoItemAlertValidacao from "../declaracao/DeclaracaoItemAlertValidacao.vue";
import { verificaItemDeclaracao } from "@/utils/functions";
export default Vue.extend({
  name: "PrefeituraFinalizarDeclaracaoTabItem",
  components: {
    CustomTabItem,
    DeclaracaoPrefeituraTabela,
    DeclaracaoItemAlertValidacao,
  },
  props: {
    unidadeId: {
      type: Number,
      default: null,
    },
    periodoAtualId: {
      type: Number,
      default: null,
    },
    declaracaoId: {
      type: Number,
      default: null,
    },
    declaracaoFinalizada: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: {
      itensDeclaracao: true,
    },
    declaracao: null as Declaracao,
  }),
  computed: {
    declaracaoValida() {
      const situacaoArray =
        this.itemsComputed.map((item) => item.situacao) || [];
      return Object.values(situacaoArray).reduce(
        (prox, acc) => prox && acc,
        true
      );
    },
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    itemsComputed() {
      return this.declaracao.declaracaoItems.map((item) => {
        return {
          ...item,
          situacao: verificaItemDeclaracao(item),
        };
      });
    },
  },
  async mounted() {
    this.listaItensDeclaracao();
  },
  methods: {
    async listaItensDeclaracao() {
      this.loading.itensDeclaracao = true;
      if (!this.unidadeId || !this.periodoAtualId) return;
      try {
        this.declaracao =
          await requester.unidade.private.buscaDeclaracaoByPeriodoId(
            this.unidadeId,
            this.periodoAtualId
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.itensDeclaracao = false;
      }
    },
    async alteraFlagConclusao(completa: boolean) {
      try {
        const res = await requester.unidade.private.alteraFlagConclusao(
          this.declaracaoId,
          this.unidadeId,
          {
            completa,
          }
        );
        if (res === "Receita possui itens não informados") {
          this.$root.$emit(
            "toast-warning",
            "Verifique as informações de receita animal antes de continuar"
          );
          this.$emit("receita-animal");
        } else {
          this.$root.$emit(
            "toast-success",
            "Declaração marcada como completa!"
          );
          this.$emit("declaracao-enviada");
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
});
