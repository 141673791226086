
import TheToastSnackbar from "./components/TheToastSnackbar.vue";
import TheConfirmDialog from "./components/TheConfirmDialog.vue";
import LayoutDefault from "./layouts/LayoutDefault.vue";
import requester from "./requester";

export default {
  name: "App",
  components: {
    LayoutDefault,
    TheToastSnackbar,
    TheConfirmDialog,
  },
  data: () => ({
    //
  }),
  computed: {
    usuario() {
      return this.$store.getters.usuario;
    },
    isRouterLoaded() {
      return !!this.$route?.name;
    },
    currentLayout() {
      return "LayoutDefault";
    },
  },
  created() {
    this.$root.$errorHandler = this.errorHandler;
  },
  async mounted() {
    this.$root.$confirm = this.$refs.theConfirmDialog.open;
    if (this.usuario) {
      if (!this.$route?.meta?.public) {
        try {
          await requester.auth.private.verificaSessao();
        } catch (err) {
          this.$root.$errorHandler(err);
        }
      } else {
        // verifica sessao em tela publica pra remover usuario sem sessao da store
        try {
          await requester.auth.private.verificaSessao();
        } catch (err) {
          if (err.response?.status === 403 || err.response?.status === 401) {
            this.$store.dispatch("logout");
          } else {
            this.$root.$errorHandler(err);
          }
        }
      }
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorHandler(err) {
      this.$root.$emit(
        "toast-error",
        err?.response?.data?.message ?? err?.response?.data ?? err?.message
      );
      if (err?.response) {
        if (
          (err.response?.status === 401 || err.response?.status === 403) &&
          this.$route.name !== "LandingPage"
        ) {
          this.$router.push({ name: "LandingPage" });
        }
      }
    },
  },
};
