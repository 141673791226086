import axios from "axios";

import admin from "./admin.requester";
import auth from "./auth.requester";
import categoria from "./categoria.resquester";
import disciplina from "./disciplina.requester";
import docente from "./docente.requester";
import especie from "./especie.requester";
import metabase from "./metabase.requester";
import periodoDeclaracao from "./periodo-declaracao.resquester";
import tabelaCustos from "./tabela-custos.resquester";
import unidade from "./unidade.resquester";
import usuario from "./usuario.requester";
import declaracaoItem from "./declaracao-item.requester";

// backend base URL
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// send authentication cookie
axios.defaults.withCredentials = true;

export default {
  admin,
  auth,
  categoria,
  disciplina,
  docente,
  especie,
  metabase,
  periodoDeclaracao,
  tabelaCustos,
  unidade,
  usuario,
  declaracaoItem,
};
