export enum StatusDeclaracoes {
  PENDENTE = "Pendente",
  PARCIAL = "Parcial",
  COMPLETA = "Completa",
}

export enum StatusPeriodosDeclaracao {
  FUTURO = "Futuro",
  EM_ANDAMENTO = "Em andamento",
  FINALIZADO = "Finalizado",
}

export enum TipoFinanciamentoBolsa {
  CAPES = "CAPES",
  CNPQ = "CNPQ",
  FAPESP = "FAPESP",
}

export enum TipoFinanciamentoProjeto {
  CAPES = "CAPES",
  CNPQ = "CNPQ",
  FAPESP = "FAPESP",
  FINEP = "FINEP",
  OUTRO = "Outro",
}

export enum ModoFinanciamento {
  PUBLICO = "Público",
  PRIVADO = "Privado",
}

export enum OrigemAnimal {
  FMVZ = "FMVZ",
  FZEA = "FZEA",
  ESALQ = "ESALQ",
  CENA = "CENA",
  PUSP = "PUSP - FC",
  OUTROS = "OUTROS",
}

export enum TiposDeclarantes {
  // no BD só existem 2: Unidade e Docente
  // Prefeitura é uso do front apenas
  UNIDADE = "Unidade",
  DOCENTE = "Docente",
  PREFEITURA = "Prefeitura",
}

export enum MetabaseCollectionsRelatorios {
  DECLARACOES = "Declarações",
  FINANCEIRO = "Financeiro",
  ESTRATEGICO = "Estratégico",
  ZOOTECNICO = "Zootécnico",
  PROJETOS = "Projetos",
}

export enum MetabaseCollectionsSerieHistorica {
  SERIE_HISTORICA = "Série Histórica",
  TABELAS = "Tabelas",
}
